import React, { useEffect, useMemo, useState } from 'react'
import { padStartNumber } from '@/utils'

const ZCountDown = ({ value, className, format = 'mm:ss', onTimeEnd }) => {
    const [times, setTimes] = useState(0)
    useEffect(() => {
        if (value > 0) {
            let _value = parseInt(value)
            setTimes(_value)
            const id = setInterval(() => {
                // console.log('ZCountDown setInterval:', _value)
                if (_value === 0) {
                    typeof onTimeEnd === 'function' && onTimeEnd()
                    return clearInterval(id)
                }
                _value -= 1
                setTimes(_value)
            }, 1000)
            return () => {
                clearInterval(id)
            }
        }
    }, [value])

    const filterTimes = useMemo(() => {
        const h = Math.floor(times / 3600)
        const m = Math.floor((times - h * 3600) / 60)
        const s = times % 60

        return format.includes('hh')
            ? format.replace('hh', padStartNumber(h)).replace('mm', padStartNumber(m)).replace('ss', padStartNumber(s))
            : format.replace('mm', padStartNumber(h * 60 + m)).replace('ss', padStartNumber(s));
    }, [times])


    return (
        <div className={`z-count-down ${className || ''}`}>{filterTimes}</div>
    )
}

export default ZCountDown