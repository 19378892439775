import React from "react";
import {  Tabs } from "antd";
import RegisterContent from "../../components/RegisterContent.js";
import LoginLayout from "../../components/LoginLayout.js";
import "../login/index.less";
import "./index.less";
const { TabPane } = Tabs;
const Login = props => {
    const { history } = props;
  return (
    <LoginLayout>
      <Tabs defaultActiveKey="1" className={"ant-carousel"}>
        <TabPane tab="重置密码" key="1">
          <RegisterContent history={history} form={"forgetpwd"} />
        </TabPane>
      </Tabs>
    </LoginLayout>
  );
};

export default Login;
