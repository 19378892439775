import React, { useState, useEffect } from 'react'
import { Button, Input, Radio, Spin, Switch, message } from 'antd'
import { getPackage, setPackage } from '@/apis/order'
import './index.less'

const numberReg = /^(0|[1-9]\d*)([\.][0-9]{1,2})?$/

const PackingFeeSetting = () => {
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(1) // 1:按订单收打包费  2:按商品收打包费
    const [value, setValue] = useState('0')
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        setLoading(true)
        getData().finally(() => {
            setLoading(false)
        })
    }, [])

    const getData = async () => {
        try {
            const { data } = await getPackage()
            setStatus(data.package_status)
            setChecked(data.package_type === 1)
            setValue(parseFloat(data.package_price) + '')
        } catch(e) {
            console.log(e)
        }
    }

    const onSubmit = async () => {
        console.log(value)
        if (status === 1 && value && !numberReg.test(value)) {
            message.info('只能输入正整数或正浮点数')
            return
        }
        try {
            const v = status === 1 ? (value || 0) : (numberReg.test(value) ? value : 0);
            const res = await setPackage({
                package_price: v,
                package_status: status,
                package_type: status === 1 ? 1 : (checked ? 1 : 2)
            })
            if (res.errors.code === 1) {
                message.success('修改成功')
                getData()
            }
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <Spin spinning={loading}>
            <div className="packingFee">
                <div className="packingFee-content">
                    <div className="packingFee-title">打包费设置{status == 1 && <span>(仅适用于外带订单和配送订单)</span>}</div>
                    <Radio.Group value={status} onChange={e => setStatus(e.target.value)}>
                        <Radio value={1}>
                            <div className="packingFee-title2">按订单收费<span>(每单收取固定的打包费)</span></div>
                        </Radio>
                        <Radio value={2}>
                            <div className="packingFee-title2">按商品收费<span>(按商品收取打包费)</span></div>
                        </Radio>
                    </Radio.Group>
                    {status === 1 ? (
                        <div className="packingFee-label">
                            <span>按每单收取</span>
                            <Input
                                value={value}
                                type="number"
                                suffix="元"
                                style={{ width: '130px' }}
                                onChange={(e) => setValue(e.target.value)}
                            />
                        </div>
                    ) : (<>
                        <div className="packingFee-label">
                            <span>返回商品页面，选择需要收取餐盒费的商品，并设置相应的打包费</span>
                        </div>
                        <div className="packingFee-title3">
                            <Switch checked={checked} onChange={v => setChecked(v)} style={{ marginRight: '8px' }}/>
                            <div className="packingFee-title2">仅外带订单和配送订单<span>(关闭则外带订单、配送订单、堂食订单适用)</span></div>
                        </div>
                    </>)}
                    <Button type="primary" style={{ height: '35px', width: '160px' }} onClick={onSubmit}>
                        确定
                    </Button>
                </div>
            </div>
        </Spin>
    )
}

export default PackingFeeSetting