import React, { useState, useEffect } from "react";
import { Table, Switch, Message } from "antd";
import { MY_CONFIG } from "../../graphql/login";
import "./index.less";
import client from "../../graphql/apollo-config";
import { ADD_PRODUCT } from "../../graphql/product";
import moment from "moment";
import {
  Get_CommissionRule_Query,
  EDIT_COMMISSION_RULE,
  Delete_Commission_Mutation,
} from "../../graphql/commission";

var pageInfo = {
  limit: 15,
  page: 1,
};
const Commission = (props) => {
  const [res, setRes] = useState("");

  useEffect(() => {
    getRes();
  }, []);

  const getRes = async () => {
    try {
      const { data } = await client.query({
        query: MY_CONFIG,
        variables: {
          name: props.location.search.slice(6),
        },
        fetchPolicy: "no-cache",
      });
      if (data) {
        setRes(data.myConfig);
      }
    } catch (error) {}
  };

  const goSet = () => {
    props.history.push("/commission/setcommission?type=default");
  };

  const [show, setShow] = useState(true);
  const { history } = props;
  const {
    params: { id },
  } = props.match;

  const [selectStatus, setSelectStatus] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [statusType, setStatusType] = useState(0);
  const [sendData, setData] = useState({
    spu: null,
    name: null,
    product_price_start: null,
    product_price_end: null,
  });
  let sid = null;

  useEffect(() => {
    requestData();
  }, [sendData]);

  const onChange = (page, pageSize) => {
    pageInfo.page = page;
    requestData(statusType);
  };

  const commissionRender = (text, record, index) => {
    return (
      <div>{`扫码付款${record.commission_qr}%,线上商城${record.commission}%`}</div>
    );
  };

  const edit = (record) => {
    if (record.first) {
      history.push(`/commission/setcommission?id=${record.id}&type=default`);
    } else {
      history.push(`/commission/setcommission?id=${record.id}`);
    }
  };

  const makefail = async (record) => {
    try {
      let product = handleUpPd(record, { status: 2 });
      const { data } = await client.mutate({
        mutation: EDIT_COMMISSION_RULE,
        variables: product,
      });
      if (data.editCommissionRule) {
        refreshData(record.id, { status: data.editCommissionRule.status });
        Message.success("提交成功");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (e) {}
  };
  const delete_commission = async (record) => {
    try {
      const { data } = await client.mutate({
        mutation: Delete_Commission_Mutation,
        variables: { id: record.id },
      });
      if (data.delCommissionRule) {
        Message.success("删除成功");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (e) {}
  };

  const outOfTime = (end) => {
    const current = new Date().getTime();
    console.log(current, end);
    if (current / 1000 < end) {
      return false;
    } else {
      return true;
    }
  };

  const optionalRender = (text, record, index) => {
    return (
      <div className="optional">
        {
          //默认
          record.first ? (
            <span className="edit-c" onClick={edit.bind(this, record)}>
              编辑
            </span>
          ) : record.status == 2 || record.outOfTime ? (
            <div>
              <span className="edit-c gray-edit">已失效</span>
              <span
                className="preview-c"
                onClick={delete_commission.bind(this, record)}
              >
                删除
              </span>
            </div>
          ) : record.status == 1 && !record.outOfTime ? (
            <div>
              <span className="edit-c" onClick={makefail.bind(this, record)}>
                使失效
              </span>
            </div>
          ) : record.status == 0 ? (
            <div>
              <span className="edit-c" onClick={edit.bind(this, record)}>
                编辑
              </span>
              <span
                className="preview-c"
                onClick={delete_commission.bind(this, record)}
              >
                删除
              </span>
            </div>
          ) : null
        }
      </div>
    );
  };

  // 状态0下架1上架
  const onSheldRender = (text, record, index) => {
    return (
      <div>
        <Switch
          checked={record.status == 1}
          onClick={onSheldChange.bind(this, index, record)}
        />
      </div>
    );
  };

  const productCategoryRender = (text, record, index) => {
    return <div>{record.productCategory && record.productCategory.name}</div>;
  };

  const nameRender = (text, record, index) => {
    return (
      <div className="name-wrapper">
        <div className="sub-wrapper">
          <div className="c-name">{record.name}</div>
          {record.first ? <div className="default-name">默认</div> : null}
        </div>
      </div>
    );
  };

  const weightRender = (text, record, index) => {
    return (
      <div className="weight-wrapper">
        {record.edit ? (
          <div>
            <input
              defaultValue={record.weight}
              className="edit-weight"
              onChange={inputValueChange.bind(this, record)}
            />
            <div className="btn-wrapper">
              <span onClick={confirmWeight.bind(this, record)}>确定</span>
              <span onClick={cancel.bind(this, record)}>取消</span>
            </div>
          </div>
        ) : (
          <div>
            <div className="weight-content">{record.weight}</div>
            <div className="change" onClick={changeWeight.bind(this, record)}>
              修改
            </div>
          </div>
        )}
      </div>
    );
  };

  const inputValueChange = (record, input) => {
    let value = input.target.value;
    console.log(value);
    refreshData(record.id, { editvalue: value });
  };

  const changeWeight = (record) => {
    refreshData(record.id, { edit: true });
  };

  const confirmWeight = async (record) => {
    var dic = dataSource.find((item) => {
      return item.id == record.id;
    });

    if (!/(^[1-9]\d*$)/.test(dic.editvalue)) {
      Message.info("权重只能输入正整数");
      return;
    }

    try {
      let product = handleUpPd(record, { weight: parseInt(dic.editvalue) });
      const { data } = await client.mutate({
        mutation: ADD_PRODUCT,
        variables: product,
      });
      if (data.addProduct) {
        refreshData(record.id, { weight: dic.editvalue, edit: false });
        Message.success("提交成功");
      }
    } catch (e) {}
  };

  const cancel = (record) => {
    refreshData(record.id, { edit: false });
  };

  const refreshData = (id, param) => {
    var i = dataSource.findIndex((item) => {
      return item.id == id;
    });
    var datas = [...dataSource];
    var dic = dataSource[i];
    var temp = { ...dic, ...param };
    datas.splice(i, 1, temp);
    setDataSource(datas);
  };

  const handleUpPd = (record, params) => {
    let commission = {};
    commission.id = record.id;
    commission.name = record.name;
    commission.commission = record.commission;
    commission.status = record.status;
    commission.start_at = record.start_at;
    commission.end_at = record.end_at;
    commission.rule_type = record.rule_type;

    let tempP = { ...commission, ...params };
    return tempP;
  };

  //商品上下架
  const onSheldChange = async (index, record, checked) => {
    if (record.first || record.status == 1) {
      return;
    }
    if (record.outOfTime) {
      return Message.success("赏金计划规则已过期");
    }

    try {
      let product = handleUpPd(record, { status: checked ? 1 : 0 });
      const { data } = await client.mutate({
        mutation: EDIT_COMMISSION_RULE,
        variables: product,
      });
      if (data.editCommissionRule) {
        refreshData(record.id, { status: data.editCommissionRule.status });
        Message.success("提交成功");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (e) {}
  };

  //状态切换
  const changeStatus = (status) => {
    pageInfo.page = 1;
    setSelectStatus(status);
    requestData(status);
  };

  const requestData = async (type) => {
    try {
      const { data } = await client.query({
        query: Get_CommissionRule_Query,
        fetchPolicy: "no-cache",
      });
      // setPagenum(data.product.last_page)
      if (data.commissionRules.length > 0) {
        data.commissionRules[0].first = 1;
      }

      data.commissionRules.forEach((item, index) => {
        item.key = index;
        item.outOfTime = outOfTime(item.end_at);
        item.end_at_t = moment(item.end_at * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        item.start_at_t = moment(item.start_at * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        if (index == 0) {
          item.end_at_t = "长期";
          item.start_at_t = "长期";
          item.status = 1;
        }
      });
      setDataSource(data.commissionRules);
      setShow(data.commissionRules.length > 0);
    } catch (e) {}
  };

  const add = () => {
    history.push("/commission/setcommission");
  };

  const HeaderCell = [
    // {
    //     title:'赏金计划规则名称',
    //     dataIndex:'name',
    // },
    {
      title: "赏金计划",
      dataIndex: "commission",
    },
    {
      title: "开始时间",
      dataIndex: "start_at_t",
    },
    {
      title: "结束时间",
      dataIndex: "end_at_t",
    },
    {
      title: "是否开启",
      dataIndex: "status",
    },
    // {
    //     title: '操作',
    //     dataIndex: 'optional',
    // }
  ];

  const columns1Getter = HeaderCell.map((col) => {
    if (col.dataIndex == "status") {
      col.render = onSheldRender;
    } else if (col.dataIndex == "optional") {
      col.render = optionalRender;
    } else if (col.dataIndex == "commission") {
      col.render = commissionRender;
    }
    col.align = "center";
    return col;
  });

  const TableProps = {
    columns: columns1Getter,
    dataSource: dataSource,
    bordered: true,
    pagination: false,
    // pagination: {
    //     current:pageInfo.page,
    //     position: ['bottomCenter'],
    //     defaultPageSize: pageInfo.limit,
    //     onChange: onChange,
    //     showSizeChanger: false,
    //     showQuickJumper: true,
    //     total:pagenum * pageInfo.limit
    // },
    // scroll: {
    //     x: 'max-content',
    //     scrollToFirstRowOnChange: true
    // }
  };

  return (
    <div className="commission-wrapper-super">
      {show ? (
        <div className="product-wrapper">
          {/*<div className='top-wrapper'>*/}
          {/*<div className='status-wrapper'>*/}
          {/*</div>*/}

          {/*<span className='add' onClick={add}>*/}
          {/*新增促销赏金计划规则*/}
          {/*</span>*/}

          {/*</div>*/}
          <Table {...TableProps} />
        </div>
      ) : (
        <div className="commission-wrapper">
          <div className="text">请设置默认赏金计划规则</div>
          <div className="set" onClick={goSet}>
            去设置
          </div>
        </div>
      )}
    </div>
  );
};

export default Commission;
