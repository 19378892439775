import React, { useState, useEffect } from "react";
import { Input, Select, Message, Modal } from "antd";

const FirstStep = (props) => {
  const { settleStatus, changeIdx, first, showMoreData } = props;
  const content = [
    [
      {
        title: "1.营业执照",
        tip: "需拍摄文字清晰、边框完整、露出国徽、加盖公章的照片",
        img: require("../../assets/images/example/3.jpg"),
      },
      {
        title: "2.门头照",
        tip: "需拍摄完整牌匾、门框的照片，确保照片清晰",
        img: require("../../assets/images/example/4.jpg"),
      },
      {
        title: "3.收银台照",
        tip: "需拍摄清晰的收银台照片",
        img: require("../../assets/images/example/6.jpg"),
      },
      {
        title: "4.店内照",
        tip: "需拍摄完整店内环境照片，确保照片清晰",
        img: require("../../assets/images/example/1.jpg"),
      },
      {
        title: "5.法定代表人身份证正面、背面、手持身份证照",
        tip: "拍摄照片需确保证件头像清晰，文字数字清晰可辨",
        img: require("../../assets/images/example/7.jpg"),
      },
      {
        title: "6.结算银行卡、手持银行卡照",
        tip: "拍摄照片需确保证件头像清晰，文字数字清晰可辨",
        img: require("../../assets/images/example/5.jpg"),
      },
    ],
    [
      {
        title: "1.门头照",
        tip: "需拍摄完整牌匾、门框的照片，确保照片清晰",
        img: require("../../assets/images/example/4.jpg"),
      },
      {
        title: "2.店内照",
        tip: "需拍摄完整店内环境照片，确保照片清晰",
        img: require("../../assets/images/example/1.jpg"),
      },
      {
        title: "3.法定代表人身份证正面、背面",
        tip: "拍摄照片需确保证件头像清晰，文字数字清晰可辨",
        img: require("../../assets/images/example/7.jpg"),
      },
      {
        title: "4.结算银行卡",
        tip: "拍摄照片需确保证件头像清晰，文字数字清晰可辨",
        img: require("../../assets/images/example/5.jpg"),
      },
    ],
    [
      {
        title: "1.营业执照",
        tip: "需拍摄文字清晰、边框完整、露出国徽、加盖公章的照片",
        img: require("../../assets/images/example/3.jpg"),
      },
      {
        title: "2.门头照",
        tip: "需拍摄完整牌匾、门框的照片，确保照片清晰",
        img: require("../../assets/images/example/4.jpg"),
      },
      {
        title: "3.收银台照",
        tip: "需拍摄清晰的收银台照片",
        img: require("../../assets/images/example/6.jpg"),
      },
      {
        title: "4.店内照",
        tip: "需拍摄完整店内环境照片，确保照片清晰",
        img: require("../../assets/images/example/1.jpg"),
      },
      {
        title: "5.法定代表人身份证正面、背面、手持身份证照",
        tip: "拍摄照片需确保证件头像清晰，文字数字清晰可辨",
        img: require("../../assets/images/example/7.jpg"),
      },
      {
        title: "6.开户许可证照",
        tip: "拍摄照片需确保证件清晰，文字数字清晰可辨",
        img: require("../../assets/images/example/5.jpg"),
      },
    ],
  ];
  const type = ["个体户", "自然人", "企业商户"];

  const chooseType = (index) => {
    console.log(first);
    if (first) {
      Message.info("不允许再次提交");
    } else {
      changeIdx(index);
    }
  };

  const showImg = (img) => {
    Modal.info({
      content: (
        <div style={{ textAlign: "center" }}>
          <img style={{ width: 438 }} src={img} alt="" />
        </div>
      ),
      icon: null,
      footer: null,
      width: 600,
    });
  };

  return (
    <div className="first-step">
      <div className="first-step_type">
        <div className="title">
          请选择入驻方式 <i className="red">*</i>
        </div>
        {type.map((i, index) => {
          return (
            <div
              key={index + ""}
              className="choose"
              style={{
                //display: index === 1 ? "none" : "",
                border:
                  settleStatus == index
                    ? "1px solid #F4981D"
                    : "1px solid #9A9A9A",
                color: settleStatus == index ? "#F4981D" : "",
              }}
              onClick={() => chooseType(index)}
            >
              {i}
              {settleStatus == index && (
                <img src={require("../../assets/images/choose.png")} alt="" />
              )}
            </div>
          );
        })}
      </div>
      {JSON.stringify(settleStatus) !== "null" && (
        <div className="first-step_content">
          <div className="first-step_content-title">
            <span className="first-step_content-title1">请准备好以下材料</span>
            <span className="first-step_content-title2">
              资料不符合要求无法通过审核
            </span>
          </div>
          {content[settleStatus].map((i, index) => {
            return (
              <div className="step-wrapper" key={index}>
                <p className="step-title">{i.title}</p>
                <p className="step-tip">{i.tip}</p>
                {i.title.indexOf("提交资料") == -1 && (
                  <div className="step-img" onClick={() => showImg(i.img)}>
                    <img className="data-img" src={i.img} alt="" />
                    <img
                      className="li-img"
                      src={require("../../assets/images/li.png")}
                      alt=""
                    />
                  </div>
                )}
                {i.title.indexOf("提交资料") !== -1 && (
                  <div className="step-more" onClick={() => showMoreData()}>
                    提交资料
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FirstStep;
