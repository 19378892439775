import gql from "graphql-tag";

// 获取商店里商品信息
export const Get_Store_Products = gql`
  query (
    $storeId: Int
    $product_type: Int
    $status: Int
    $stock: Int
    $name: String
    $limit: Int
    $page: Int
    $spu: String
    $product_price_start: Float
    $product_price_end: Float
    $module_type: Int
    $id: String
    $product_category_id: Int
    $platform_category_id: Int
    $supplier_id: Int
  ) {
    product(
      storeId: $storeId
      product_type: $product_type
      status: $status
      stock: $stock
      name: $name
      limit: $limit
      page: $page
      spu: $spu
      product_price_start: $product_price_start
      product_price_end: $product_price_end
      module_type: $module_type
      id: $id
      product_category_id: $product_category_id
      platform_category_id: $platform_category_id
      supplier_id: $supplier_id
    ) {
      data {
        id
        product_type
        product_coupon
        name
        barcode
        spu
        sales_point
        content
        price
        first_price
        product_offer_price
        stock
        order_limit
        supplier
        module_type
        is_must
        cover {
          url
        }
        productSpecs {
          id
          sku
          name
          price
          making_price
          offer_price
          stock
          barcode
        }
        store {
          id
          name
          product_type
          is_scan
        }
        status
        created_at
        productCategory {
          id
          name
        }
        images {
          id
          url
        }
        video {
          url
        }
        weight
        delivery_type
        total_write_off_count
        package_price
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;

// 获取商店里商品信息
export const Get_Store_Categorys = gql`
  query ($limit: Int, $page: Int) {
    ProductCategories(limit: $limit, page: $page) {
      data {
        id
        name
        status
        sort_order
        created_at
        updated_at
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;

export const GET_CATEGORY = gql`
  query ($parent_id: Int) {
    categorys(parent_id: $parent_id) {
      value: id
      label: name
      children: child {
        value: id
        label: name
      }
    }
  }
`;

// 修改类目
export const Xiu_Gai_Lei_Mu_Mutation = gql`
  mutation (
    $product_category_id: Int!
    $name: String
    $sort_order: Int!
    $status: Int!
  ) {
    updateProductCategory(
      product_category_id: $product_category_id
      name: $name
      sort_order: $sort_order
      status: $status
    ) {
      id
      name
      status
      sort_order
      created_at
      updated_at
    }
  }
`;

// 获取类目信息
export const Get_One_Category = gql`
  query ($product_category_id: Int!) {
    productCategory(product_category_id: $product_category_id) {
      id
      name
      status
      sort_order
      created_at
      updated_at
    }
  }
`;

// 增加类目
export const Add_Lei_Mu_Mutation = gql`
  mutation ($name: String!, $sort_order: Int!, $status: Int!) {
    addProductCategory(name: $name, sort_order: $sort_order, status: $status)
  }
`;

// 删除类目
export const Delete_Lei_Mu_Mutation = gql`
  mutation ($product_category_id: Int!) {
    delProductCategory(product_category_id: $product_category_id)
  }
`;

//扫码付款记录
export const Get_ScanPayRecord_Query = gql`
  query (
    $store_id: Int
    $order_no: String
    $created_at_start: Int
    $created_at_end: Int
    $total_start: Float
    $total_end: Float
    $actual_amount_start: Float
    $actual_amount_end: Float
    $rebate_hongbao_start: Float
    $rebate_hongbao_end: Float
    $hongbao_start: Float
    $hongbao_end: Float
    $limit: Int
    $page: Int
    $pos_code_name: String
  ) {
    StoreOrderQr(
      store_id: $store_id
      order_no: $order_no
      created_at_start: $created_at_start
      created_at_end: $created_at_end
      total_start: $total_start
      total_end: $total_end
      actual_amount_start: $actual_amount_start
      actual_amount_end: $actual_amount_end
      rebate_hongbao_start: $rebate_hongbao_start
      rebate_hongbao_end: $rebate_hongbao_end
      hongbao_start: $hongbao_start
      hongbao_end: $hongbao_end
      limit: $limit
      page: $page
      pos_code_name: $pos_code_name
    ) {
      data {
        id
        order_no
        created_at
        actual_amount
        pay_type
        user {
          username
        }
        rebate_hongbao
        hongbao
        hongbao_subsidy
        total
        pay_at
        received_at
        pos_code_name
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;

//扫码付款记录
export const Get_StoreCommissions_Query = gql`
  query (
    $order_no: String
    $username: String
    $start_time: Int
    $end_time: Int
    $amount_min: Float
    $amount_max: Float
    $pay_type: Int
    $is_first: Int
    $type: Int
    $limit: Int
    $page: Int
  ) {
    storeCommissions(
      order_no: $order_no
      username: $username
      start_time: $start_time
      end_time: $end_time
      amount_min: $amount_min
      amount_max: $amount_max
      pay_type: $pay_type
      type: $type
      is_first: $is_first
      limit: $limit
      page: $page
    ) {
      data {
        id
        type
        type_name
        amount
        pay_type
        order {
          id
          order_no
          created_at
          store {
            name
          }
          pay_at
          pay_type
          user {
            username
          }
          total
        }
        order_qr {
          id
          total
          order_no
          created_at
          store {
            name
          }
          pay_at
          pay_type
          user {
            username
          }
        }
        created_at
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;
