import gql from "graphql-tag";

// 增加商家典金计划规则
export const ADD_COMMISSION_RULE = gql`
  mutation (
    $name: String!
    $commission: Float!
    $commission_qr: Float!
    $start_at: Int!
    $end_at: Int!
    $rule_type: Int!
  ) {
    addCommissionRule(
      name: $name
      commission: $commission
      start_at: $start_at
      end_at: $end_at
      rule_type: $rule_type
      commission_qr: $commission_qr
    ) {
      id
    }
  }
`;

// 编辑商家典金计划规则
export const EDIT_COMMISSION_RULE = gql`
  mutation (
    $id: ID!
    $name: String!
    $commission: Float!
    $commission_qr: Float!
    $start_at: Int!
    $status: Int!
    $end_at: Int!
    $rule_type: Int!
  ) {
    editCommissionRule(
      id: $id
      name: $name
      commission: $commission
      start_at: $start_at
      status: $status
      end_at: $end_at
      rule_type: $rule_type
      commission_qr: $commission_qr
    ) {
      id
      status
    }
  }
`;

// 商家典金计划规则(编辑)
export const EDIT_RULES = gql`
  query ($id: ID!) {
    commissionRule(id: $id) {
      id
      name
      commission
      status
      start_at
      end_at
      rule_type
    }
    user {
      store {
        id
        category_commission_rule {
          commission_list
          min_commission
        }
      }
    }
  }
`;

// 商家典金计划规则(编辑)
export const EDIT_RULE = gql`
  query {
    user {
      store {
        id
        category_commission_rule {
          commission_list
          min_commission
          min_commission_qr
        }
      }
    }
  }
`;

//商家典金计划规则查询
export const Get_CommissionRule_Query = gql`
  query {
    commissionRules {
      id
      name
      commission
      commission_qr
      status
      start_at
      end_at
      rule_type
    }
  }
`;
// 删除商家典金计划规则
export const Delete_Commission_Mutation = gql`
  mutation ($id: ID!) {
    delCommissionRule(id: $id)
  }
`;

// 红包活动
export const ACTIVITY = gql`
  mutation ($commission_rate: Float!) {
    hongbaoEnroll(commission_rate: $commission_rate)
  }
`;

// 最低红包比例
export const MIN_HONGBAO_COMMISSION = gql`
  query {
    storeInfo {
      category_commission_rule {
        min_hongbao_commission
      }
    }
  }
`;
