/*
 * @Author: Fan
 * @Date: 2020-12-15 15:47:31
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-23 10:22:48
 * @Description: file content
 */
import React, { useEffect, useState } from "react";
import "./index.less";
import { Spin, message } from "antd";
import moment from "moment";
import { useQuery, useMutation } from "react-apollo";
import { isNonEmptyArray } from "@/utils";
import { useParams, useHistory } from "react-router";
import Contact from "@/components/public/contact/contact";
import Centers from "@/components/public/contact/center";
import Client from "@/components/public/contact/client";
import Information from "@/components/public/contact/information";
import { GET_STORE_SIGN, POST_STORE_SIGNS } from "@/graphql/storesigns.js";

const MsgDetail = () => {
  const params = useParams();
  const history = useHistory();
  const [btnDisable, setBtnDisable] = useState(false);
  const [postResult, setPost] = useState("");
  const { data, loading } = useQuery(GET_STORE_SIGN, {
    variables: {
      id: params.id,
    },
    fetchPolicy: "no-cache",
  });
  const [post] = useMutation(POST_STORE_SIGNS, {
    variables: {
      id: params.id,
    },
    onCompleted: (data) => {
      setBtnDisable(!!data.storeSign);
      if (data.storeSign) {
        history.push("/messages");
      }
    },
    onError: (err) => {},
  });
  useEffect(() => {
    if (data) {
      setBtnDisable(!!data.storeSign.status);
    }
  }, [data]);
  return (
    <div className="msg-flex">
      <div className="msg-detail-wrapper">
        {loading ? (
          <div className="msg-center">
            <Spin />
          </div>
        ) : (
          <>
            <p className="msg-center title">{data?.storeSign.title}</p>
            <div
              className="msg-content"
              dangerouslySetInnerHTML={{ __html: data?.storeSign.content }}
            ></div>
            <div className="agree-btn">
              <a
                href="javascript:void(0);"
                className={btnDisable || !data ? "disable" : ""}
                onClick={() => {
                  data && !btnDisable && post();
                }}
              >
                已阅读，同意
              </a>
            </div>
          </>
        )}
      </div>
      <div className="index-contact">
        <Centers
          history={history}
          title={'公告通知'}
          type={3}
        />
        <Contact/>
        <Information/>
        <Client/>
        <Centers
          history={history}
          title={'学习中心'}
          type={2}
        />
      </div>
    </div>
  );
};
export default MsgDetail;
