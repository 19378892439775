import React, {useEffect, useState} from 'react';
import {Input, Message} from 'antd';
import './index.less';
import {ACTIVITY, MIN_HONGBAO_COMMISSION} from "../../../graphql/commission";
import client from "@/graphql/apollo-config";


const HongbaoSet = (props) => {
    const [commissionRate, setCommissionRate] = useState(0);
    const [min, setMIn] = useState(0);
    useEffect(() => {
        requestData()
    }, []);
    const history = props.history
    const requestData = async () => {
        try {
            const {data} = await client.query({
                query: MIN_HONGBAO_COMMISSION,
                fetchPolicy: "no-cache"
            });
            if (data) {
                console.log(data)
                setMIn(data.storeInfo.category_commission_rule.min_hongbao_commission);
            }

        } catch (e) {

        }
    }
    const check = () => {
        if (!commissionRate) {
            Message.info('请输入最低档返红包比例');
            return false;
        }
        if (commissionRate.toString().indexOf(".") !== -1 && commissionRate.toString().split(".")[1].length > 2) {
            Message.info('最多输入两位小数的数字');
            return false;
        }
        if (commissionRate < min) {
            Message.info('最低填入' + min + '%');
            return false;
        }
        return true;
    }
    const submit = async () => {
        if (await check()) {
            try {
                const {data} = await client.mutate({
                    mutation: ACTIVITY,
                    variables: {
                        commission_rate: commissionRate
                    }
                });
                console.log(data)
                if (data.hongbaoEnroll) {
                    Message.info('签约成功，返红包活动已开启')
                    history.push('/')
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    return <div className="hongbaoset-container">
        <div className="tips">请填写返红包比例：</div>
        <div className="hongbaoset-content_wrapper">
            <div className="title">
                <i className="red">* </i>
                返红包比例
            </div>
            <div className="content">
                <Input
                    placeholder=""
                    className="my-input"
                    maxLength={18}
                    onChange={(e) => setCommissionRate(e.target.value)}/>
                <div className="end">%</div>
            </div>
            <div className='min'>(最低填入{min}%)</div>
        </div>
        <div className='sub' onClick={() => submit()}>提交</div>
    </div>
};

export default HongbaoSet;
