// 若为非空数组则返回true,否则false
import { useEffect, useRef, useCallback } from "react";
import { Message } from "antd";
import app from "../app.json";
import ExportJsonExcel from 'js-export-excel'

const isNonEmptyArray = (value) => {
  return Array.isArray(value) && value.length > 0;
};
const useIsMounted = () => {
  const ref = useRef(false);
  const cb = useCallback(() => ref.current, []);

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  return cb;
};

const emojiRule =
  /[\u{1F601}-\u{1F64F}\u{2702}-\u{27B0}\u{1F680}-\u{1F6C0}\u{1F170}-\u{1F251}\u{1F600}-\u{1F636}\u{1F681}-\u{1F6C5}\u{1F30D}-\u{1F567}]/gu;

// 获取地址url的query
const getQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
};
/**
 * 遍历多维数组，获取选中的某一项，并且得到其所有父级项，返回数组
 * @param { * 多维数组 } obj
 * @param { * 选中的唯一标志 } selectedKeys
 * @param { * 需要对比的唯一标志 } key
 * return 每一项
 */
const traverseArray = (obj, selectedKeys, key) => {
  let arr = [];
  for (let a in obj) {
    // console.log(obj[a][key], selectedKeys,"里面的");
    if (obj[a][key] === selectedKeys) {
      arr.push(obj[a]);
      break;
    } else if (obj[a].children) {
      let newArr = traverseArray(obj[a].children, selectedKeys, key);
      if (newArr.length > 0) {
        arr = [...arr, obj[a], ...newArr];
        break;
      }
    }
  }
  return arr;
};

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// 统一社会信用编码
const checkSocialCreditCode = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/;

// 身份证
const isCardNo = (card) => {
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
  let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (reg.test(card) === false) {
    return false;
  }
  return true;
};

const operateImg = (data, thumbUrl, s) => {
  let b = [];
  let a = {};
  a.url = data;
  a.uid = new Date().getTime();
  a.name = new Date().getTime();
  if (thumbUrl) {
    a.thumbUrl = thumbUrl;
  }
  b.push(a);
  if (s) {
    s([...b]);
  } else {
    return b;
  }
};

// 处理传给后台的图片地址
const handleImg = (urls) => {
  if (urls?.length > 0) {
    const formatList = urls.map((item) =>
      item.url.replace(`${app.oss_host}`, "")
    );
    return urls.length > 1 ? formatList : formatList.join("");
  }
  return ''
};

const verification = (rule) => {
  for (let item of rule) {
    if (item.rule) {
      Message.info(item.title);
      return false;
    }
  }
  return true;
};

//字符串判空
const isEmpty = (obj) => {
  if (typeof obj == "undefined" || obj == null || obj == "") {
    return true;
  } else {
    return false;
  }
};

//支付方式
const formatPayType = (type) => {
  //1支付宝支付 2微信支付 3微信小程序支付 4微信内支付 5通联微信内支付 6通联微信小程序支付 7通联支付宝支付 10跳过支付
  if ([0, 2, 3, 4, 5, 6, 8].includes(type)) {
    return "微信支付";
  } else if ([1, 7].includes(type)) {
    return "支付宝支付";
  }
  return "--";
};

const getCommissionType = (type) => {
  //0是首单 1是其他
  //1用户 2平台 3代理 4商家 5商家首单 6用户首单 7营业额
  if (type == 5 || type == 6) {
    return 0;
  } else {
    return 1;
  }
};

/**
 * 个位数前面补 0
 * @param {number} d 数字
 * @param {number} len 长度
 * @returns {string}
 */
const padStartNumber = (d, len = 2) => {
  return String(d).padStart(len, '0')
}

/**
 * 时间戳转日期字符串
 * @param {number} timestamp 时间戳
 * @param {number} type 字符串类型
 * @param {string} dSpace 日期分隔符
 * @param {string} tSpace 时间分隔符
 * @returns {string}
 */
const timestampToString = (timestamp, type, dSpace = '-', tSpace = ':') => {
  const d = new Date(+timestamp)
  const [Y, M, D, h, m, s] = [
    d.getFullYear(),
    padStartNumber(d.getMonth() + 1),
    padStartNumber(d.getDate()),
    padStartNumber(d.getHours()),
    padStartNumber(d.getMinutes()),
    padStartNumber(d.getSeconds())
  ]
  const obj = {
    1: `${Y + dSpace + M + dSpace + D}`, // YYYY-MM-DD
    2: `${Y + dSpace + M + dSpace + D} ${h + tSpace + m}`, // YYYY-MM-DD hh:mm
    3: `${Y + dSpace + M + dSpace + D} ${h + tSpace + m + tSpace + s}`, // YYYY-MM-DD hh:mm:ss
    4: `${h + tSpace + m}`, // hh:mm
    5: `${h + tSpace + m + tSpace + s}`, // hh:mm:ss
  }
  return obj[type]
}

/**
 * 复制文本
 * @param {string} text 要复制的文本
 * @returns {Promise}
 */
async function copyToClipboard(text) {
  if (!navigator.clipboard) {
    // 如果不支持Clipboard API，则使用旧方法尝试复制
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      document.body.removeChild(textArea);
      return successful;
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      document.body.removeChild(textArea);
      return false;
    }
  }
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    console.error('Error using clipboard.write:', err);
    return false;
  }
}

const copy = async(text) => {
  const res = await copyToClipboard(text)
  if (res) {
    Message.success('复制成功')
  }
}

const formatNumber = (n, len = 2) => {
  return parseFloat(Number(n).toFixed(len))
}

/**
 * 金额格式化 例: 1234567.89 -> 1,234,567.89
 * @param {number} num
 * @returns {string}
 */
const formatAmount = (num) => {
  if (!isNaN(Number(num))) {
    const arr = Number(num).toFixed(2).split('.')
    arr[0] = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return Number(arr[1]) > 0 ? arr.join('.') : arr[0]
  }
  return '0.00'
}

// 导出Excel
const toExportExcel = ({ fileName, data, header, filter }) => {
  const option = {}
  option.fileName = fileName || Date.now()
  option.datas = [
    {
      sheetData: data,
      sheetName: 'sheet',
      sheetHeader: header,
      sheetFilter: filter,
      columnWidths: Array(header.length).fill('10')
    }
  ]
  const toExcel = new ExportJsonExcel(option)
  toExcel.saveExcel()
  Message.success('导出成功')
}

// 获取表格头部数字对应的字母
const excelColumnNumberToCode = (num) => {
  let result = ''
  while (num > 0) {
    let mod = (num - 1) % 26
    result = String.fromCharCode(mod + 65) + result
    num = Math.floor((num - mod) / 26)
  }
  return result
}

const excelColumnNumberToCodes = (start, end) => {
  if (!end) {
    end = start
    start = 1
  }
  const result = []
  for (let i=start; i<=end; i++) {
    result.push(excelColumnNumberToCode(i))
  }
  return result
}

export {
  isNonEmptyArray,
  useIsMounted,
  getQueryString,
  traverseArray,
  emojiRule,
  getBase64,
  checkSocialCreditCode,
  isCardNo,
  operateImg,
  verification,
  handleImg,
  isEmpty,
  formatPayType,
  getCommissionType,
  padStartNumber,
  timestampToString,
  copyToClipboard,
  copy,
  formatNumber,
  formatAmount,
  toExportExcel,
  excelColumnNumberToCode,
  excelColumnNumberToCodes,
};
