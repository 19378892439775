import React from "react";
import {
  Table,
  Button,
  Space,
  Message,
  Modal,
  Form,
  Col,
  Row,
  Input,
} from "antd";
import client from "@/graphql/apollo-config";
import ExportJsonExcel from "js-export-excel";
import { SUPPLIERS_LIST, DEL_SUPPLIER } from "@/graphql/store";

const LIMIT = 10;

const SupplyChain = (props) => {
  const { history } = props;
  const [dataSource, setDataSource] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [searchParams, setSearchParams] = React.useState({
    name: "",
    mobile: "",
  });
  const [form] = Form.useForm();

  const requestData = React.useCallback(async () => {
    try {
      const {
        data: {
          suppliers: { data: list, current_page, total },
        },
      } = await client.query({
        query: SUPPLIERS_LIST,
        fetchPolicy: "no-cache",
        variables: {
          page,
          limit: LIMIT,
          ...searchParams,
        },
      });
      setDataSource(list);
      setPage(current_page);
      setTotal(total);
    } catch (e) {}
  }, [page, searchParams]);

  const columns = React.useMemo(() => {
    return [
      {
        dataIndex: "name",
        title: "供应商名称",
      },
      {
        dataIndex: "mobile",
        title: "手机号码",
      },
      {
        dataIndex: "address",
        title: "售后地址",
      },
      {
        dataIndex: "accounting_period",
        title: "账期",
      },
      {
        dataIndex: "weekdays_stop_order",
        title: "工作日截单时间",
      },
      {
        dataIndex: "weekend_deliver",
        title: "周末发货安排",
      },
      {
        dataIndex: "remark",
        title: "备注",
      },
      {
        dataIndex: "operation",
        title: "操作",
        render: (_, record) => (
          <Space size="middle">
            <Button
              type="link"
              onClick={() =>
                history.push(`/supplyChain/addSupplyChain?id=${record.id}`)
              }
            >
              编辑
            </Button>
            <Button
              type="link"
              danger
              onClick={() =>
                Modal.confirm({
                  content: "是否删除该供应商",
                  onOk() {
                    deleteData(+record.id);
                  },
                })
              }
            >
              删除
            </Button>
          </Space>
        ),
      },
    ];
  }, [history]);

  const exportExcel = (data) => {
    let option = {};
    console.log(data, "--sdfs");
    option.fileName = new Date().getTime();

    option.datas = [
      {
        sheetData: data,
        sheetName: "sheet",
        sheetHeader: columns
          .filter((item) => item.dataIndex !== "operation")
          .map((i) => i.title),
        sheetFilter: columns.map((i) => i.dataIndex),
        columnWidths: Array(15).fill("10"),
      },
    ];

    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  const getAllData = async () => {
    try {
      const {
        data: {
          suppliers: { data: list },
        },
      } = await client.query({
        query: SUPPLIERS_LIST,
        variables: {
          ...searchParams,
          page: 1,
          limit: total,
        },
        fetchPolicy: "no-cache",
      });
      exportExcel(list);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async (id) => {
    try {
      const { data } = await client.mutate({
        mutation: DEL_SUPPLIER,
        variables: { id },
      });
      if (data.deleteSupplier) {
        Message.success("删除成功");
        
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (e) {}
  };

  const getFields = () => {
    return (
      <>
        <Col span={6}>
          <Form.Item name="name" label="供应商名称">
            <Input placeholder="请输入供应商名称" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="mobile" label="手机号码">
            <Input placeholder="请输入手机号码" type="number" />
          </Form.Item>
        </Col>
      </>
    );
  };

  React.useEffect(() => {
    requestData();
  }, [requestData]);

  return (
    <div className="supplyChain-wrapper">
      <Form
        form={form}
        onFinish={(v) => {
          setPage(1);
          setSearchParams(v);
          requestData();
        }}
      >
        <Row gutter={24}>{getFields()}</Row>
        <Row>
          <Col span={24}>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
          </Col>
        </Row>
        <Row
          style={{
            margin: "10px 0",
          }}
        >
          <Col span={24}>
            <Button
              type="primary"
              onClick={() => history.push("/supplyChain/addSupplyChain")}
            >
              添加供应商
            </Button>
            <Button
              type="primary"
              onClick={getAllData}
              style={{
                marginLeft: "12px",
              }}
            >
              <span style={{ color: "white" }}>导出Excel</span>
            </Button>
          </Col>
        </Row>

        <Table
          pagination={{
            position: ["bottomCenter"],
            defaultCurrent: 1,
            defaultPageSize: LIMIT,
            onChange: (page) => setPage(page),
            showSizeChanger: false,
            showQuickJumper: true,
            total: total,
            current: page,
          }}
          rowKey="id"
          dataSource={dataSource}
          columns={[
            {
              dataIndex: "name",
              title: "供应商名称",
            },
            {
              dataIndex: "mobile",
              title: "手机号码",
            },
            {
              dataIndex: "address",
              title: "售后地址",
            },
            {
              dataIndex: "accounting_period",
              title: "账期",
            },
            {
              dataIndex: "weekdays_stop_order",
              title: "工作日截单时间",
            },
            {
              dataIndex: "weekend_deliver",
              title: "周末发货安排",
            },
            {
              dataIndex: "remark",
              title: "备注",
            },
            {
              dataIndex: "operation",
              title: "操作",
              render: (_, record) => (
                <Space size="middle">
                  <Button
                    type="link"
                    onClick={() =>
                      history.push(
                        `/supplyChain/addSupplyChain?id=${record.id}`
                      )
                    }
                  >
                    编辑
                  </Button>
                  <Button
                    type="link"
                    danger
                    onClick={() =>
                      Modal.confirm({
                        content: "是否删除该供应商",
                        onOk() {
                          deleteData(+record.id);
                        },
                      })
                    }
                  >
                    删除
                  </Button>
                </Space>
              ),
            },
          ]}
        />
      </Form>
    </div>
  );
};

export default SupplyChain;
