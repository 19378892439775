import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Category from "./page/category";
import Index from "./page/index";
import Login from "./page/login";
// import Order from "./page/order";
import ExpressDelivery from './page/order/ExpressDelivery';
import TakeoutDelivery from './page/order/TakeoutDelivery';
import TakeoutSelfHelp from './page/order/TakeoutSelfHelp';
import ExpressDeliveryDetail from './page/order/ExpressDeliveryDetail';
import TakeoutDetail from './page/order/takeoutDetail';
import TakeoutSelfHelpDetail from './page/order/takeoutSelfHelpDetail';
import ScanCodeOrder from './page/order/ScanCodeOrder';
import ScanCodeOrderDetail from './page/order/scanCodeOrderDetail';
import RefundList from './page//order/refundList'
// import LTdelivery from "./page/order/LTdelivery";
// import GeneralDelivery from "./page/order/GeneralDelivery";
// import ECoupon from "./page/order/ECoupon";
// import QRorderDelivery from "./page/order/QRorderDelivery";
// import Product from "./page/product";
import Product2 from "./page/product/index2";
import BasicInfo from "./page/basicInfo";
import JoinDz from "./page/joinDz";
// import NewProduct from "./page/product/newproduct/newProduct1";
import NewProduct2 from "./page/product/newproduct/newProduct2";
import Connect from "./page/index/connect";
import Explain from "./page/index/explain";
import Resources from "./page/resources";
import Commission from "./page/commission";
import SetCommission from "./page/commission/setcommission";
import Forgetpwd from "./page/forgetpwd";
import NewClass from "./page/category/newclass";
import Logistics from "./page/logistics";
import Setting from "./page/setting";
import Store from "./page/store";
import CommissionDetails from "./page/commissionDetails";
import Download from "./page/download";
import Record from "./page/record";
import Invitation from "./page/invitation";
// import GiftRecome from "./page/giftRecome";
import HongbaoSet from "./page/index/hongbaoset";
import Messages from "./page/messages";
import Msgdetail from "./page/msgdetail";
// import Board from "./page/board";
// import BoardEdit from "./page/board/boardEdit";
import Coupon from "./page/coupon";
import AddCoupon from "./page/coupon/addCoupon";
// import Refund from "./page/order/Refund";
import SupplyChain from "./page/supplyChain";
import AddSupplyChain from "./page/supplyChain/add";
// import Detail from "./page/order/detail";
import PaymentCode from "./page/paymentCode";
import AddPaymentCode from "./page/paymentCode/add";
import CouponRecord from './page/mealCoupon/couponRecord'
import UsedCouponRecord from './page/mealCoupon/usedCouponRecord'
import DeductCouponRecord from './page/mealCoupon/deductCouponRecord'
import CouponRecordDetail from './page/mealCoupon/couponRecordDetail'
import UsedCouponRecordDetail from './page/mealCoupon/usedCouponRecordDetail'
import DeductCouponRecordDetail from './page/mealCoupon/deductCouponRecordDetail'
// import Dishes from "./page/dishes/index";
// import AddDishes from "./page/dishes/add/index";
import PackingFeeSetting from './page/packingFeeSetting/index'
import SubStop from './page/subStop/index'
import SubStopAdd from './page/subStop/add/add'

/**
 * siderList 每一项说明
 * * 号为必填
 * @param { * 菜单标题 , string} title
 * @param { * 跳转地址 , string} path
 * @param { * 路由组件 , React.ComponentType } component
 * @param { 分组的菜单项 , []} children
 * @param { 需要激活的侧边栏项，以path为值 , string} activeMenu
 * @param { 需要展开的侧边栏项，以上一级path为值 ,[]} openKeys[]
 * @param { 是否在侧边栏隐藏，默认值false } hidden
 * @param { 是否需要token权限，默认值false } requiresAuth
 * key跟link保持一致，需要通过link判断当前的key
 */
export const order_children = [
  {
    title: '外卖配送订单',
    path: '/TakeoutDelivery',
    url: '/TakeoutDelivery',
    component: TakeoutDelivery,
    requiresAuth: true,
  },
  {
    title: '外卖自取订单',
    path: '/TakeoutSelfHelp',
    url: '/TakeoutSelfHelp',
    component: TakeoutSelfHelp,
    requiresAuth: true,
  },
  {
    title: '普通物流订单',
    path: '/ExpressDelivery',
    url: '/ExpressDelivery',
    component: ExpressDelivery,
    requiresAuth: true,
  },
  {
    title: '扫码点餐订单',
    path: '/ScanCodeOrder',
    url: '/ScanCodeOrder',
    component: ScanCodeOrder,
    requiresAuth: true,
  },
  // {
  //   title: "同城配送订单",
  //   path: "/LTdelivery",
  //   url: "/LTdelivery",
  //   component: LTdelivery,
  //   requiresAuth: true,
  // },
  // {
  //   title: "普通物流订单",
  //   path: "/GeneralDelivery",
  //   url: "/GeneralDelivery",
  //   component: GeneralDelivery,
  //   requiresAuth: true,
  // },
  // {
  //   title: "扫码点餐订单",
  //   path: "/QRorderDelivery",
  //   url: "/QRorderDelivery",
  //   component: QRorderDelivery,
  //   requiresAuth: true,
  // },

  {
    title: "退款/退货管理",
    path: "/RefundList",
    url: "/RefundList",
    component: RefundList,
    requiresAuth: true,
  },
  // {
  //   title: "电子卡券订单",
  //   path: "/ECoupon",
  //   url: "/ECoupon",
  //   component: ECoupon,
  //   requiresAuth: true,
  // },
];

export const RouterList = [
  { title: "首页", path: "/", component: Index, requiresAuth: true },
  {
    title: "绑定收款银行信息",
    path: "/index/connect",
    component: Connect,
    hidden: true,
    activeMenu: "/index",
    requiresAuth: true,
  },
  {
    title: "签约消息列表",
    path: "/messages",
    component: Messages,
    hidden: true,
    requiresAuth: true,
  },
  {
    title: "签约消息详情",
    path: "/msgdetail/:id",
    component: Msgdetail,
    hidden: true,
    requiresAuth: true,
  },
  {
    title: "返红包说明",
    path: "/index/explain",
    component: Explain,
    hidden: true,
    activeMenu: "/index",
    requiresAuth: true,
  },
  {
    title: "设置返红包比例",
    path: "/index/hongbaoset",
    component: HongbaoSet,
    hidden: true,
    activeMenu: "/index",
    requiresAuth: true,
  },
  {
    title: "入驻信息",
    path: "/joinDz",
    component: JoinDz,
    requiresAuth: true,
  },
  {
    title: "特约商户申请",
    path: "/basicInfo",
    component: BasicInfo,
    requiresAuth: true,
  },
  {
    title: "线上开店认证",
    path: "/store",
    component: Store,
    requiresAuth: true,
  },
  {
    title: "商品管理",
    path: "/product2",
    component: Product2,
    requiresAuth: true,
  },
  {
    title: "商品添加",
    path: "/product2/newproduct",
    component: NewProduct2,
    hidden: true,
    activeMenu: "/product2",
    requiresAuth: true,
  },
  {
    title: "打包费设置",
    path: "/packingFeeSetting",
    component: PackingFeeSetting,
    hidden: true,
    activeMenu: "/product2",
    requiresAuth: true,
  },
  // {
  //   title: "好物管理",
  //   path: "/product",
  //   component: Product,
  //   requiresAuth: true,
  // },
  // {
  //   title: "商品添加",
  //   path: "/product/newproduct",
  //   component: NewProduct,
  //   hidden: true,
  //   activeMenu: "/product",
  //   requiresAuth: true,
  // },
  {
    title: "设置赏金计划",
    path: "/commission/setcommission",
    component: SetCommission,
    hidden: true,
    activeMenu: "/commission",
    requiresAuth: true,
  },
  {
    title: "商品类目",
    path: "/category",
    component: Category,
    requiresAuth: true,
  },
  {
    title: "订单管理",
    path: "/order",
    children: order_children
  },
  // {
  //   title: "堂食菜品",
  //   path: "/dishes",
  //   component: Dishes,
  //   requiresAuth: true,
  // },
  // {
  //   title: "添加菜品",
  //   path: "/dishes/add",
  //   component: AddDishes,
  //   hidden: true,
  //   activeMenu: "/dishes",
  //   requiresAuth: true,
  // },
  {
    title: "赏金计划设置",
    path: "/commission",
    component: Commission,
    requiresAuth: true,
  },

  {
    title: "邀请人数",
    path: "/invitation",
    component: Invitation,
    requiresAuth: true,
  },
  {
    title: "赏金计划收入明细",
    path: "/commissionDetails",
    component: CommissionDetails,
    requiresAuth: true,
  },
  {
    title: "物流设置",
    path: "/logistics",
    component: Logistics,
    requiresAuth: true,
  },

  {
    title: "下载收款码",
    path: "/download",
    component: Download,
    requiresAuth: true,
    hidden: true,
  },
  {
    title: "收款码列表",
    path: "/paymentCode",
    component: PaymentCode,
    requiresAuth: true,
  },

  {
    title: "添加收款码",
    path: "/paymentCode/add",
    component: AddPaymentCode,
    requiresAuth: true,
    hidden: true,
  },
  {
    title: "扫码付款记录",
    path: "/record",
    component: Record,
    requiresAuth: true,
  },

  {
    title: "餐券记录",
    path: "/mealCoupon",
    children: [
      {
        title: "发放餐券记录",
        path: "/couponRecord",
        component: CouponRecord,
        requiresAuth: true,
      },
      {
        title: "本店餐券使用",
        path: "/usedCouponRecord",
        component: UsedCouponRecord,
        requiresAuth: true,
      },
      {
        title: "它店餐券抵扣",
        path: "/deductCouponRecord",
        component: DeductCouponRecord,
        requiresAuth: true,
      },
      {
        title: "发放餐券记录详情",
        path: "/couponRecord/detail",
        component: CouponRecordDetail,
        hidden: true,
        activeMenu: "/couponRecord",
        requiresAuth: true,
      },
      {
        title: "本店餐券使用详情",
        path: "/usedCouponRecord/detail",
        component: UsedCouponRecordDetail,
        hidden: true,
        activeMenu: "/usedCouponRecord",
        requiresAuth: true,
      },
      {
        title: "它店餐券抵扣详情",
        path: "/deductCouponRecord/detail",
        component: DeductCouponRecordDetail,
        hidden: true,
        activeMenu: "/deductCouponRecord",
        requiresAuth: true,
      },
    ]
  },
  {
    title: "重置密码",
    path: "/forgetpwd",
    component: Forgetpwd,
    hidden: true,
  },
  {
    title: "登录",
    path: "/login",
    component: Login,
    hidden: true,
  },
  {
    title: "协议",
    path: "/resources",
    component: Resources,
    hidden: true,
  },
  {
    title: "新增商品类目",
    path: "/category/newclass",
    component: NewClass,
    hidden: true,
    activeMenu: "/category",
    requiresAuth: true,
  },
  // {
  //   title: "返红包记录",
  //   path: "/giftRecome",
  //   component: GiftRecome,
  //   requireAuth: true,
  // },
  // {
  //   title: "桌台管理",
  //   path: "/board",
  //   component: Board,
  //   requireAuth: true,
  // },
  // {
  //   title: "桌台添加",
  //   path: "/board/newBoard",
  //   component: BoardEdit,
  //   hidden: true,
  //   activeMenu: "/board",
  //   requiresAuth: true,
  // },
  {
    title: "供应商管理",
    path: "/supplyChain",
    component: SupplyChain,
    requireAuth: true,
  },
  {
    title: "添加供应商",
    path: "/supplyChain/AddSupplyChain",
    component: AddSupplyChain,
    hidden: true,
    activeMenu: "/supplyChain",
    requiresAuth: true,
  },
  {
    title: "红包券管理",
    path: "/coupon",
    component: Coupon,
    requireAuth: true,
  },
  {
    title: "添加红包券",
    path: "/coupon/AddCoupon",
    component: AddCoupon,
    hidden: true,
    activeMenu: "/coupon",
    requiresAuth: true,
  },
  {
    title: "店铺管理",
    path: "/subStop",
    component: SubStop,
    requireAuth: true,
  },
  {
    title: "添加子店铺",
    path: "/subStop/add",
    component: SubStopAdd,
    hidden: true,
    activeMenu: "/subStop",
    requiresAuth: true,
  },
  {
    title: "设置",
    path: "/setting",
    component: Setting,
    requiresAuth: true,
  },
  // {
  //   title: "订单详情",
  //   path: "/orderDetail",
  //   component: Detail,
  //   hidden: true,
  //   // activeMenu: "/coupon",
  //   requiresAuth: true,
  // },
  {
    title: "物流订单详情",
    path: "/expressDeliveryDetail",
    component: ExpressDeliveryDetail,
    hidden: true,
    activeMenu: "/ExpressDelivery",
    requiresAuth: true,
  },
  {
    title: "外卖订单详情",
    path: "/takeoutDetail",
    component: TakeoutDetail,
    hidden: true,
    activeMenu: "/TakeoutDelivery",
    requiresAuth: true,
  },
  {
    title: "外卖自取详情",
    path: "/takeoutSelfHelpDetail",
    component: TakeoutSelfHelpDetail,
    hidden: true,
    activeMenu: "/TakeoutSelfHelp",
    requiresAuth: true,
  },
  {
    title: "扫码点餐详情",
    path: "/scanCodeOrderDetail",
    component: ScanCodeOrderDetail,
    hidden: true,
    activeMenu: "/ScanCodeOrder",
    requiresAuth: true,
  },
];

// 需要token权限的路由
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Cookies.get("token") ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);
const RouteItemDom = (item) => {
  const path = item.url ? item.url : item.path;
  return item.requiresAuth ? (
    <PrivateRoute
      exact
      path={path}
      key={item.path}
      component={item.component}
    />
  ) : (
    <Route exact path={path} key={item.path} component={item.component} />
  );
};
const RouteListDom = (list) => {
  return list.map((item) => {
    return item.children ? RouteListDom(item.children) : RouteItemDom(item);
  });
};

const BasicRoute = () => (
  <Switch>
    {/* exact 严格模式，统一开启 */}
    {RouteListDom(RouterList)}
  </Switch>
);

export default BasicRoute;
