import React, { useState, useEffect, useMemo } from "react";
import { Radio, Message, Select, Input, Button, Table, Space, Modal, DatePicker } from "antd";
import "./index.less";
import {
  Change_Store_Mutation,
  DELIVERY_WAY,
  REGION,
} from "../../graphql/store";
import client from "../../graphql/apollo-config";
import { getExpressBalance, setExpressPrefer, getTransList, recharge } from "@/apis/logistics";

import UploadBusinessModal from "../../components/modal/uploadbusiness/uploadBusinessModal";
import { DeleteOutlined, SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
const { RangePicker } = DatePicker;

const amountList = [200, 300, 500, 1000, 3000, 5000]
const Logistics = () => {
  const [storeInfo, setStoreInfo] = useState(false);
  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const [OSSData, setOSSData] = useState({});
  const [provinceList, setProvinceList] = useState([]);
  const [specialList, setSpeciaList] = useState([]);
  const [logist, setLogist] = useState({
    openStatus: 0, //店铺营业状态 0不营业 1营业
    id: 0,
    deliveryTy: 0, // 物流类型
    //同城
    logistics: [],
    fnptFreightType: 0,
    fnptCustomerFreight: 0,
    //普通
    isExpress: 0, // 是否有启用普通快递服务 0没 1有
    selfCustomerFreight: 0, // 普通快递配送自定义运费
    freeDelivery: 0, // 普通快递是否包邮 0是 1否
    free_threshold: 0, //包邮门槛
  });
  const [weapp, setWeapp] = useState({
    all_balance: 0, // 总余额
    order_pattern: '', // 配送偏好
    service_trans_prefer: '', // 运力
    delivery_price: '', // 配送费
    pickup_time: 0, // 出餐时间(分钟)
  })
  const [weappOptions, setWeappOptions] = useState([]) // 配送偏好下拉选项
  const [weappExpressOptions, setWeappExpressOptions] = useState([])// 运力下拉选项

  const [visible, setVisible] = useState(false)
  const [amount, setAmount] = useState('')
  const [isError, setIsError] = useState(false)

  const [searchText, setSearchText] = useState('');
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordState, setRecordState] = useState({
    flow_type: 1,
    start_time: undefined,
    end_time: undefined
  })
  const flowTypeOptions = useMemo(() => ([
    { value: 1, label: '充值' },
    { value: 2, label: '消费' },
    { value: 3, label: '退款' },
  ]), [])
  const [dataSource, setDataSource] = useState([])
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  }
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  }
  const handleDateChange = (value, dateString, setSelectedKeys, confirm, clearFilters) => {
    if (value != null) {
      setSelectedKeys(value);
      confirm();
      setRecordState({
        ...recordState,
        start_time: dateString[0],
        end_time: dateString[1],
      })
    } else {
      clearFilters ? clearFilters() : setSelectedKeys([]);
      confirm();
      setRecordState({
        ...recordState,
        start_time: undefined,
        end_time: undefined,
      })
    }
    setPageCurrent(1)
  }
  const columns = useMemo(() => {
    const _col = [
      {
        title: '订单编号',
        dataIndex: 'order_no',
        width: 300,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => {
          return (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
              <Input
                placeholder={`搜索`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
              <Space>
                <Button
                  size="small"
                  style={{ width: 90, height: 27 }}
                  onClick={() => clearFilters && handleReset(clearFilters)}
                >
                  重置
                </Button>
                <Button
                  type="primary"
                  size="small"
                  icon={<SearchOutlined />}
                  style={{ width: 90, height: 27 }}
                  onClick={() => handleSearch(selectedKeys, confirm)}
                >
                  搜索
                </Button>
              </Space>
            </div>
          );
        },
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#F4981D" : undefined }} />
        ),
        onFilter: (value, record) => record.order_no.toString().includes(value),
        render: (text) => (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
              verticalAlign: 'middle'
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ),
      },
      {
        title: '交易类型',
        dataIndex: 'flow_type_text',
      },
      {
        title: '快递类型',
        dataIndex: 'service_trans_id_text',
      },
      {
        title: '金额（¥）',
        dataIndex: 'pay_amount'
      },
      {
        title: '交易时间',
        dataIndex: 'trad_time',
        width: 300,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              showTime={{ format: 'HH:mm:ss' }}
              value={selectedKeys}
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(value, dateString) => handleDateChange(
                value,
                dateString,
                setSelectedKeys,
                confirm,
                clearFilters
              )}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <CalendarOutlined style={{ color: filtered ? "#FFD600" : undefined }} />
        ),
      },
      // {
      //   title: '支付方式',
      //   dataIndex: 'pay_name'
      // }
    ].map(t => {
      t.align = 'center'
      return t
    })
    return _col
  }, [searchText, recordState])

  useEffect(() => {
    getRecordList()
  }, [recordState]);

  useEffect(() => {
    request();
    initAddress();
    getBalance()
  }, []);
  useEffect(() => {
    logist.logistics.forEach((item) => {
      if (item.type == logist.deliveryTy) {
        item.fnptCustomerFreight = logist.fnptCustomerFreight;
      }
    });
  }, [logist]);

  const request = async () => {
    try {
      const { data } = await client.query({
        query: DELIVERY_WAY,
        fetchPolicy: "no-cache",
      });
      setOSSData({
        ...data.oss,
      });
      const user = data.user;
      let logistics = data.logistics.map((item, index) => {
        return {
          ...item,
          fnptFreightType: item.is_custom,
          fnptCustomerFreight: 0,
        };
      });

      if (user) {
        const d = user.store;
        setLogist({
          deliveryTy: d.delivery_type,
          logistics: logistics,
          openStatus: d.open_status,
          id: d.id,
          isExpress: d.is_express,
          fnptFreightType: d.fnpt_freight_type,
          fnptCustomerFreight: d.fnpt_customer_freight,
          freeDelivery: d.free_delivery,
          selfCustomerFreight: d.self_customer_freight,
          free_threshold: d.store_info ? d.store_info.free_threshold : 0,
        });
        setSpeciaList(d.store_info?.province_freight || []);
        setStoreInfo(d);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initAddress = async () => {
    try {
      const { data } = await client.query({
        query: REGION,
        fetchPolicy: "no-cache",
      });
      setProvinceList(
        data.region.map((i) => {
          return {
            id: i.id,
            name: i.name,
          };
        })
      );
    } catch (error) {}
  };

  // 获取运费余额
  const getBalance = async () => {
    try {
      const { data } = await getExpressBalance()
      setWeapp({
        all_balance: data.balance.all_balance,
        order_pattern: data.store.order_pattern,
        service_trans_prefer: data.store.service_trans_prefer,
        delivery_price: data.delivery_price,
        pickup_time: data.pickup_time,
      })
      setWeappOptions(data.trans.map(t => ({ value: t.config_id, label: t.name })))
      setWeappExpressOptions(
        Object.entries(data.trans[1].trans).map(([v, name]) => ({ value: v, label: name }))
      )
    } catch (e) {
      console.log(e)
    }
  }

  // 获取交易记录
  const getRecordList = async () => {
    try {
      const { data } = await getTransList({
        flow_type: recordState.flow_type,
        start_time: recordState.start_time,
        end_time: recordState.end_time
      })
      setDataSource(data)
    } catch(e) {
      console.log(e)
    }
  }

  // change weapp data
  const changeWeappData = (key, value) => {
    setWeapp({
      ...weapp,
      [key]: value
    })
  }

  const changeData = (label, key, value) => {
    if (label != "普通" && key === "fnptFreightType") {
      logist.logistics.forEach((item) => {
        if (item.label === label) {
          item.fnptFreightType = value;
        }
      });
    } else if (label != "普通" && key === "fnptCustomerFreight") {
      logist.logistics.forEach((item) => {
        if (item.label === label) {
          item.fnptCustomerFreight = value;
        }
      });
    } else if (label == null && key === "deliveryTy") {
      logist.deliveryTy = value;
    } else if (label == "普通" && key === "freeDelivery") {
      logist.freeDelivery = value;
    } else if (label == "普通" && key === "selfCustomerFreight") {
      logist.selfCustomerFreight = value;
    }
    setLogist({
      ...logist,
      [key]: value,
    });
  };

  const changeSpecialData = (type, index, data) => {
    switch (type) {
      case "add":
        setSpeciaList([
          ...specialList,
          {
            province: "",
            freight: 0,
          },
        ]);
        break;
      case "delete":
        specialList.splice(index, 1);
        setSpeciaList([...specialList]);
        break;
      case "change":
        specialList[index] = data;
        setSpeciaList([...specialList]);
        break;
      default:
        break;
    }
  };

  // 小程序配送偏好提交
  const onWeappConfirm = async () => {
    try {
      const res = await setExpressPrefer({
        order_pattern: weapp.order_pattern,
        service_trans_prefer: weapp.service_trans_prefer
      })
    } catch (e) {
      console.log(e)
    }
  }

  const send = async () => {
    // if (logist.deliveryTy === 0) {
    //   Message.info("同城配送请6选1");
    //   return;
    // }
    if (logist.freeDelivery === 0 && specialList.length) {
      for (let special of specialList) {
        if (!special.province || !Boolean(special.freight)) {
          Message.info("请完善追加快递费省份设置");
          return;
        }
      }
    }
    try {
      const type = logist.logistics.find((item) => logist.deliveryTy == item.type);
      console.log("type = ", type);
      // if (type && type[0] && type[0].type == 1 && (!storeInfo.credit_code || !storeInfo.business_license || !storeInfo.id_card_front)) {
      //     setShowBusinessModal(true);
      //     return;
      // }
      await onWeappConfirm();
      const { data } = await client.mutate({
        mutation: Change_Store_Mutation,
        variables: {
          open_status: logist.openStatus,
          fnpt_freight_type: type ? type.fnptFreightType : 0,
          fnpt_customer_freight: type ? Number(type.fnptCustomerFreight) : 0,
          self_customer_freight: Number(logist.selfCustomerFreight) || 0,
          is_express: logist.isExpress || 0,
          free_delivery: logist.freeDelivery || 0,
          delivery_type: 2, // logist.deliveryTy,
          free_threshold: logist.free_threshold ? +logist.free_threshold : 0,
          delivery_price: weapp.delivery_price ? +weapp.delivery_price : 0,
          pickup_time: weapp.pickup_time ? +weapp.pickup_time : 0,
          province_freight:
            logist.freeDelivery === 0
              ? specialList.map((item) => {
                  item["__typename"] && delete item["__typename"];
                  return item;
                })
              : [],
        },
        fetchPolicy: "no-cache",
      });

      if (data) {
        Message.success("修改成功");
        request();
        getBalance()
      }
    } catch (e) {
      console.log("e ====", e);
      const graphQLErrors = e.graphQLErrors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        const code = graphQLErrors[0].code;
        if (code == 3677) {
          setShowBusinessModal(true);
        }
      }
    }
  };
  //营业执照上传成功
  const submitBusinessSuccess = (store) => {
    setShowBusinessModal(false);
    setStoreInfo({
      ...storeInfo,
      credit_code: store.credit_code,
      business_license: store.business_license,
      id_card_front: store.id_card_front,
      id_card_no: store.id_card_no,
    });
    // send();
  };

  const onAmountCancel = () => {
    setAmount('')
    setIsError(false)
    setVisible(false)
  }
  // 充值
  const onAmountConfirm = async () => {
    if (+amount < 50) return;
    try {
      const { data } = await recharge({ amount })
      document.onvisibilitychange = function () {
        if (document.visibilityState === 'visible') {
          document.onvisibilitychange = null
          onAmountCancel()
          getBalance()
          setRecordState({
            flow_type: 1,
            start_time: undefined,
            end_time: undefined
          })
          setPageCurrent(1)
        }
      }
      window.open(data.payurl, '_blank')
    } catch(e) {
      console.log(e)
    }
  }

  return (
    <>
    <div className="new-logistics-wrapper">
      <div className="logistics-title">请选择开通物流:</div>
      <div className="logistics-content">
        <div className="logistics-content_title">
          <span style={{ fontWeight: "bold", color: "#000", marginTop: 1 }}>
            同城配送
          </span>
          {/* <span style={{ fontSize: 12, color: "#999" }}>
            （{logist.logistics.length}选1）
          </span> */}
        </div>
        <div className="logistics-content_main">
          <div className="logistics-weapp">
            <div className="logistics-weapp__balance">
              <div>
                <div className="item-title">小程序运费余额</div>
                <div className="item-subtl">
                  ¥<span className="price">{weapp.all_balance}</span>
                </div>
              </div>
              <Button
                type="primary"
                style={{ width: 80, height: 38 }}
                onClick={() => setVisible(true)}
              >充值</Button>
            </div>
            <div className="logistics-weapp__title">小程序配送偏好</div>
            <div className="logistics-weapp__select flex align-center">
              <Select
                style={{ width: 154, fontSize: 12 }}
                value={weapp.order_pattern}
                options={weappOptions}
                onChange={(v) => changeWeappData('order_pattern', v)}
              />
              {weapp.order_pattern === 1 && <span className="text">优先下单低价运力</span>}
              {weapp.order_pattern === 2 && (<>
                <span className="text">优先下单</span>
                <Select
                  style={{ width: 154, fontSize: 12 }}
                  value={weapp.service_trans_prefer}
                  options={weappExpressOptions}
                  onChange={(v) => changeWeappData('service_trans_prefer', v)}
                />
              </>)}
            </div>
          </div>
          <div className="logistics-content_platform">配送费设置</div>
          <div>
            <div className="logistics-child">
              <div
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  marginLeft: 13,
                }}
              >
                配送费
              </div>
              <Input
                suffix="元"
                type="number"
                value={weapp.delivery_price}
                onChange={(e) => changeWeappData('delivery_price', e.target.value)}
                style={{
                  width: 120,
                  marginRight: 30,
                  marginLeft: 15,
                }}
              />
            </div>
          </div>
          <div className="logistics-content_platform">同城配送运费包邮设置</div>
          <div>
            <div className="logistics-child">
              <div
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  marginLeft: 13,
                }}
              >
                同城配送订单达到配送费门槛则用户免运费(0元则不开启运费包邮)
              </div>
              <Input
                suffix="元"
                type="number"
                value={logist.free_threshold}
                onChange={(e) =>
                  changeData(null, "free_threshold", e.target.value)
                }
                style={{
                  width: 120,
                  marginRight: 30,
                  marginLeft: 15,
                }}
              />
            </div>
          </div>
          <div className="logistics-content_platform">出餐准备时间</div>
          <div className="logistics-child">
            <div
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginLeft: 13,
              }}
            >
              订单准备时间最长需
            </div>
            <Input
              suffix="分钟"
              type="number"
              value={weapp.pickup_time}
              onChange={(e) => {
                changeWeappData('pickup_time', e.target.value ? parseInt(e.target.value) + '' : '')
              }}
              style={{
                width: 120,
                marginRight: 30,
                marginLeft: 15,
              }}
            />
          </div>
          {/* <div className="logistics-content_platform">
            1、我需要典栈平台提供同城配送服务
          </div>
          <div>
            {logist.logistics.map((item) => {
              if (item.type != 2) {
                return (
                  <div key={item.type}>
                    <Radio.Group value={logist.deliveryTy}>
                      <div>
                        <Radio
                          style={{
                            fontSize: 16,
                            marginRight: 30,
                            marginBottom: 10,
                          }}
                          value={item.type}
                          onClick={() =>
                            changeData(null, "deliveryTy", item.type)
                          }
                        >
                          {item.title}
                        </Radio>
                        <div className="logistics-child">
                          <div
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              marginLeft: 13,
                            }}
                          >
                            运费设置
                          </div>
                          <Radio.Group
                            value={item.fnptFreightType}
                            style={{ marginLeft: 25 }}
                            disabled={logist.deliveryTy !== item.type}
                          >
                            <Radio
                              style={{ fontSize: 16 }}
                              value={0}
                              onClick={() =>
                                changeData(item.label, "fnptFreightType", 0)
                              }
                            >
                              自定义运费
                              <Input
                                disabled={
                                  logist.deliveryTy !== item.type ||
                                  item.fnptFreightType === 1
                                }
                                suffix="元"
                                type="number"
                                value={
                                  item.fnptFreightType === 1
                                    ? 0
                                    : item.fnptCustomerFreight
                                }
                                onChange={(e) =>
                                  changeData(
                                    item.label,
                                    "fnptCustomerFreight",
                                    e.target.value
                                  )
                                }
                                style={{
                                  width: 120,
                                  marginRight: 30,
                                  marginLeft: 15,
                                }}
                              />
                            </Radio>
                            <Radio
                              style={{ fontSize: 16 }}
                              value={1}
                              onClick={() =>
                                changeData(item.label, "fnptFreightType", 1)
                              }
                            >
                              {item.label}实际产生运费
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </Radio.Group>
                  </div>
                );
              } else {
                return (
                  <div key={item.type}>
                    <Radio.Group value={logist.deliveryTy}>
                      <div>
                        <Radio
                          style={{
                            fontSize: 16,
                            marginRight: 30,
                            marginBottom: 10,
                          }}
                          value={item.type}
                          onClick={() =>
                            changeData(null, "deliveryTy", item.type)
                          }
                        >
                          {item.title}
                        </Radio>
                        <div className="logistics-child">
                          <div
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              marginLeft: 13,
                            }}
                          >
                            运费设置
                          </div>
                          <Radio.Group
                            value={item.fnptFreightType}
                            style={{ marginLeft: 25 }}
                            disabled={logist.deliveryTy !== item.type}
                          >
                            <Input
                              disabled={
                                logist.deliveryTy !== item.type ||
                                item.fnptFreightType === 1
                              }
                              suffix="元"
                              type="number"
                              value={
                                item.fnptFreightType === 1
                                  ? 0
                                  : item.fnptCustomerFreight
                              }
                              onChange={(e) =>
                                changeData(
                                  item.label,
                                  "fnptCustomerFreight",
                                  e.target.value
                                )
                              }
                              style={{
                                width: 120,
                                marginRight: 30,
                                marginLeft: 15,
                              }}
                            />
                          </Radio.Group>
                        </div>
                      </div>
                    </Radio.Group>
                  </div>
                );
              }
            })}
          </div> */}
        </div>
      </div>
      <div className="logistics-content">
        <div className="logistics-content_title">
          <span
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              marginTop: 1,
            }}
          >
            普通快递
          </span>
        </div>
        <div className="logistics-content_main">
          {/* <div className="logistics-content_platform">
            2、我已有配送员/合作配送平台,或者不需要典栈平台提供同城配送服务
          </div> */}
          <Radio.Group value={logist.isExpress}>
            <div>
              <Radio
                style={{ fontSize: 16, marginRight: 30 }}
                value={1}
                onClick={() =>
                  changeData("普通", "isExpress", logist.isExpress == 1 ? 0 : 1)
                }
              >
                普通快递配送
                <span className="tip">
                  （ 我有合作的申通、韵达、顺丰等等快递
                  ，需提供客户包邮服务，承诺24小时内发货）
                </span>
              </Radio>
              <div className="logistics-child">
                <div
                  style={{ fontSize: 16, fontWeight: "bold", marginLeft: 13 }}
                >
                  运费设置
                </div>
                <Radio.Group
                  value={logist.freeDelivery}
                  style={{ marginLeft: 25 }}
                  disabled={logist.isExpress != 1}
                >
                  <Radio
                    style={{ fontSize: 16 }}
                    value={0}
                    onClick={() => changeData("普通", "freeDelivery", 0)}
                  >
                    自定义运费
                    <Input
                      disabled={
                        logist.isExpress != 1 ||
                        logist.freeDelivery != 0 ||
                        logist.freeDelivery == 1
                      }
                      suffix="元"
                      type="number"
                      value={
                        logist.freeDelivery === 1
                          ? 0
                          : logist.selfCustomerFreight
                      }
                      onChange={(e) =>
                        changeData(
                          "普通",
                          "selfCustomerFreight",
                          e.target.value
                        )
                      }
                      style={{ width: 120, marginRight: 30, marginLeft: 15 }}
                    />
                  </Radio>
                  <Radio
                    style={{ fontSize: 16 }}
                    value={1}
                    onClick={() => changeData("普通", "freeDelivery", 1)}
                  >
                    包邮
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </Radio.Group>
          {logist.freeDelivery === 0 && (
            <div className="specialList">
              <div className="specialList-title">
                <div>追加快递费省份设置</div>
                <div
                  style={{
                    color: "#999",
                  }}
                >
                  默认全部省份使用自定义快递费金额某省份追加则实际运费等于自定义快递费+该省份追加快递费
                </div>
              </div>
              <Button
                type="primary"
                className="specialList-add"
                onClick={() => changeSpecialData("add")}
              >
                添加
              </Button>
              {specialList.map((item, index) => (
                <div className="specialList-item" key={`${index}`}>
                  <Select
                    style={{ width: 170, marginRight: 20, fontSize: 14 }}
                    value={item.province}
                    onChange={(v) => {
                      if (
                        specialList.find((special) => special.province === v)
                      ) {
                        Message.info("不能选择相同的省份");
                      } else {
                        changeSpecialData("change", index, {
                          ...item,
                          province: v,
                        });
                      }
                    }}
                  >
                    {provinceList.map((i) => {
                      return (
                        <Select.Option value={i.name} key={i.id}>
                          {i.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  <Input
                    suffix="元"
                    type="number"
                    value={item.freight}
                    style={{ width: 120, marginRight: 30, marginLeft: 15 }}
                    onInput={({ target: { value } }) => {
                      changeSpecialData("change", index, {
                        ...item,
                        freight: value ? +value : "",
                      });
                    }}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      changeSpecialData("delete", index);
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="logistics-btn" onClick={() => send()}>
        <span className="blue">确定</span>
      </div>
      <UploadBusinessModal
        visible={showBusinessModal}
        OSSData={OSSData}
        cancelText={"取消"}
        okText={"确认"}
        store={storeInfo}
        onOk={submitBusinessSuccess}
        onCancel={() => setShowBusinessModal(false)}
      />
    </div>
    <div className="new-logistics-wrapper">
      <div className="logistics-title" style={{ marginBottom: 17 }}>交易记录</div>
      <div className="flow-btns">
        {flowTypeOptions.map(item => (
          <span
            key={item.value}
            className={`flow-btn ${item.value === recordState.flow_type ? 'active' : ''}`}
            onClick={() => {
              setRecordState({
                ...recordState,
                flow_type: item.value
              })
              setPageCurrent(1)
            }}
          >{item.label}</span>
        ))}
      </div>
      <Table
        rowKey="order_no"
        columns={columns}
        dataSource={dataSource}
        bordered
        pagination={{
          current: pageCurrent,
          total: dataSource.length,
          defaultPageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
          position: ["bottomCenter"],
          showTotal: (total) => `共${total}条`,
          onChange: (page) => {
            // console.log('pagination:', page)
            setPageCurrent(page)
          },
        }}
      />
    </div>
    <Modal
      width={420}
      open={visible}
      className="recharge-modal"
      centered
      footer={null}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
    >
      <h2 className="recharge-modal-title">充值</h2>
      <div className="flex align-start">
        <div className="recharge-modal-label">充值金额</div>
        <div className="flex-1">
          <div className="recharge-modal-input">
            <Input
              prefix="¥"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              onBlur={() => {
                setIsError(+amount < 50)
              }}
              style={{
                height: 38
              }}
            />
            {isError && <span className="recharge-modal-error">充值金额不低于50</span>}
          </div>
          <div className="recharge-modal-tags">
            {amountList.map(t => (<div
              key={t}
              className={`recharge-modal-tag ${t == amount ? 'active' : ''}`}
              onClick={() => setAmount(t + '')}
            >¥{t}</div>))}
          </div>
        </div>
      </div>
      <div className="recharge-modal-footer">
        <Button className="recharge-modal-btn" onClick={onAmountCancel}>取消</Button>
        <Button className="recharge-modal-btn" type="primary" onClick={onAmountConfirm}>充值</Button>
      </div>
    </Modal>
    </>
  );
};
export default Logistics;
