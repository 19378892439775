import React, { useState, useEffect } from "react";
import { Table, Button, Input, Space, DatePicker, InputNumber } from "antd";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import { Get_StoreCommissions_Query } from "@/graphql/products";
import client from "@/graphql/apollo-config";
import ExportJsonExcel from "js-export-excel";
const { RangePicker } = DatePicker;

const statusTitles = [
  { text: "扫码付款", value: "1" },
  { text: "商城订单", value: "0" },
  { text: "首单奖励", value: "2" },
];

const CommissionDetails = () => {
  const [tabData, setTabData] = useState([]);
  const [current, setCurrent] = useState("");
  const [total, setTotal] = useState("");
  const [sendData, setData] = useState({
    order_no: null,
    username: null,
    start_time: null,
    end_time: null,
    amount_min: null,
    amount_max: null,
    pay_type: null,
    type: null,
    is_first: null,
    limit: 10,
    page: 1,
  });

  useEffect(() => {
    getInfo();
  }, [sendData]);

  const getInfo = async () => {
    try {
      const { data } = await client.query({
        query: Get_StoreCommissions_Query,
        variables: sendData,
        fetchPolicy: "no-cache",
      });
      const newData = hundleData(data.storeCommissions.data);
      setTabData([...newData]);
      setCurrent(data.storeCommissions.current_page);
      setTotal(data.storeCommissions.total);
    } catch (error) {}
  };
  const hundleData = (data) => {
    data.map((i) => {
      i.key = i.id + " ";
      if (i.pay_type == 0) {
        //线上
        i.tempOrder = i.order;
      } else {
        i.tempOrder = i.order_qr;
      }
      if (commission_type(i.type) == 0) {
        //首单
        i.total = "--";
        i.order_no = "--";
        i.c_pay_type = "--";
        i.c_type = "首单奖励";
        i.name = (i.tempOrder && i.tempOrder.store.name) || "--";
      } else {
        i.order_no = (i.tempOrder && i.tempOrder.order_no) || "--";
        i.c_pay_type = (i.tempOrder && pay_str(i.tempOrder.pay_type)) || "--";
        i.c_type = i.pay_type == 0 ? "商城订单" : "扫码付款";
        i.name = i.tempOrder?.user?.username || "--";
      }
      i.time = (i.tempOrder && getDate(i.tempOrder.pay_at)) || "--";
      i.total = (i.tempOrder && i.tempOrder.total) || "--";
      return i;
    });
    return data;
  };
  //支付方式
  const pay_str = (type) => {
    //1支付宝支付 2微信支付 3微信小程序支付 4微信内支付 5通联微信内支付 6通联微信小程序支付 7通联支付宝支付 10跳过支付
    if (
      type == 0 ||
      type == 2 ||
      type == 3 ||
      type == 4 ||
      type == 5 ||
      type == 6 ||
      type == 8
    ) {
      return "微信支付";
    } else if (type == 1 || type == 7) {
      return "支付宝支付";
    }
    return "--";
  };

  const commission_type = (type) => {
    //0是首单 1是其他
    //1用户 2平台 3代理 4商家 5商家首单 6用户首单 7营业额
    if (type == 5 || type == 6) {
      return 0;
    } else {
      return 1;
    }
  };

  // 处理时间
  const getDate = (date) => {
    if (date === 0) return "-";
    return moment(date * 1000).format("YYYY-MM-DD HH:mm:ss");
  };

  const getColumnSearchProps = (dataIndex) => {
    let searchInput;
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`搜索`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, height: 27 }}
              >
                搜索
              </Button>
              <Button
                onClick={() =>
                  handleReset(selectedKeys, clearFilters, dataIndex)
                }
                size="small"
                style={{ width: 90, height: 27 }}
              >
                重置
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#FFD600" : undefined }} />
      ),
    };
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    if (selectedKeys[0] !== undefined) {
      if (dataIndex == "order_no") {
        setData({
          ...sendData,
          order_no: selectedKeys[0],
          page: 1,
        });
      } else if (dataIndex == "name") {
        setData({
          ...sendData,
          username: selectedKeys[0],
          page: 1,
        });
      }
    }
  };
  const handleReset = (selectedKeys, clearFilters, dataIndex) => {
    clearFilters();
    if (selectedKeys[0] !== undefined) {
      if (dataIndex == "order_no") {
        setData({
          ...sendData,
          order_no: null,
          page: 1,
        });
      } else if (dataIndex == "name") {
        setData({
          ...sendData,
          username: null,
          page: 1,
        });
      }
    }
  };

  const getColumnDataProps = (col) => {
    const dateFormat = "YYYY/MM/DD";
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <div>
            <RangePicker
              showTime={{ format: "HH:mm:ss" }}
              value={selectedKeys}
              format={dateFormat}
              onChange={(value, dateString) =>
                onChangeData(
                  value,
                  dateString,
                  confirm,
                  clearFilters,
                  setSelectedKeys,
                  col
                )
              }
            />
            <Space style={{ marginLeft: 20 }}>
              <Button
                onClick={() => handleResetData(clearFilters)}
                size="small"
                style={{ width: 60, height: 25 }}
              >
                重置
              </Button>
            </Space>
          </div>
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined style={{ color: filtered ? "#FFD600" : undefined }} />
      ),
    };
  };

  const handleResetData = (clearFilters) => {
    clearFilters();
    setData({
      ...sendData,
      start_time: null,
      end_time: null,
    });
  };

  const onChangeData = (
    value,
    dateString,
    confirm,
    clearFilters,
    setSelectedKeys
  ) => {
    if (value !== null) {
      confirm();
      setSelectedKeys([value[0], value[1]]);
      setData({
        ...sendData,
        start_time: value[0].unix(),
        end_time: value[1].unix(),
        page: 1,
      });
    } else {
      clearFilters();
      setSelectedKeys([null, null]);
      setData({
        ...sendData,
        start_time: null,
        end_time: null,
        page: 1,
      });
    }
  };

  const onChangePagination = (page) => {
    setData({
      ...sendData,
      page: page,
    });
  };

  const getColumnNumberProps = (col) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <InputNumber
                value={selectedKeys[0]}
                onChange={(value) => {
                  selectedKeys[0] = value;
                  setSelectedKeys(selectedKeys);
                }}
                style={{ width: 88, marginBottom: 8, display: "block" }}
              />
              <span>-</span>
              <InputNumber
                value={selectedKeys[1]}
                onChange={(value) => {
                  selectedKeys[1] = value;
                  setSelectedKeys(selectedKeys);
                }}
                style={{ width: 88, marginBottom: 8, display: "block" }}
              />
            </div>

            <Space>
              <Button
                type="primary"
                onClick={() => numberSearch(selectedKeys, confirm, col)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, height: 27 }}
              >
                搜索
              </Button>
              <Button
                onClick={() => numberReset(clearFilters, col)}
                size="small"
                style={{ width: 90, height: 27 }}
              >
                重置
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#FFD600" : undefined }} />
      ),
    };
  };
  const numberSearch = (selectedKeys, confirm, col) => {
    confirm();
    if (col == "amount") {
      setData({
        ...sendData,
        amount_min: selectedKeys[0],
        amount_max: selectedKeys[1],
        page: 1,
      });
    }
  };

  const numberReset = (clearFilters, col) => {
    clearFilters();
    if (col == "amount") {
      setData({
        ...sendData,
        amount_min: null,
        amount_max: null,
        page: 1,
      });
    }
  };

  const getColumnStatusProps = (dataIndex) => {
    let filters;
    switch (dataIndex) {
      case "c_type":
        filters = statusTitles;
        break;
      case "type":
        filters = [
          {
            text: "公域流量",
            value: "8",
          },
          {
            text: "私域流量",
            value: "4",
          },
          {
            text: "分享奖",
            value: "1",
          },
        ];
        break;
      default:
        break;
    }
    return {
      filters: filters,
      filterMultiple: false,
    };
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (extra.action == "paginate") return;
    if (filters.c_type !== null || filters.type !== null) {
      setData({
        ...sendData,
        is_first: filters.c_type && filters.c_type[0] == 2 ? 1 : null,
        pay_type: filters.c_type
          ? filters.c_type[0] == 2
            ? null
            : filters.c_type[0]
          : null,
        type: filters.type && filters.type[0] ? filters.type[0] : null,
        page: 1,
      });
    } else {
      setData({
        ...sendData,
        pay_type: null,
        is_first: null,
        type: null,
        page: 1,
      });
    }
  };

  const columns = [
    {
      title: "订单编号",
      dataIndex: "order_no",
      key: "order_no",
      ...getColumnSearchProps("order_no"),
    },
    {
      title: "付款时间",
      dataIndex: "time",
      key: "time",
      ...getColumnDataProps("time"),
    },
    {
      title: "订单金额",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "赏金计划类型",
      dataIndex: "type_name",
      key: "type_name",
    },
    {
      title: "赏金计划",
      dataIndex: "amount",
      key: "amount",
      ...getColumnNumberProps("amount"),
    },

    {
      title: "类型",
      dataIndex: "c_type",
      key: "c_type",
      ...getColumnStatusProps("c_type"),
    },
    {
      title: "支付方式",
      dataIndex: "c_pay_type",
      key: "c_pay_type",
    },
    {
      title: "用户昵称",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
  ];

  // const [getAllData] = useLazyQuery(Get_StoreCommissions_Query, {
  //   variables: {
  //     ...sendData,
  //     page: 1,
  //     limit: total,
  //   },
  //   fetchPolicy: "no-cache",
  //   onCompleted: (data) => {
  //     if (data) {
  //     //   const { data } = await client.query({
  //     //   query: Get_StoreCommissions_Query,
  //     //   variables: sendData,
  //     //   fetchPolicy: "no-cache",
  //     // });
  //     const newData = hundleData(data.storeCommissions.data);
  //     exportExcel(newData);
  //     }
  //   },
  // });
  const getAllData = async () => {
    console.log(sendData, "sendDatasendData");
    try {
      const { data } = await client.query({
        query: Get_StoreCommissions_Query,
        variables: {
          ...sendData,
          page: 1,
          limit: total,
        },
        fetchPolicy: "no-cache",
      });
      const newData = hundleData(data.storeCommissions.data);
      exportExcel(newData);
    } catch (error) {
      console.log(error);
    }
  };
  const exportExcel = (data) => {
    let option = {};
    console.log(data, "--sdfs");
    option.fileName = new Date().getTime();

    option.datas = [
      {
        sheetData: data,
        sheetName: "sheet",
        sheetHeader: columns.map((i) => i.title),
        sheetFilter: columns.map((i) => i.key),
        columnWidths: Array(15).fill("10"),
      },
    ];

    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  return (
    <div className="order">
      <div style={{ textAlign: "right", marginBottom: "10px" }}>
        <Button type="primary" onClick={getAllData}>
          <span style={{ color: "white" }}>导出Excel</span>
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={tabData}
        pagination={{
          position: ["bottomCenter"],
          defaultCurrent: 1,
          defaultPageSize: 10,
          current: current,
          total: total,
          onChange: onChangePagination,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={(pagination, filters, sorter, extra) =>
          onChange(pagination, filters, sorter, extra)
        }
      />
    </div>
  );
};

export default CommissionDetails;
