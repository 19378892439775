import React, { useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, DatePicker, Input, message, Modal, Radio, Select, Steps } from 'antd'
import BusinessImg from '@/components/FileUpload'
import client from '@/graphql/apollo-config'
import { IMAGE2TEXT, REGION, STORE_AUDIT_INFO } from '@/graphql/store'
import { handleImg, isCardNo, isEmpty } from '@/utils'
import { isPhone } from '@/utils/regexp'
import { addSonStore } from '@/apis/shop'
import moment from 'moment'
import './add.less'

const axios = require('axios').default;
const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const SubStopAdd = (props) => {
    const [step, setStep] = useState(0)
    const [OSSData, setOSSData] = useState({})
    const [longIdCard, setLongIdCard] = useState(false)
    const [form, setForm] = useState({
        mobile: '',                 // 店铺账号
        short_name: '',             // 店铺简称
        store_category_id: '',      // 商家类目
        product_type: '',           // 店铺类型
        average_spend: '',          // 人均消费
        lowest_delivery_price: '',  // 起送价
        province: '',               // 省
        city: '',                   // 市
        area: '',                   // 区县
        address: '',                // 店铺详细地址
        hot_line: '',               // 客服手机号
        cover: [],                  // 店铺头像
        notice: '',                 // 公告
        id_card_front: [],          // 身份证正面照
        id_card_back: [],           // 身份证背面照
        id_card_no: '',             // 身份证号
        id_card_valid_start: '',    // 身份证有效期开始日期
        id_card_valid_end: '',      // 身份证有效期结束日期
        contact_name: '',           // 联系人
        contact_mobile: '',         // 联系电话
    })
    const [categroyList, setCategroyList] = useState([])
    const [region, setRegion] = useState([])

    const [provinceList, cityList, areaList] = useMemo(() => {
        // console.log('region', form.province, form.city)
        const citys = form.province ? (region.find(t => t.name === form.province)?.data || []) : []
        const areas = form.city ? (citys.find(t => t.name === form.city)?.data || []) : []

        return [
            region.map(t => ({ id: t.id, name: t.name })),
            citys.map(t => ({ id: t.id, name: t.name })),
            areas.map(t => ({ id: t.id, name: t.name })),
        ]
    }, [region, form.province, form.city])

    const rule1 = [
        {
            rule: !form.mobile || !form.mobile.trim(),
            title: '请填写店铺账号',
        },
        {
            rule: !isPhone(form.mobile),
            title: '店铺账号格式不正确，请输入正确的手机号',
        },
        {
            rule: !form.short_name || !form.short_name.trim(),
            title: '请填写店铺简称',
        },
        {
            rule: !form.store_category_id,
            title: '请选择商家类目',
        },
        {
            rule: form.product_type === '',
            title: '请选择店铺类型',
        },
        {
            rule: !form.average_spend || !form.average_spend.trim(),
            title: '请填写人均消费',
        },
        {
            rule: !form.lowest_delivery_price || !form.lowest_delivery_price.trim(),
            title: '请填写起送价',
        },
        {
            rule: !form.province,
            title: '请选择省份',
        },
        {
            rule: !form.city,
            title: '请选择市',
        },
        {
            rule: !form.area,
            title: '请选择区/县',
        },
        {
            rule: !form.address,
            title: '请填写详细地址',
        },
        {
            rule: form.address && form.address.length < 5,
            title: '请填写5个字以上的详细地址',
        },
        {
            rule: !form.hot_line || !form.hot_line.trim(),
            title: '请填写客服手机号',
        },
        {
            rule: !isPhone(form.hot_line),
            title: '客服手机号码格式不正确',
        },
        {
            rule: form.cover.length === 0,
            title: '请上传店铺头像',
        },
    ]
    const rule2 = [
        {
            rule: form.id_card_front.length === 0,
            title: '请上传身份证正面照',
        },
        {
            rule: form.id_card_back.length === 0,
            title: '请上传身份证背面照',
        },
        {
            rule: !form.id_card_no || !form.id_card_no.trim(),
            title: '请填写法人身份证号',
        },
        {
            rule: !isCardNo(form.id_card_no),
            title: '请填写正确的法人身份证号',
        },
        {
            rule: !form.id_card_valid_start,
            title: '请选择身份证有效期开始时间',
        },
        {
            rule: !form.id_card_valid_end,
            title: '请选择身份证有效期结束时间',
        },
        {
            rule: !form.contact_name || !form.contact_name.trim(),
            title: '请填写联系人',
        },
        {
            rule: !form.contact_mobile || !form.contact_mobile.trim(),
            title: '请填写联系电话',
        },
        {
            rule: !isPhone(form.contact_mobile),
            title: '联系电话格式不正确',
        },
    ]

    useEffect(() => {
        getStoreInfo()
        getRegionList()
    }, [])

    const getStoreInfo = async () => {
        try {
            const { data } = await client.query({
                query: STORE_AUDIT_INFO,
                fetchPolicy: 'no-cache',
            })
            if (data) {
                setOSSData({
                    ...data.oss,
                })
                setCategroyList(data.storeCategory)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getRegionList = async () => {
        try {
            const { data } = await client.query({
                query: REGION,
                fetchPolicy: 'no-cache',
            })
            setRegion(data.region)
        } catch (e) {
            console.log(e)
        }
    }

    // 跳转地图
    const goLocation = () => {
        if (form.city !== '' && form.area !== '' && form.address) {
            window.open(
                `https://uri.amap.com/search?keyword=${form.area + form.address}&city=${form.city}&view=map&coordinate=gaode&callnative=0`
            );
        } else {
            message.info('请完善地址信息');
        }
    };

    const changeData = (key, value) => {
        setForm({
            ...form,
            [key]: value
        })
    }
    const changeDatas = (datas) => {
        setForm({
            ...form,
            ...datas
        })
    }

    const showExample = (img) => {
        Modal.info({
            icon: null,
            content: (
                <img
                style={{ textAlign: "center", width: "530px" }}
                src={require(`@/assets/images/example/${img}.jpg`)}
                alt=""
                />
            ),
            width: 600,
        });
    };

    const image2Text = async (name, arr) => {
        try {
            if (arr && arr.length > 0) {
                const { data } = await client.query({
                    query: IMAGE2TEXT,
                    variables: {
                        type: 0,
                        url: handleImg(arr),
                        side: name.indexOf("_front") != -1
                            ? "front"
                            : name.indexOf("_back") != -1
                            ? "back"
                            : "",
                    },
                    fetchPolicy: "no-cache",
                });
                console.log("图片识别", arr);
                console.log("data11:", data);
        
                if (data && data.image2Text) {
                    switch (name) {
                    case "id_card_front":
                        const id_card_no = data.image2Text.id_card_no;
                        setForm({
                            ...form,
                            id_card_front: arr,
                            id_card_no: !isEmpty(id_card_no) && id_card_no != "无" ? id_card_no : "",
                        });
                        break;
                    case "id_card_back":
                        const idStart = data.image2Text.id_card_valid_start;
                        const idEnd = data.image2Text.id_card_valid_end;
                        setForm({
                            ...form,
                            id_card_back: arr,
                            id_card_valid_start: !isEmpty(idStart) && idStart != "无" ? moment(idStart, "YYYYMMDD").format("YYYY-MM-DD") : "",
                            id_card_valid_end:
                            !isEmpty(idEnd) && idEnd != "无"
                                ? idEnd == "长期"
                                    ? "2099-12-30"
                                    : moment(idEnd, "YYYYMMDD").format("YYYY-MM-DD")
                                : "",
                        });
                        setLongIdCard(false)
                        break;
                    default:
                        break;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const changeTime = (value, dateString, start, end) => {
        console.log(value, dateString)
        setForm({
        ...form,
        [start]: dateString[0],
        [end]: dateString[1],
        });
    };
    const changeBox = (e, start, end) => {
        setLongIdCard(e.target.checked)
        if (e.target.checked) {
            const idCardStart = form[start] || "2099-12-30";
            setForm({
                ...form,
                [start]: idCardStart,
                [end]: "2099-12-30",
            })
        } else {
            setForm({
                ...form,
                [start]: '',
                [end]: '',
            })
        }
    }

    const verification = (rule) => {
        for (let item of rule) {
            if (item.rule) {
                message.info(item.title);
                return false;
            }
        }
        return true;
    };

    const nextStep = () => {
        if (verification(rule1)) {
            setStep(1)
        }
    }

    const getLocation = async () => {
        try {
            const { data } = await axios.get(
                `https://restapi.amap.com/v3/geocode/geo?key=75060fae7d3d453c790b609a312a7a08&address=${
                    form.city + form.area + form.address
                }&city=${form.city}`
            );
            const d = data.geocodes[0].location.split(',')
            return { lng: d[0], lat: d[1] }
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        }
    }

    const submit = async () => {
        if (!verification(rule2)) return;
        try {
            const { lng, lat } = await getLocation()
            const { data } = await addSonStore({
                ...form,
                lng,
                lat,
                cover: handleImg(form.cover),
                id_card_front: handleImg(form.id_card_front),
                id_card_back: handleImg(form.id_card_back),
            })
            message.success('添加成功')
            props.history.goBack();
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <div className="subStopAdd">
            <div className="steps-card">
                <Steps
                    size="small"
                    labelPlacement="vertical"
                    current={step}
                    style={{ width: 280, marginLeft: -10 }}
                >
                    <Step title="填写子店铺信息" />
                    <Step title="填写个人信息" />
                </Steps>
            </div>
            {step === 0 && <div className="form-card">
                <div className="form-item">
                    <div className="form-item__label">店铺账号<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <Input
                            className="form-item__input"
                            type="tel"
                            value={form.mobile}
                            maxLength={11}
                            onChange={(e) => changeData('mobile', e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">店铺简称<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <Input
                            className="form-item__input"
                            value={form.short_name}
                            onChange={(e) => changeData('short_name', e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">商家类目<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <Select
                            className="form-item__input"
                            placeholder="请选择"
                            value={form.store_category_id}
                            onChange={e => changeData('store_category_id', e)}
                        >
                            {categroyList.map((item) => (
                                <Option value={item.id} key={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">店铺类型<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <Radio.Group
                            value={form.product_type}
                            onChange={(e) => changeData('product_type', e.target.value)}
                        >
                            <Radio className="form-item__radio" value={0}>实物商品<span className="sub">(需配送发货)</span></Radio>
                            <Radio className="form-item__radio" value={1}>电子卡券<span className="sub">(系统自动生成券码，线上核销)</span></Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">人均消费<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <Input
                            type="number"
                            className="form-item__input"
                            value={form.average_spend}
                            onChange={(e) => changeData('average_spend', e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">起送价<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <Input
                            type="number"
                            className="form-item__input"
                            value={form.lowest_delivery_price}
                            onChange={(e) => changeData('lowest_delivery_price', e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">店铺地址<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <div className="form-item__input flex-space" style={{ marginBottom: 30 }}>
                            <Select
                                placeholder="请选择"
                                value={form.province}
                                style={{ width: 169 }}
                                onChange={e => changeDatas({
                                    province: e,
                                    city: '',
                                    area: '',
                                    address: '',
                                })}
                            >
                                {provinceList.map((item) => (
                                    <Option value={item.name} key={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                placeholder="请选择"
                                value={form.city}
                                style={{ width: 169 }}
                                onChange={e => changeDatas({
                                    city: e,
                                    area: '',
                                    address: '',
                                })}
                            >
                                {cityList.map((item) => (
                                    <Option value={item.name} key={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                placeholder="请选择"
                                value={form.area}
                                style={{ width: 169 }}
                                onChange={e => changeDatas({
                                    area: e,
                                    address: '',
                                })}
                            >
                                {areaList.map((item) => (
                                    <Option value={item.name} key={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <TextArea
                            className="form-item__input"
                            rows={3}
                            value={form.address}
                            onChange={e => changeData('address', e.target.value)}
                        />
                        <div className="form-item__input" style={{ textAlign: 'right', marginTop: 6 }}>
                            <span className="location" onClick={goLocation}>查看定位</span>
                        </div>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">客服手机号<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <Input
                            type="tel"
                            className="form-item__input"
                            value={form.hot_line}
                            maxLength={11}
                            onChange={(e) => changeData('hot_line', e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">店铺头像<span className="required">*</span></div>
                    <div className="form-item__inner flex">
                        <BusinessImg
                            OSSData={OSSData}
                            fileList={form.cover}
                            disabled={false}
                            listChange={(arr) => changeData('cover', arr)}
                        />
                        <div className="img-tip">
                            • 建议上传140*140px或比例1：1;
                            <br />• JPG、PNG格式，图片小于1M;
                        </div>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">店铺公告</div>
                    <div className="form-item__inner">
                        <Input
                            className="form-item__input"
                            value={form.notice}
                            onChange={(e) => changeData('notice', e.target.value)}
                        />
                    </div>
                </div>
                <Button type="primary" size="large" style={{ width: 318, marginTop: 18 }} onClick={nextStep}>下一步</Button>
            </div>}
            {step === 1 && <div className="form-card">
                <div className="form-item">
                    <div className="form-item__label">身份证正面照<span className="required">*</span></div>
                    <div className="form-item__inner flex">
                        <BusinessImg
                            OSSData={OSSData}
                            fileList={form.id_card_front}
                            disabled={false}
                            listChange={(arr) => {
                                changeData("id_card_front", arr);
                            }}
                            onSuccess={(arr) => {
                                image2Text("id_card_front", arr);
                            }}
                        />
                        <div className="img-tip">
                            • 上传JPG、PNG格式，图片小于1M；
                            <br />
                            • 证件头像清晰，文字数字清晰可辨；
                            <br />
                            • 个体户或企业用户上传法人身份证；
                            <br />
                            <p
                            style={{ color: "#048ad3", cursor: "pointer" }}
                            onClick={() => showExample(8)}
                            >
                            示例
                            </p>
                        </div>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">身份证正面照<span className="required">*</span></div>
                    <div className="form-item__inner flex">
                        <BusinessImg
                            OSSData={OSSData}
                            fileList={form.id_card_back}
                            disabled={false}
                            listChange={(arr) => {
                                changeData("id_card_back", arr);
                            }}
                            onSuccess={(arr) => {
                                image2Text("id_card_back", arr);
                            }}
                        />
                        <div className="img-tip">
                            • 上传JPG、PNG格式，图片小于1M；
                            <br />
                            • 身份证未过期；
                            <br />
                            • 个体户或企业用户上传法人身份证；
                            <br />
                            <p
                            style={{ color: "#048ad3", cursor: "pointer" }}
                            onClick={() => showExample(7)}
                            >
                            示例
                            </p>
                        </div>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">身份证号<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <Input
                            className="form-item__input"
                            value={form.id_card_no}
                            onChange={(e) => changeData('id_card_no', e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">身份证有效期<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <div className="form-item__input flex-space align-center">
                            <RangePicker
                                value={[
                                    form.id_card_valid_start
                                    ? moment(form.id_card_valid_start)
                                    : '',
                                    form.id_card_valid_end
                                    ? moment(form.id_card_valid_end)
                                    : '',
                                ]}
                                disabled={longIdCard}
                                onChange={(value, dateString) =>
                                    changeTime(
                                    value,
                                    dateString,
                                    "id_card_valid_start",
                                    "id_card_valid_end"
                                    )
                                }
                                style={{ flex: 1 }}
                            />
                            <div style={{ marginLeft: 50, display: "inline" }}>
                                <Checkbox
                                    checked={longIdCard}
                                    onChange={(e) =>
                                        changeBox(e, 'id_card_valid_start', 'id_card_valid_end')
                                    }
                                >
                                    长期
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">联系人<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <Input
                            className="form-item__input"
                            value={form.contact_name}
                            onChange={(e) => changeData('contact_name', e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-item__label">联系电话<span className="required">*</span></div>
                    <div className="form-item__inner">
                        <Input
                            type="tel"
                            className="form-item__input"
                            value={form.contact_mobile}
                            maxLength={11}
                            onChange={(e) => changeData('contact_mobile', e.target.value)}
                        />
                    </div>
                </div>
                <div style={{ marginTop: 48 }}>
                    <Button type="text" size="large" style={{ width: 112, marginRight: 32 }} onClick={() => setStep(0)}>上一步</Button>
                    <Button type="primary" size="large" style={{ width: 318 }} onClick={submit}>提交</Button>
                </div>
            </div>}
        </div>
    )
}

export default SubStopAdd