import React, { useEffect } from "react";
import { Button, Modal, List, Checkbox, Input } from "antd";
import { useQuery } from "react-apollo";
import { FIND_USER } from "@/graphql/store";

import { DeleteOutlined } from "@ant-design/icons";

import "./index.less";

function UserSelector(props) {
  const { value, onChange, mode } = props;
  const [visible, setVisible] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [selData, setSelData] = React.useState([]);
  const [haveMore, setHaveMore] = React.useState(false);
  const [dataSoruce, setDatasoruce] = React.useState([]);
  const [name, setName] = React.useState("");

  const dataRes = useQuery(FIND_USER, {
    fetchPolicy: "no-cache",
    variables: {
      page,
      limit: 15,
      search: name,
    },
  });

  useEffect(() => {
    try {
      if (dataRes.data) {
        const { data, has_more_pages } = dataRes.data.findUser;

        const needRefresh =
          !dataSoruce.length ||
          dataSoruce.slice(-1)[0].id !== data.slice(-1)[0].id;
        if (!needRefresh) return;

        const newData = page === 1 ? data : [...dataSoruce, ...data];
        setDatasoruce(newData);
        setHaveMore(has_more_pages);
      }
    } catch (error) {
      console.log(error);
    }
  }, [dataRes.data, dataSoruce, page]);

  useEffect(() => {
    if (value) {
      setSelData(value);
    }
  }, [value]);

  return (
    <div className="user-selector">
      <Button type="primary" onClick={() => setVisible(true)}>
        选择
      </Button>
      <Modal
        title="选择用户"
        visible={visible}
        onOk={() => {
          onChange(selData);
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
        okText="确认"
        cancelText="取消"
      >
        <Input.Search
          placeholder="输入用户昵称或者ID"
          enterButton="搜索"
          size="large"
          allowClear
          onSearch={(v) => {
            setPage(1);
            setName(v);
          }}
        />
        <List
          className="list-container"
          itemLayout="vertical"
          loadMore={
            haveMore && (
              <Button
                className="list-container-loadmore"
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                加载更多
              </Button>
            )
          }
        >
          {dataSoruce.map((item, index) => {
            return (
              <List.Item key={`${index}`}>
                <Checkbox
                  checked={selData.find((data) => data.id === item.id)}
                  onClick={() => {
                    if (mode === "multiple") {
                      const selIndex = selData.findIndex(
                        (data) => data.id === item.id
                      );
                      if (selIndex > -1) {
                        selData.splice(selIndex, 1);
                        setSelData([...selData]);
                      } else {
                        setSelData([
                          ...selData,
                          {
                            id: item.id,
                            username: item.username,
                          },
                        ]);
                      }
                    } else {
                      setSelData([
                        {
                          id: item.id,
                          username: item.username,
                        },
                      ]);
                    }
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      marginRight: 10,
                    }}
                  >
                    用户ID：{item.id}
                  </span>

                  <span>用户昵称：{item.username}</span>
                </Checkbox>
              </List.Item>
            );
          })}
        </List>
      </Modal>
      {value && value.length ? (
        <List
          style={{
            maxWidth: 300,
          }}
        >
          {value.map((item, index) => (
            <List.Item
              key={item.id}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: 10,
                }}
              >
                用户ID：{item.id}
              </span>

              <span>用户昵称：{item.username}</span>
              <Button
                type="link"
                onClick={() => {
                  value.splice(index, 1);
                  onChange(value);
                }}
              >
                <DeleteOutlined />
              </Button>
            </List.Item>
          ))}
        </List>
      ) : null}
    </div>
  );
}

export default UserSelector;
