import React, { useState, useEffect } from "react";
import "./index.less";
import { Table, Switch, Message } from "antd";
import {
  Delete_Lei_Mu_Mutation,
  Get_Store_Categorys,
  Xiu_Gai_Lei_Mu_Mutation,
} from "../../graphql/products";
import client from "../../graphql/apollo-config";
import moment from "moment";

var pageInfo = {
  limit: 15,
  page: 1,
};

const HeaderCell = [
  {
    title: "类目",
    dataIndex: "id",
  },
  {
    title: "类目名称",
    dataIndex: "name",
  },
  {
    title: "是否显示",
    dataIndex: "status",
  },
  {
    title: "展示权重",
    dataIndex: "sort_order",
    sorter: (a, b) => a.sort_order - b.sort_order,
  },
  {
    title: "创建时间",
    dataIndex: "created_at",
  },
  {
    title: "修改时间",
    dataIndex: "updated_at",
  },
  {
    title: "操作",
    dataIndex: "optional",
  },
];

const Category = (props) => {
  const { history } = props;
  const [datas, setDatas] = useState([]);
  const [pagenum, setPagenum] = useState(1);

  useEffect(() => {
    requestData();
  }, []);

  const requestData = async () => {
    try {
      const { data } = await client.query({
        query: Get_Store_Categorys,
        variables: pageInfo,
        fetchPolicy: "no-cache",
      });
      data.ProductCategories.data.forEach((item, index) => {
        item.key = index;
        item.created_at = handleDate(item.created_at);
        item.updated_at = handleDate(item.updated_at);
      });
      setPagenum(data.ProductCategories.last_page);
      setDatas(data.ProductCategories.data);
    } catch (e) {}
  };
  const handleDate = (value) => {
    return moment(value * 1000).format("YYYY-MM-DD hh:mm:ss");
  };

  //编辑类目
  const edit = (record) => {
    history.push(`/category/newclass?id=${record.id}`);
  };

  //删除类目
  const deleteCategory = async (record, index) => {
    try {
      const { data } = await client.mutate({
        mutation: Delete_Lei_Mu_Mutation,
        variables: { product_category_id: record.id },
        fetchPolicy: "no-cache",
      });
      if (data) {
        let tempDatas = [...datas];
        tempDatas.slice(index, 1);
        setDatas([...tempDatas]);
        Message.info("已删除");
        setInterval(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (e) {}
  };

  const onSheldRender = (text, record, index) => {
    return (
      <div>
        <Switch
          checked={record.status != 0}
          onClick={onSheldChange.bind(this, index, record)}
        />
      </div>
    );
  };

  const optionalRender = (text, record, index) => {
    return (
      <div className="optional">
        <span className="edit" onClick={() => edit(record)}>
          编辑
        </span>
        <span className="preview" onClick={() => deleteCategory(record, index)}>
          删除
        </span>
      </div>
    );
  };

  const weightRender = (text, record, index) => {
    return (
      <div className="weight-wrapper">
        {record.edit ? (
          <div>
            <input
              defaultValue={record.sort_order}
              className="edit-weight"
              onChange={inputValueChange.bind(this, record)}
            />
            <div className="btn-wrapper">
              <span onClick={confirmWeight.bind(this, record)}>确定</span>
              <span onClick={cancel.bind(this, record)}>取消</span>
            </div>
          </div>
        ) : (
          <div>
            <div className="weight-content">{record.sort_order}</div>
            <div className="change" onClick={changeWeight.bind(this, record)}>
              修改
            </div>
          </div>
        )}
      </div>
    );
  };

  const inputValueChange = (record, input) => {
    let value = input.target.value;
    console.log(value);
    refreshData(record.id, { editvalue: value });
  };

  const changeWeight = (record) => {
    refreshData(record.id, { edit: true });
  };

  const confirmWeight = async (record) => {
    var dic = datas.find((item) => {
      return item.id == record.id;
    });

    if (!/(^[1-9]\d*$)/.test(dic.editvalue)) {
      Message.info("权重只能输入正整数");
      return;
    }

    try {
      let category = handleUpPd(record, { sort_order: dic.editvalue });
      const { data } = await client.mutate({
        mutation: Xiu_Gai_Lei_Mu_Mutation,
        variables: category,
        fetchPolicy: "no-cache",
      });
      if (data.updateProductCategory) {
        refreshData(record.id, { sort_order: dic.editvalue, edit: false });
        Message.success("提交成功");
      }
    } catch (e) {}
  };

  const cancel = (record) => {
    refreshData(record.id, { edit: false });
  };

  const refreshData = (id, param) => {
    var i = datas.findIndex((item) => {
      return item.id == id;
    });
    var tdatas = [...datas];
    var dic = datas[i];
    var temp = { ...dic, ...param };
    tdatas.splice(i, 1, temp);
    setDatas(tdatas);
  };

  const handleUpPd = (record, params) => {
    var product = {};
    product.product_category_id = record.id;
    product.name = record.name;
    product.sort_order = record.sort_order;
    product.status = record.status;
    let tempP = { ...product, ...params };
    return tempP;
  };

  //修改
  const onSheldChange = async (index, record, checked) => {
    console.log(record.name, index, datas);
    var category = handleUpPd(record, { status: checked ? 1 : 0 });
    try {
      const { data } = await client.mutate({
        mutation: Xiu_Gai_Lei_Mu_Mutation,
        variables: category,
        fetchPolicy: "no-cache",
      });
      if (data.updateProductCategory) {
        refreshData(record.id, {
          status: data.updateProductCategory.status,
          updated_at: handleDate(data.updateProductCategory.updated_at),
        });
        Message.success("提交成功");
      }
    } catch (e) {}
  };

  const columns1Getter = HeaderCell.map((col) => {
    if (col.dataIndex == "status") {
      col.render = onSheldRender;
    } else if (col.dataIndex == "optional") {
      col.render = optionalRender;
    } else if (col.dataIndex == "sort_order") {
      col.render = weightRender;
    }
    col.align = "center";
    return col;
  });

  const onChange = (page, pageSize) => {
    console.log(page, pageSize);
    pageInfo.page = page;
    requestData();
  };

  const TableProps = {
    columns: columns1Getter,
    dataSource: datas,
    bordered: true,
    pagination: {
      position: ["bottomCenter"],
      defaultCurrent: 1,
      defaultPageSize: pageInfo.limit,
      onChange: onChange,
      showSizeChanger: false,
      showQuickJumper: true,
      total: pagenum * pageInfo.limit,
    },
    scroll: {
      x: "max-content",
      scrollToFirstRowOnChange: true,
    },
  };

  const add = () => {
    history.push("/category/newclass");
  };

  return (
    <div className="product-wrapper">
      <span className="add" onClick={add}>
        新增类目
      </span>
      <Table {...TableProps} />
    </div>
  );
};

export default Category;
