import React from 'react';
import Block from './block';
import app from "@/app.json";
// import QR from '../../../assets/images/QR.png'

const QRCode = require("qrcode-react");
function QrcodeView(props){
  const { id, storeid, title } = props;
  console.log("id:",id);

  return (
    <Block>
      <div style={style.wrapper}>
        <div style={style.left}>
          {/* <img src={QR} width='92' height='92'></img> */}
          <QRCode
            value={`${app.host}/storeDetail?id=${id}&product_type=1`}
            // value={`${app.host}/packageB/pages/productList/productList?id=${id}`}
            // value={`https://m.dev.dzans.cn/packageA/pages/pay/pay?id=${encodeURIComponent(
            //   `${id}`
            // )}`}
            size={92}
          />
        </div>
        <div style={style.right}>
          <span>{title || "扫码进入我的店铺"}</span>
          {/* <span style={{lineHeight: '14px'}}>获取更多开店资料</span> */}
        </div>
      </div>
    </Block>
  )
}

const style = {
  wrapper:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin:'2px 0'
  },
  left:{},
  right:{
    marginLeft:'14px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '14px'
  }
}

export default QrcodeView