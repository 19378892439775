import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { Checkbox, Modal, message } from 'antd'
import ZStepper from '@/components/zStepper/zStepper'
import { refundSubmit } from '@/apis/order'
import { formatNumber } from '@/utils'
import './refundModal.less'

const RefundModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [info, setInfo]= useState({})
    const [goods, setGoods] = useState([])
    const isAllChecked = useMemo(() => {
        if (goods.length > 0) {
            return goods.every(t => t.checked)
        }
        return false
    }, [goods])
    // 商品总额 | 打包费
    const [goodsAllPrice, packagePrice] = useMemo(() => {
        let is = true
        let p = 0
        const price = goods.reduce((prev, item) => {
            if (is && (!item.checked || item.value < item.number)) {
                is = false
            }
            if (item.checked && (info.order_type != 2 || info.package_type === 2 || info.pack_type === 1)) {
                p += item.value * item.package_price
            }
            return prev += item.checked ? item.value * item.price : 0
        }, 0)
        p = is ? info.package_price : (info.package_status === 1 ? 0 : p)
        return [formatNumber(price), formatNumber(p)]
    }, [goods, info])

    // 总金额
    const totalAllPrice = useMemo(() => {
        return formatNumber(goodsAllPrice + packagePrice + info.freight)
    }, [goodsAllPrice, packagePrice, info])

    // 商品选中 change
    const onGoodChange = (index, is) => {
        const _list = [...goods]
        const item = _list[index]
        item.checked = is
        if (is && item.value === 0) {
            item.value = item.number
        }
        setGoods(_list)
    }
    // 商品数量 change
    const onGoodNumChange = (num, index) => {
        const _list = [...goods]
        _list[index].value = num
        if (num === 0) {
            _list[index].checked = false
        }
        setGoods(_list)
    }
    const onAllCheckedChange = () => {
        const _list = [...goods]
        _list.forEach(item => {
            if (isAllChecked) {
                item.checked = false
            } else {
                item.checked = true
                item.value = item.number
            }
        })
        setGoods(_list)
    }
    const onSubmit = async() => {
        if (goods.every(t => !t.checked)) return message.warning('请选择退款商品')
        try {
            const params = {
                order_id: info.order_id,
                type: 1,
                items: goods.filter(t => t.checked).map(t => ({ id: t.id, num: t.value })),
                return_method: 0
            }
            const res = await refundSubmit(params)
            message.success('提交成功')
            setVisible(false)
            typeof props.onOk === 'function' && props.onOk()
        } catch(e) {
            console.log(e)
        }
    }

    useImperativeHandle(ref, () => ({
        show: (data) => {
            const {list, ..._data} = data
            setGoods(list.map(t => ({
                id: t.id,
                cover: t.product_cover,
                name: t.product_name,
                price: +t.product_price,
                package_price: +t.package_price,
                number: t.number,
                value:  t.number,
                checked: false,
            })))
            setInfo(_data)
            setVisible(true)
        }
    }))

    return (
        <Modal
            open={visible}
            width={510}
            title="申请退款"
            className="custom-modal refund-modal"
            centered
            okText="提交"
            onOk={onSubmit}
            onCancel={() => setVisible(false)}
        >
            <div className="refund-modal-title">退款商品<span>*</span></div>
            <div className="refund-modal-list">
                {goods.map((item, index) => (<div className="item-content" key={item.id}>
                    <Checkbox checked={item.checked} onChange={e => onGoodChange(index, e.target.checked)} />
                    <div className="item-media flex-shrink-0">
                        <img className="item-img" src={item.cover} alt="" />
                    </div>
                    <div className="item-inner flex-1">
                        <div className="item-title flex-space">
                            <div className="ell1">{item.name}</div>
                            <div className="item-price">¥{item.price}</div>
                        </div>
                        <div className="flex-space align-center">
                            <div className="item-num">x{item.number}</div>
                            <ZStepper value={item.value} max={item.number} onChange={v => onGoodNumChange(v, index)} />
                        </div>
                    </div>
                </div>))}
            </div>
            <div className="refund-modal-cells">
                <div className="item-cell align-center">
                    <div className="item-cell__label">退款商品总价</div>
                    <div className="bold">¥{goodsAllPrice}</div>
                </div>
                <div className="item-cell align-center">
                    <div className="item-cell__label">配送费</div>
                    <div className="bold">¥{info.freight}</div>
                </div>
                <div className="item-cell align-center">
                    <div className="item-cell__label">打包费</div>
                    <div className="bold">¥{packagePrice}</div>
                </div>
                <div className="item-cell align-start">
                    <Checkbox checked={isAllChecked} onChange={e => onAllCheckedChange()}>全选</Checkbox>
                    <div className="flex-column align-end">
                        <div className="flex align-center bold">退款金额<span className="total">¥{totalAllPrice}</span></div>
                        <div className="item-cell__title">本单送的免费餐券相对应扣除</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
})

export default RefundModal