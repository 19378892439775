import React from "react";
import { Form, Input, Button, Message } from "antd";
import { CREATE_SUPPLIER, GET_SUPPLIER } from "@/graphql/store";
import client from "@/graphql/apollo-config";
import qs from "query-string";

const AddSupplyChain = (props) => {
  const { history } = props;
  const { id } = qs.parse(history.location.search);

  const [form] = Form.useForm();
  const onSubmit = async (values) => {
    const { data } = await client.mutate({
      mutation: CREATE_SUPPLIER,
      fetchPolicy: "no-cache",
      variables: {
        ...values,
        ...(id ? { id: +id } : {}),
      },
    });
    if (data.createSupplier) {
      Message.success("提交成功");
      setTimeout(() => {
        history.go(-1);
      }, 2000);
    }
  };

  React.useEffect(() => {
    if (id) {
      client
        .query({
          query: GET_SUPPLIER,
          fetchPolicy: "no-cache",
          variables: {
            id: +id,
          },
        })
        .then(({ data: { supplier } }) => {
          form.setFieldsValue(supplier);
        });
    }
  }, [form, id]);

  return (
    <div className="page-wrapper">
      <Form form={form} onFinish={onSubmit} autoComplete="off">
        <Form.Item
          name="name"
          label="供应商名称"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="请输入供应商名称" maxLength={25} />
        </Form.Item>
        <Form.Item
          name="mobile"
          label="手机号码"
          required
          rules={[
            {
              required: true,
            },
            {
              pattern: /^1\d{10}$/,
              message: "请输入正确的手机号",
            },
          ]}
        >
          <Input placeholder="请输入手机号码" maxLength={11} />
        </Form.Item>
        <Form.Item
          name="address"
          label="售后地址"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="请输入售后地址" maxLength={50} />
        </Form.Item>
        <Form.Item
          name="accounting_period"
          label="账期"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="请输入账期" maxLength={10} />
        </Form.Item>
        <Form.Item name="weekdays_stop_order" label="工作日截单时间">
          <Input placeholder="请输入工作日截单时间" maxLength={10} />
        </Form.Item>
        <Form.Item name="weekend_deliver" label="周末发货安排">
          <Input placeholder="请输入周末发货安排" maxLength={10} />
        </Form.Item>
        <Form.Item name="remark" label="备注">
          <Input.TextArea placeholder="请输入备注" maxLength={50} />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button
            onClick={() => window.history.back()}
            style={{
              marginRight: 10,
            }}
          >
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddSupplyChain;
