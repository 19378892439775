import React, { useState, useEffect } from "react";
import "./index.less";
import {
  Table,
  Button,
  Input,
  Modal,
  Switch,
  Message,
  Space,
  Form,
  Row,
  Col,
  Select,
  Cascader,
} from "antd";
import {
  GET_CATEGORY,
  Get_Store_Categorys,
  Get_Store_Products,
} from "@/graphql/products";
import client from "@/graphql/apollo-config";
import app from "@/app.json";
import moment from "moment";
import {
  DEL_PRODUCT,
  BATCH_PRODUCT,
  GOODS_SET_STATUS,
  GOODS_SET_WEIGHT,
} from "../../graphql/product";
import { USER } from "../../graphql/finance";
import { SearchOutlined } from "@ant-design/icons";
import { useQuery, useLazyQuery } from "react-apollo";
import { SUPPLIERS_LIST } from "@/graphql/store";
import ExportBtn from "./exportBtn";
import { setGoodsPackage } from "@/apis/order";

var pageInfo = {
  limit: 15,
  page: 1,
};
const Product = (props) => {
  const { history } = props;
  const {
    params: { id },
  } = props.match;

  const [selectStatus, setSelectStatus] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [pagenum, setPagenum] = useState(1);
  const [isSpend, setIsSpend] = useState(0);
  const [statusType, setStatusType] = useState(0);
  const [storeID, setStoreID] = useState(null);
  const [storeType, setStoreType] = useState(0);
  const [isBatch, setBatch] = useState(false); // 是否点击了批量操作
  const [batchArray, setBatchArray] = useState([]); // 批量操作数组
  const [sendData, setData] = useState({
    spu: null,
    name: null,
    product_price_start: null,
    product_price_end: null,
  });
  const [total, setTotal] = useState(0);
  const [isSupplier, setIsSupplier] = useState(0);
  let sid = null;

  const statusTitles = ["全部", "上架", "下架", "缺货"];

  const [searchParams, setSearchParams] = React.useState({
    module_type: null,
    id: null,
    product_category_id: null,
    platform_category_id: null,
    supplier_id: null,
  });

  useEffect(() => {
    requestStoreId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendData]);

  const onChange = (page) => {
    pageInfo.page = page;
    requestData(statusType);
  };

  const categoryRes = useQuery(GET_CATEGORY, {
    fetchPolicy: "no-cache",
  });

  const productCategoryRes = useQuery(Get_Store_Categorys, {
    fetchPolicy: "no-cache",
    variables: {
      limit: 100,
      page: 1,
    },
  });

  const [getSuppliersData, { data }] = useLazyQuery(SUPPLIERS_LIST, {
    variables: {
      page: 1,
      limit: 200,
    },
    fetchPolicy: "no-cache",
  });

  const goodsImageRender = (text, record, index) => {
    return (
      <img className="image" src={record.cover ? record.cover.url : "*"} />
    );
  };

  const edit = (record) => {
    history.push(`/product2/newproduct?id=${record.id}`);
  };

  const del = (item) => {
    Modal.confirm({
      content: "确定删除该商品吗？",
      onOk() {
        delConfirm(item);
      },
    });
  };

  const delConfirm = async (item) => {
    try {
      const { data } = await client.query({
        query: DEL_PRODUCT,
        variables: {
          id: item.id,
        },
        fetchPolicy: "no-cache",
      });
      if (data) {
        Message.success("删除成功");
        var index = dataSource.findIndex((e) => {
          return e.id == item.id;
        });
        let tempDatas = [...dataSource];
        tempDatas.splice(index, 1);
        setDataSource([...tempDatas]);
      } else {
        Message.error("删除失败");
      }
    } catch (error) {}
  };

  const optionalRender = (text, record, index) => {
    return (
      <div className="optional">
        <span className="edit" onClick={edit.bind(this, record)}>
          编辑
        </span>
        <span className="preview" onClick={del.bind(this, record)}>
          删除
        </span>
      </div>
    );
  };

  // 主价格 render
  const priceRender = (text, record, index) => {
    return (
      <div>￥{record.price}</div>
    )
  }

  // 划线价 render
  const firstPriceRender = (text, record, index) => {
    return (
      <div>￥{record.first_price}</div>
    )
  }

  // 供货价 render
  const offerPriceRender = (text, record, index) => {
    return (<>
      {record.productSpecs.length > 0 ? (
        <div className="specs-wrapper">
          {record.productSpecs.map((item, index) => {
            return (
              <div className="specs-content" key={index}>
                <span className="name">{item.name}:</span>
                <span className="stock">￥{item.offer_price}</span>
              </div>
            );
          })}
        </div>
      ) : (
        <div>￥{record.product_offer_price}</div>
      )}
    </>)
  }

  // 主库存 render
  const stockRender = (text, record, index) => {
    return (
      <div>{record.stock}件</div>
    )
  }

  // 规格 render
  const specsRender = (text, record, index) => {
    return (
      <div className="specs-wrapper">
        {record.productSpecs.map((item, index) => {
          return (
            <div className="specs-content" key={index}>
              <span className="name">{item.name}</span>
            </div>
          );
        })}
      </div>
    );
  };

  // 规格价 render
  const specsPriceRender = (text, record, index) => {
    return (
      <div className="specs-wrapper">
        {record.productSpecs.map((item, index) => {
          return (
            <div className="specs-content" key={index}>
              <span className="name">{item.name}:</span>
              <span className="stock">￥{item.price}</span>
            </div>
          );
        })}
      </div>
    );
  };

  // 规格库存 render
  const specsStockRender = (text, record, index) => {
    return (
      <div className="specs-wrapper">
        {record.productSpecs.map((item, index) => {
          return (
            <div className="specs-content" key={index}>
              <span className="name">{item.name}:</span>
              <span className="stock">{item.stock}件</span>
            </div>
          );
        })}
      </div>
    );
  };

  // 状态0下架1上架
  const onSheldRender = (text, record, index) => {
    return (
      <div>
        <Switch
          checked={record.status != 0}
          loading={record.loading}
          onClick={onSheldChange.bind(this, index, record)}
        />
      </div>
    );
  };

  // 名称
  const onNameRender = (text, record, index) => {
    return (
      <div>
        <div>{record.name} </div>
        {/*{record.supplier?<div>供应商:{record.supplier}</div>:null}*/}
      </div>
    );
  };

  const moduleTypeOptions = [
    {
      value: 1,
      label: "官方商城",
    },
    {
      value: 2,
      label: "特推商城",
    },
    {
      value: 3,
      label: "红包券商城",
    },
    {
      value: 0,
      label: "普通线上商城",
    },
  ];

  const productModuleTypeRender = (text, record, index) => {
    return (
      <div>
        {record.module_type == 1
          ? "官方商城"
          : record.module_type == 2
          ? "特推商城"
          : record.module_type == 3
          ? "红包券商城"
          : "普通线上商城"}
      </div>
    );
  };

  const productCategoryRender = (text, record, index) => {
    return <div>{record.productCategory && record.productCategory.name}</div>;
  };

  const weightRender = (text, record, index) => {
    return (
      <div className="weight-wrapper">
        {record.edit ? (
          <div>
            <input
              defaultValue={record.weight}
              className="edit-weight"
              onChange={inputValueChange.bind(this, record)}
            />
            <div className="btn-wrapper">
              <span onClick={confirmWeight.bind(this, record)}>确定</span>
              <span onClick={cancel.bind(this, record)}>取消</span>
            </div>
          </div>
        ) : (
          <div>
            <div className="weight-content">{record.weight}</div>
            <div className="change" onClick={changeWeight.bind(this, record)}>
              修改
            </div>
          </div>
        )}
      </div>
    );
  };

  // 打包费render
  const packagePriceRender = (text, record, index) => {
    return (
      <div className="weight-wrapper">
        {record.edit2 ? (
          <div>
            <input
              defaultValue={record.package_price}
              className="edit-weight"
              onChange={(e) => {
                let value = e.target.value;
                console.log(value);
                refreshData(record.id, { packageValue: value });
              }}
            />
            <div className="btn-wrapper">
              <span onClick={packagePriceConfirm.bind(this, record)}>确定</span>
              <span onClick={() => {
                refreshData(record.id, { edit2: false });
              }}>取消</span>
            </div>
          </div>
        ) : (
          <div>
            <div className="weight-content">{record.package_price}</div>
            <div className="change" onClick={() => {
              refreshData(record.id, { edit2: true });
            }}>
              修改
            </div>
          </div>
        )}
      </div>
    );
  }
  // 打包费确定
  const packagePriceConfirm = async (record) => {
    var dic = dataSource.find((item) => {
      return item.id == record.id;
    });
    if (!/^(0|[1-9]\d*)([\.][0-9]{1,2})?$/.test(dic.packageValue)) {
      Message.info("打包费只能输入正整数或正浮点数");
      return;
    }
    try {
      const res = await setGoodsPackage({
        id: record.id,
        package_price: dic.packageValue
      })
      if (res.errors.code === 1) {
        refreshData(record.id, { package_price: dic.packageValue, edit2: false });
        Message.success("修改成功");
      }
    } catch(e) {
      console.log(e)
    }
  }

  const inputValueChange = (record, input) => {
    let value = input.target.value;
    console.log(value);
    refreshData(record.id, { editvalue: value });
  };

  const changeWeight = (record) => {
    refreshData(record.id, { edit: true });
  };

  const confirmWeight = async (record) => {
    var dic = dataSource.find((item) => {
      return item.id == record.id;
    });

    if (!/^(0|[1-9]\d*)$/.test(dic.editvalue)) {
      Message.info("权重只能输入正整数");
      return;
    }

    try {
      const { data } = await client.mutate({
        mutation: GOODS_SET_WEIGHT,
        variables: {
          weight: parseInt(dic.editvalue),
          product_ids: record.id + ''
        },
      });
      if (data.GoodsWeightSetting) {
        refreshData(record.id, { weight: dic.editvalue, edit: false });
        Message.success("修改成功");
      }
    } catch (e) {}
  };

  const cancel = (record) => {
    refreshData(record.id, { edit: false });
  };

  const refreshData = (id, param) => {
    var i = dataSource.findIndex((item) => {
      return item.id == id;
    });
    var datas = [...dataSource];
    var dic = dataSource[i];
    var temp = { ...dic, ...param };
    datas.splice(i, 1, temp);
    setDataSource(datas);
  };

  //商品上下架
  const onSheldChange = async (index, record, checked) => {
    refreshData(record.id, { loading: true });
    try {
      const { data } = await client.mutate({
        mutation: GOODS_SET_STATUS,
        variables: {
          status: checked ? 1 : 0,
          product_ids: record.id + ''
        },
      });
      if (data.GoodsSaleStatusSetting) {
        refreshData(record.id, { status: checked ? 1 : 0, loading: false });
        Message.success("修改成功");
      }
    } catch (e) {}
  };

  //状态切换
  const changeStatus = (status) => {
    pageInfo.page = 1;
    setSelectStatus(status);
    requestData(status);
  };

  const requestData = React.useCallback(
    async (type, search) => {
      setStatusType(type);
      if (search) {
        setSearchParams(search);
      }
      let _search = search || searchParams;
      if (sid == null && storeID == null) {
        return Message.info("您还没有店铺");
      }
      var dic = {
        storeId: sid ? sid : storeID,
        product_type: 1, // 外卖商品
        ...sendData,
        ...pageInfo,
        ..._search,
        platform_category_id: _search?.platform_category_id
          ? _search.platform_category_id[
              _search.platform_category_id.length - 1
            ]
          : null,
      };
      switch (type) {
        case 0: {
          //全部
          break;
        }
        case 1: {
          //上架
          dic = { ...dic, status: 1 };
          break;
        }
        case 2: {
          //下架
          dic = { ...dic, status: 0 };
          break;
        }
        case 3: {
          //缺货
          dic = { ...dic, stock: 0 };
          break;
        }
        default:
          break;
      }
      try {
        const { data } = await client.query({
          query: Get_Store_Products,
          variables: dic,
          fetchPolicy: "no-cache",
        });
        data.product.data.forEach((item, index) => {
          item.key = index;
          item.loading = false;
          item.editvalue = item.weight + '';
          item.packageValue = item.package_price + '';
          item.created_at = moment(item.created_at * 1000).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        });
        pageInfo.page = data.product.current_page;
        setPagenum(data.product.last_page);
        setDataSource(data.product.data);
        setTotal(data.product.total);
      } catch (e) {}
    },
    [searchParams, sendData, sid, storeID]
  );

  const add = async () => {
    try {
      const { data } = await client.query({
        query: USER,
        fetchPolicy: "no-cache",
      });
      if (data.user) {
        if (
          data.user.store.delivery_type === 0 &&
          data.user.store.product_type === 0 &&
          data.user.store.is_express === 0
        ) {
          Modal.confirm({
            content: "请先到物流设置开通物流",
            okText: "去设置",
            cancelText: "取消",
            onOk() {
              history.push("/logistics");
            },
          });
        } else {
          history.push("/product2/newproduct");
        }
      }
    } catch (error) {}
  };

  const batch = async () => {
    setBatch(!isBatch);
  };

  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`搜索`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, height: 27 }}
              >
                搜索
              </Button>
              <Button
                onClick={() =>
                  handleReset(selectedKeys, clearFilters, dataIndex)
                }
                size="small"
                style={{ width: 90, height: 27 }}
              >
                重置
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#F4981D" : undefined }} />
      ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    if (selectedKeys[0] !== undefined) {
      if (dataIndex == "spu") {
        pageInfo.page = 1;
        setData({
          ...sendData,
          spu: selectedKeys[0],
        });
      } else if (dataIndex == "name") {
        pageInfo.page = 1;
        setData({
          ...sendData,
          name: selectedKeys[0],
        });
      }
    }
  };
  const handleReset = (selectedKeys, clearFilters, dataIndex) => {
    clearFilters();
    if (selectedKeys[0] !== undefined) {
      if (dataIndex == "spu") {
        pageInfo.page = 1;
        setData({
          ...sendData,
          spu: null,
        });
      } else if (dataIndex == "name") {
        pageInfo.page = 1;
        setData({
          ...sendData,
          name: null,
        });
      }
    }
  };

  const HeaderCell = [
    {
      title: "商品ID",
      dataIndex: "id",
    },
    {
      title: "SPU",
      dataIndex: "spu",
      ...getColumnSearchProps("spu"),
    },
    {
      title: "商品条形码",
      dataIndex: "barcode",
    },
    {
      title: "封面",
      dataIndex: "cover",
    },
    {
      title: "商品类目",
      dataIndex: "productCategory",
    },
    {
      title: "供应商名称",
      dataIndex: "supplier",
    },
    {
      title: "商品名称",
      dataIndex: "name",
      width: 300,
      ...getColumnSearchProps("name"),
    },

    // 新增 start
    {
      title: '主价格',
      dataIndex: 'price',
    },
    {
      title: '划线价',
      dataIndex: 'first_price',
    },
    {
      title: '主库存',
      dataIndex: 'stock',
    },
    {
      title: '供货价',
      dataIndex: 'offer_price',
    },
    {
      title: '规格',
      dataIndex: 'specs',
    },
    {
      title: '规格价',
      dataIndex: 'specs_price',
    },
    {
      title: '规格库存',
      dataIndex: 'specs_stock',
    },
    // 新增 end

    {
      title: "是否上架",
      dataIndex: "status",
    },
    {
      title: "生效模块",
      dataIndex: "module_type",
    },
    {
      title: "展示权重",
      dataIndex: "weight",
      sorter: (a, b) => a.weight - b.weight,
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
    },
    {
      title: "打包费",
      dataIndex: "package_price",
      sorter: (a, b) => a.package_price - b.package_price,
    },
    {
      title: "操作",
      fixed: "right",
      dataIndex: "optional",
    },
  ];
  const columns1Getter = HeaderCell.map((col) => {
    if (col.dataIndex == "cover") {
      col.render = goodsImageRender;
    } else if (col.dataIndex == "status") {
      col.render = onSheldRender;
    } else if (col.dataIndex == "name") {
      col.render = onNameRender;
    } else if (col.dataIndex == "optional") {
      col.render = optionalRender;
    } else if (col.dataIndex == "productCategory") {
      col.render = productCategoryRender;
    } else if (col.dataIndex == "weight") {
      col.render = weightRender;
    } else if (col.dataIndex == "module_type") {
      col.render = productModuleTypeRender;
    } else if (col.dataIndex == "price") {
      col.render = priceRender;
    } else if (col.dataIndex == "first_price") {
      col.render = firstPriceRender;
    } else if (col.dataIndex == "offer_price") {
      col.render = offerPriceRender;
    } else if (col.dataIndex == "stock") {
      col.render = stockRender;
    } else if (col.dataIndex == "specs") {
      col.render = specsRender;
    } else if (col.dataIndex == "specs_price") {
      col.render = specsPriceRender;
    } else if (col.dataIndex == "specs_stock") {
      col.render = specsStockRender;
    } else if (col.dataIndex == 'package_price') {
      col.render = packagePriceRender
    }

    col.align = "center";
    return col;
  });

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setBatchArray(selectedRowKeys);
    },
  };

  const TableProps = {
    columns: columns1Getter,
    dataSource: dataSource,
    bordered: true,
    pagination: {
      current: pageInfo.page,
      position: ["bottomCenter"],
      defaultPageSize: pageInfo.limit,
      onChange: onChange,
      showSizeChanger: false,
      showQuickJumper: true,
      total,
      showTotal: (total) => `共${total}条`,
    },
    scroll: {
      x: "max-content",
      scrollToFirstRowOnChange: true,
    },
    rowKey: "id",
  };
  const requestStoreId = async () => {
    try {
      const { data } = await client.query({
        query: USER,
        fetchPolicy: "no-cache",
      });
      if (data.user.store.id) {
        sid = data.user.store.id;
        setStoreID(data.user.store.id);
        setStoreType(data.user.store.product_type); //0实物1电子卡券
        requestData(statusType);
        setIsSpend(data.user.store.is_spend);

        if (data.user.store.open_supplier) {
          getSuppliersData();
          setIsSupplier(data.user.store.open_supplier);
        }
      } else {
        Message.info("您还没有店铺!");
      }
    } catch (e) {}
  };

  const batchProduct = (type) => {
    const contentMap = {
      1: "删除",
      2: "上架",
      3: "下架",
    };
    if (batchArray.length > 0) {
      Modal.confirm({
        content: `确定${contentMap[type]}，共${batchArray.length}个商品吗？`,
        okText: "确定",
        onOk() {
          batchMutation(type);
        },
        onCancel() {},
      });
    } else {
      Message.info("请先选择商品");
    }
  };

  const batchMutation = async (type) => {
    try {
      const { data } = await client.mutate({
        mutation: BATCH_PRODUCT,
        variables: {
          ids: batchArray,
          type: type,
        },
        fetchPolicy: "no-cache",
      });
      if (data.batchProduct) {
        Message.success("修改成功");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (e) {}
  };

  const [form] = Form.useForm();

  const getFields = () => {
    return (
      <>
        <Col span={6}>
          <Form.Item name="id" label="商品ID">
            <Input placeholder="请输入商品ID" />
          </Form.Item>
        </Col>
        {isSpend == 1 && (
          <Col span={6}>
            <Form.Item name="module_type" label="生效模块">
              <Select
                placeholder="请选择"
                options={moduleTypeOptions}
                allowClear
              />
            </Form.Item>
          </Col>
        )}

        <Col span={6}>
          <Form.Item name="platform_category_id" label="平台分类">
            <Cascader
              changeOnSelect
              options={categoryRes.data ? categoryRes.data.categorys : []}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="product_category_id" label="商品类目">
            <Select placeholder="请选择" allowClear>
              {(productCategoryRes.data?.ProductCategories
                ? productCategoryRes.data.ProductCategories.data
                : []
              ).map((i) => {
                return (
                  <Select.Option value={i.id} key={i.id}>
                    {i.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        {Boolean(isSupplier) && (
          <Col span={6}>
            <Form.Item name="supplier_id" label="供应商">
              <Select
                placeholder="请选择"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {(data?.suppliers ? data.suppliers.data : []).map((i) => {
                  return (
                    <Select.Option value={i.id} key={i.id}>
                      {i.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        )}
      </>
    );
  };

  return (
    <div className="product-wrapper">
      <Form
        form={form}
        onFinish={(v) => {
          pageInfo.page = 1;
          requestData(statusType, v);
        }}
      >
        <Row gutter={24}>{getFields()}</Row>
        <Row
          style={{
            marginBottom: 20,
          }}
        >
          <Col span={24}>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
            <Button
              type="link"
              htmlType="submit"
              onClick={() => {
                form.resetFields();
                form.submit();
              }}
            >
              重置
            </Button>
          </Col>
        </Row>
        <div className="top-wrapper">
          <div className="status-wrapper">
            {statusTitles.map((title, index) => {
              return (
                <div
                  className={[
                    index == selectStatus ? "selected" : null,
                    "status-btn",
                  ].join(" ")}
                  key={index}
                  onClick={() => changeStatus(index)}
                >
                  {title}
                </div>
              );
            })}
          </div>
          {storeID ? (
            <div className="btn-right-wrapper">
              <div className="add2" onClick={add}>
                新增商品
              </div>
              <div className="batch-btn" onClick={batch}>
                批量操作
              </div>
            </div>
          ) : null}
        </div>
        {isBatch && (
          <div className="batch-wrapper">
            <span onClick={() => batchProduct(2)}>上架</span>
            <span onClick={() => batchProduct(3)}>下架</span>
            <span onClick={() => batchProduct(1)}>删除</span>
          </div>
        )}
        <div style={{ textAlign: "right", marginBottom: "10px" }}>
          <Button type="primary" style={{ marginRight: '20px' }} onClick={() => {
            history.push("/packingFeeSetting");
          }}>打包费设置</Button>
          <ExportBtn
            query={Get_Store_Products}
            variables={{
              storeId: sid ? sid : storeID,
              product_type: 1,
              ...sendData,
              ...pageInfo,
              ...searchParams,
              platform_category_id: searchParams?.platform_category_id
                ? searchParams.platform_category_id[
                    searchParams.platform_category_id.length - 1
                  ]
                : null,
            }}
            total={total}
            code={null}
            columns={TableProps.columns}
            getDataSource={(data) => data}
          />
        </div>
        <Table
          rowSelection={
            isBatch
              ? {
                  type: "checkbox",
                  ...rowSelection,
                }
              : null
          }
          {...TableProps}
        />
      </Form>
    </div>
  );
};

export default Product;
