import React, { useState } from "react";
import { Upload, Modal, Button, Message } from "antd";
import { getBase64 } from "../../utils";
import app from "../../app.json";
import ImgCrop from "antd-img-crop";
import "antd/es/modal/style";
import "antd/es/slider/style";

const UploadImg = (props) => {
  const { imagesList, storeVideo, changeImages, changeVideo, OSSData } = props;

  // 图片上传
  const [previewImg, setPreviewImg] = useState(false);
  const [previewVideo, setPreviewVideo] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  // 店铺主图
  const handlePreview = async (file, i) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    if (i == 3) {
      setPreviewVideo(true);
    } else {
      setPreviewImg(true);
    }
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onChange = (e, i) => {
    if (e.file.status !== "removed") {
      const isLt2M = e.file.size / 1024 / 1024 < 1;
      const isLt10M = e.file.size / 1024 / 1024 < 10;

      if (!isLt2M && e.file.type.indexOf("image") != -1) {
        Message.error("图片大小不能超过1m");
        return;
      }
      if (!isLt10M && e.file.type.indexOf("video") != -1) {
        Message.error("视频大小不能超过10m");
        return;
      }
    }
    let b = [];
    if (i == 3) {
      if (e.file.status === "removed") {
        changeVideo(b);
      } else {
        if (e.file.type.indexOf("video") != -1) {
          e.fileList.map((i) => {
            return (i.url =
              `${app.oss_host}` +
              OSSData.dir +
              i.uid +
              i.name.slice(i.name.lastIndexOf(".")));
          });
          changeVideo(e.fileList);
        } else {
          Message.info("请上传视频格式的文件");
        }
      }
    } else {
      if (e.file.status === "removed") {
        // 删除
        b = imagesList.filter((i) => {
          return i.url != e.file.url;
        });
        changeImages(b);
      } else {
        // 添加
        if (e.file.type.indexOf("image") != -1) {
          if (e.file.type.indexOf("gif") != -1) {
            Message.info("不支持上传gif图");
          } else {
            for (let i = 0; i < e.fileList.length; i++) {
              if (e.fileList[i].type) {
                const suffix = e.fileList[i].name.slice(
                  e.fileList[i].name.lastIndexOf(".")
                );
                e.fileList[i].url =
                  `${app.oss_host}` + OSSData.dir + e.fileList[i].uid + suffix;
              }
            }
            changeImages(e.fileList);
          }
        } else {
          Message.info("请上传图片格式的文件");
        }
      }
    }
  };
  const getExtraData = (file) => {
    const suffix = file.name.slice(file.name.lastIndexOf("."));
    return {
      key: OSSData.dir + file.uid + suffix,
      OSSAccessKeyId: OSSData.accessid,
      policy: OSSData.policy,
      Signature: OSSData.signature,
    };
  };
  const onRemove = () => {};

  return (
    <div>
      {/*图片上传*/}
      <div
        className="category-content_wrapper"
        style={{ alignItems: "flex-start", marginBottom: 0 }}
      >
        <div className="title" style={{ marginRight: 50 }}>
          店铺轮播图 <i className="red">*</i>
        </div>
        <div className="content category-content_upload">
          <div className="upLoad-content">
            {imagesList.length < 1 && (
              <div className="upload-img">
                <i className="iconfont">&#xe717;</i>
              </div>
            )}
            <ImgCrop aspect={7 / 3} rotate={true}>
              <Upload
                listType="picture-card"
                fileList={imagesList}
                action={`https://${OSSData.host}`}
                onPreview={(file, name) => handlePreview(file, 2)}
                onChange={(e) => onChange(e, 2)}
                onRemove={() => onRemove()}
                data={(file) => getExtraData(file)}
              >
                {imagesList.length >= 5 ? null : (
                  <Button className="upload-btn">选择文件</Button>
                )}
              </Upload>
            </ImgCrop>
            <Modal
              visible={previewImg}
              title={previewTitle}
              footer={null}
              onCancel={() => {
                setPreviewImg(false);
              }}
            >
              <img style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>
        </div>
      </div>
      <div className="tip3">
        *
        建议上传710*300px或比例7:3，JPG、PNG格式，图片小于1M；最多上传5张图片。
      </div>
      {/*视频上传*/}
      <div
        className="category-content_wrapper"
        style={{ alignItems: "flex-start", marginBottom: 0 }}
      >
        <div className="title">
          介绍视频 <i className="red"></i>
        </div>
        <div className="content category-content_upload">
          <div className="upLoad-content">
            {storeVideo.length < 1 && (
              <div className="upload-img">
                <i className="iconfont">&#xe718;</i>
              </div>
            )}
            <Upload
              fileList={storeVideo}
              action={`https://${OSSData.host}`}
              listType={"picture-card"}
              onPreview={(file, name) => handlePreview(file, 3)}
              onChange={(e) => onChange(e, 3)}
              onRemove={() => onRemove()}
              data={(file) => getExtraData(file)}
            >
              {storeVideo.length >= 1 ? null : (
                <Button className="upload-btn">选择文件</Button>
              )}
            </Upload>
            <Modal
              visible={previewVideo}
              title={previewTitle}
              footer={null}
              onCancel={() => {
                setPreviewVideo(false);
              }}
            >
              <video
                controls="controls"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </div>
        </div>
      </div>
      <div className="tip3 tip2">
        *
        选填，建议上传710*300px或比例7:3，MP4格式，视频小于10M；只允许上传1个视频。
      </div>
    </div>
  );
};

export default UploadImg;
