import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import './zStepper.less'

const Z_NAME = 'z-stepper'

const ns = {
    name: Z_NAME,
    b: (str) => `${Z_NAME}-${str}`,
    e: (str) => `${Z_NAME}--${str}`,
    s: (str) => `${Z_NAME}__${str}`,
}

function ZStepper (props) {
    const {
        value = 0,
        min = 0,
        max = 99,
        distance = 1,
        disabled = false,
        onChange = () => {},
    } = props
    const [_value, setValue] = useState(0)

    useEffect(() => {
        setValue(value)
    }, [value])

    const onInputHandle = (dis) => {
        if (disabled) return;
        if (!dis) {
            if (_value > min) {
                onChange(_value - distance, dis)
            }
        } else {
            if (_value < max) {
                onChange(_value + distance, dis)
            }
        }
    }

    return (
        <div className={ns.name}>
            <div
                className={classNames({
                    [ns.s('btn')]: true,
                    [ns.e('prev')]: true,
                    [ns.e('disabled')]: _value === min
                })}
                onClick={() => onInputHandle(0)}
            ></div>
            <div className={ns.s('inner')}>{_value}</div>
            <div
                className={classNames({
                    [ns.s('btn')]: true,
                    [ns.e('next')]: true,
                    [ns.e('disabled')]: _value === max
                })}
                onClick={() => onInputHandle(1)}
            ></div>
        </div>
    )
}

export default ZStepper