import React from "react";
import { Form, Input, Button, Message } from "antd";
import { BIND_POSCODE } from "@/graphql/store";
import client from "@/graphql/apollo-config";
import qs from "query-string";

const AddPaymentCode = (props) => {
  const { history } = props;
  const { record } = qs.parse(history.location.search);

  const [form] = Form.useForm();
  const onSubmit = async (values) => {
    const { data } = await client.mutate({
      mutation: BIND_POSCODE,
      fetchPolicy: "no-cache",
      variables: {
        ...values,
        pos_code_id: values.pos_code_id || JSON.parse(record).pos_code_id,
      },
    });
    if (data.bindPosCode) {
      Message.success("提交成功");
      setTimeout(() => {
        history.go(-1);
      }, 2000);
    }
  };

  React.useEffect(() => {
    if (record) {
      form.setFieldsValue(JSON.parse(record));
    }
  }, [form, record]);

  return (
    <div className="page-wrapper">
      <Form form={form} onFinish={onSubmit} autoComplete="off">
        <Form.Item
          name="name"
          label="收款码名称"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="请输入收款码名称" maxLength={25} />
        </Form.Item>
        {!record && (
          <Form.Item
            name="pos_code_id"
            label="收款码ID"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="请输入收款码ID" maxLength={50} />
          </Form.Item>
        )}

        <Form.Item label=" " colon={false}>
          <Button
            onClick={() => window.history.back()}
            style={{
              marginRight: 10,
            }}
          >
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddPaymentCode;
