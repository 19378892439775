import React,{ useState } from 'react';
import {Modal, Input, Message} from 'antd';
import BusinessImg from "@/components/FileUpload";

import './uploadBusinessModal.less';
import client from "../../../graphql/apollo-config";
import {ADD_BUSINESS_LICENSE, IMAGE2TEXT} from "../../../graphql/store";
import {isEmpty} from "../../../utils";
import { handleImg, verification ,isCardNo} from "../../../utils/index"
import { isSocialCreditCode } from "@/utils/regexp";

const UploadBusinessModal = (props) => {
    const { visible,OSSData, cancelText, okText, onOk,onCancel,store} = props
    const [storeInfo, setStoreInfo] = useState({
        credit_code:store && store.credit_code || null,
        business_license:store && store.business_license || [],
        id_card_front:store && store.id_card_front || [],// 身份证正面
        id_card_no:store && store.id_card_no || null,
        corporation:store && store.corporation || null
    });

    const rule1 = [
        {
            rule: storeInfo.id_card_front && JSON.stringify(storeInfo.id_card_front) == '[]',
            title: "请上传身份证正面照"
        },
        {
        rule: !storeInfo.corporation || !storeInfo.corporation.trim(),
            title: "请填写姓名"
        },
        {
            rule: !storeInfo.id_card_no,
            title: "请填写身份证号"
        },
        {
            rule: !isCardNo(storeInfo.id_card_no),
            title: "请填写正确的身份证号"
        },
        {
            rule: JSON.stringify(storeInfo.business_license) == '[]',
            title: "请上传营业执照"
        },
        {
            rule: !storeInfo.credit_code,
            title: "请输入统一社会信用代码"
        },
        {
            rule: storeInfo.credit_code && storeInfo.credit_code.length < 18,
            title: "统一社会信用代码必须18位"
        },
        {
            rule: storeInfo.credit_code && !isSocialCreditCode(storeInfo.credit_code),
            title: "统一社会信用代码不正确"
        }
    ];
    const getImageType = (name) => {
        switch (name) {
            case 'id_card_front':
                return 0;//身份证
            case 'business_license':
                return 2;//营业执照
        }
    }
    const image2Text = async (name, arr) => {
        try {
            if (arr && arr.length > 0) {
                const {data} = await client.query({
                    query: IMAGE2TEXT,
                    variables: {
                        type: await getImageType(name),
                        url: handleImg(arr),
                        side: name.indexOf('_front') != -1 ? 'front' : name.indexOf('_back') != -1 ? 'back' : ''
                    },
                    fetchPolicy: "no-cache"
                });
                console.log('图片识别', arr);
                if (data && data.image2Text) {
                    switch (name) {
                        case 'business_license'://营业执照
                            const credit_code = data.image2Text.credit_code;
                            setStoreInfo({
                                ...storeInfo,
                                business_license: arr,
                                credit_code: !isEmpty(credit_code)&& credit_code!='无'? credit_code:''
                            });
                            break;
                        case 'id_card_front'://step==
                            const id_card_no = data.image2Text.id_card_no;
                            const corporation = data.image2Text.name;
                            setStoreInfo({
                                ...storeInfo,
                                id_card_front: arr,
                                corporation: !isEmpty(corporation)&& corporation!='无'? corporation:'',
                                id_card_no: !isEmpty(id_card_no)&& id_card_no!='无'? id_card_no:''
                            });
                            break;
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const showExample = (img) => {
        Modal.info({
            icon: null,
            content: <img style={{textAlign: 'center', width: '530px'}}
                          src={require(`../../../assets/images/example/${img}.jpg`)} alt=''/>,
            width: 600
        })
    }
    // 修改数据
    const changeData = (name,value) =>{
        setStoreInfo({
            ...storeInfo,
            [name]:value
        })
    }

    const submitMutate = async() =>{
        try {
            const { data } = await client.mutate({
                mutation: ADD_BUSINESS_LICENSE,
                variables:{
                    business_license: handleImg(storeInfo.business_license)?handleImg(storeInfo.business_license):'',
                    credit_code: storeInfo.credit_code?storeInfo.credit_code:'',
                    id_card_no: storeInfo.id_card_no?storeInfo.id_card_no:'',
                    corporation: storeInfo.corporation?storeInfo.corporation:'',
                    id_card_front: handleImg(storeInfo.id_card_front)?handleImg(storeInfo.id_card_front):''
                },
                fetchPolicy: "no-cache"
            });
            console.log(data, 9898989)
            if (data.addStoreLogistic) {
                Message.success('提交成功，请等待审核')
                if (typeof onOk == 'function'){
                    onOk(data.addStoreLogistic);
                }
            }
        } catch (error) {}
    }
    const submit = async() =>{
        console.log('info ============== ',storeInfo);
        if (!verification(rule1)) return;
        submitMutate()
    }
    return <Modal
        width={'50%'}
        cancelText={cancelText}
        okText={okText}
        open={visible}
        onOk={() => {
            submit();
        }}
        onCancel={() => {
            if (typeof onCancel == 'function'){
                onCancel();
            }
        }}
    >
        <div className='container'>
            <h2>开通蜂鸟配送</h2>
            <div className="category-content">
                <div style={{marginBottom:30,fontWeight:'bold'}}>以下资料开通蜂鸟同城配送时，必须填写</div>
                <div
                    className="category-content_wrapper"
                    style={{ alignItems: "flex-start" }}
                >
                    <div className="title" style={{ marginRight: 38 }}>
                        身份证正面照 <i className="red">*</i>
                    </div>
                    <BusinessImg
                        OSSData={OSSData}
                        listChange={(arr) => {
                            changeData("id_card_front", arr);
                        }}
                        onSuccess={(arr) => {
                            image2Text("id_card_front", arr);
                        }}
                        fileList={storeInfo.id_card_front}
                    />
                    <div className="img-tip">
                        • 上传JPG、PNG格式，图片小于1M；
                        <br />
                        • 证件头像清晰，文字数字清晰可辨；
                        <br />
                        • 个体户或企业用户上传法人身份证；
                        <br />
                        <p
                            style={{ color: "#048ad3", cursor: "pointer" }}
                            onClick={() => showExample(8)}
                        >
                            示例
                        </p>
                    </div>
                </div>
                <div className="category-content_wrapper">
                    <div className="title" style={{ marginRight: 54 }}>
                        姓名 <i className="red">*</i>
                    </div>
                    <div className="content">
                        <Input
                            placeholder="请填写姓名"
                            value={storeInfo.corporation}
                            className="my-input"
                            maxLength={20}
                            onChange={(e) =>
                                changeData("corporation", e.target.value)
                            }
                        />
                    </div>
                </div>
                <div className="category-content_wrapper">
                    <div className="title" style={{ marginRight: 22 }}>
                        身份证号 <i className="red">*</i>
                    </div>
                    <div className="content">
                        <Input
                            style={{ width: 300 }}
                            placeholder="请填写身份证号"
                            value={storeInfo.id_card_no}
                            className="my-input"
                            maxLength={18}
                            onChange={(e) => changeData("id_card_no", e.target.value)}
                        />
                    </div>
                </div>
                <div className="category-content_wrapper" style={{alignItems: 'flex-start'}}>
                    <div className="title">
                        营业执照 <i className="red">*</i>
                    </div>
                    <BusinessImg
                        OSSData={OSSData}
                        listChange={(arr) => {
                            changeData("business_license", arr);
                        }}
                        onSuccess={(arr) => {
                            image2Text('business_license',arr);
                        }}
                        fileList={storeInfo.business_license}
                    />
                    <div className="img-tip">
                        • 上传JPG、PNG格式，图片小于1M；
                        <br />
                        • 营业执照未过期；
                        <br />
                        • 需文字清晰、边框完整、露出国徽；
                        <br />• 复印件需加盖公章。
                        <p
                            style={{ color: "#048ad3", cursor: "pointer" }}
                            onClick={() => showExample(3)}
                        >
                            示例
                        </p>
                    </div>
                </div>
                <div className="category-content_wrapper">
                    <div className="title" style={{ marginRight: 22 }}>
                        统一社会
                        <br />
                        信用代码 <i className="red">*</i>
                    </div>
                    <div className="content">
                        <Input
                            style={{ width: 300 }}
                            placeholder="统一社会信用代码"
                            value={storeInfo.credit_code}
                            className="my-input"
                            maxLength={18}
                            onChange={(e) =>
                                changeData("credit_code", e.target.value)
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    </Modal>
}

export default UploadBusinessModal