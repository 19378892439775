import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Button, DatePicker, Form, Image, Input, Modal, Select, Table, message } from 'antd'
import { getRefundList, refundAuditSubmit } from '@/apis/order'
import './refundList.less'

const { RangePicker } = DatePicker;

// 退款状态
const REFUND_STATUS = {
    1: '申请中',
    2: '已退款',
    3: '商家已拒绝',
    4: '用户已撤销'
}
// 退款类型
const REFUND_TYPE = {
    1: '仅退款',
    2: '退货退款'
}
// 退货方式
const REFUND_METHOD = {
    1: '商家上门取货',
    2: '自行退货'
}

const numberReg = /^(0|[1-9]\d*)([\.][0-9]{1,2})?$/

// 退款审核弹窗
const RefundAuditModal = forwardRef((props, ref) => {
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [info, setInfo]= useState({
        type: 1, // 1:拒绝  2:同意
        id: 0,
        amount: 0,
    })

    useImperativeHandle(ref, () => ({
        show: (data) => {
            setInfo(data)
            setVisible(true)
        }
    }))

    const onClose = () => {
        form.resetFields();
        setVisible(false)
    }

    const onSubmit = async () => {
        try {
            const values = await form.validateFields()
            const res = await refundAuditSubmit({
                refund_id: info.id,
                status: info.type,
                ...values
            })
            message.success('提交成功')
            setVisible(false)
            typeof props.onOk === 'function' && props.onOk()
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <Modal
            open={visible}
            width={486}
            title={info.type === 0 ? '拒绝退款' : '同意退款'}
            className="custom-modal refund-modal"
            centered
            destroyOnClose
            onOk={onSubmit}
            onCancel={onClose}
        >
            <Form
                className="refund-modal-form"
                form={form}
                preserve={false}
                colon={false}
                style={{ '--label-width': info.type === 1 ? '76px' : '96px' }}
            >
                {(data) => (<>
                    {info.type === 1 && (<>
                        <Form.Item
                            name="reason"
                            label="拒绝原因"
                            rules={[{ required: true }]}
                        >
                            <Input.TextArea rows={2} />
                        </Form.Item>
                    </>)}
                    {info.type === 2 && (<>
                        <Form.Item label="申请退款金额">
                            <div className="refund-modal-amount">¥{info.amount}</div>
                        </Form.Item>
                        <Form.Item name="amount" label="减除扣款" rules={[{ validator: (_, v, cb) => {
                            if (v) {
                                if (!numberReg.test(v)) {
                                    return cb(new Error('请输入正整数或2位小数点的数字'))
                                } else if (+v > +info.amount) {
                                    return cb(new Error('减除扣款不可大于退款金额'))
                                }
                            }
                            cb()
                        } }]}>
                            <Input prefix="￥" type="number" />
                        </Form.Item>
                        <Form.Item name="reason" label="备注">
                            <Input.TextArea rows={2} />
                        </Form.Item>
                        <div className="flex-a-center refund-modal-footer">
                            实际退款金额<div className="refund-modal-amount" style={{ marginLeft: '12px' }}>¥{
                                parseFloat((+info.amount - Number(data.amount || '0')).toFixed(2))
                            }</div>
                        </div>
                    </>)}
                </>)}
            </Form>
        </Modal>
    )
})

const RefundList = () => {
    const [form] = Form.useForm()
    const [pageInfo, setPageInfo] = useState({
        page: 1,
        pagesize: 20,
    })
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const refundAuditRef = useRef(null)
    const columns = useMemo(() => {
        const onAuditShow = (record, type) => {
            refundAuditRef.current && refundAuditRef.current.show({
                type,
                id: record.id,
                amount: record.amount
            })
        }
        const col = [
            {
                title: '订单编号',
                dataIndex: 'order_no',
            },
            {
                title: '退款状态',
                dataIndex: 'status',
                render: (_) => REFUND_STATUS[_]
            },
            {
                title: '用户ID',
                dataIndex: 'user_id',
            },
            {
                title: '用户名称',
                dataIndex: 'username',
                render: (_, record) => record.user ? record.user.username : ''
            },
            {
                title: '手机号',
                dataIndex: 'mobile',
                render: (_, record) => record.user ? record.user.mobile : ''
            },
            {
                title: '退款类型',
                dataIndex: 'type',
                render: (_) => REFUND_TYPE[_]
            },
            {
                title: '退货原因',
                dataIndex: 'remark_type_text',
                width: 300,
            },
            {
                title: '备注',
                dataIndex: 'remark',
                width: 368,
                render: (_, record) => {
                    return (
                        <div className="flex align-center">
                            {record.images.length > 0 && <Image.PreviewGroup>
                                {record.images.map((t, i) => (<span key={i} className="flex-shrink-0" style={{ marginRight: '10px' }}>
                                    <Image
                                        width={64}
                                        height={64}
                                        src={t}
                                    />
                                </span>))}
                            </Image.PreviewGroup>}
                            <span style={{ textAlign: 'left' }}>{_}</span>
                        </div>
                    )
                }
            },
            {
                title: '退货商品',
                dataIndex: 'goods',
                width: 380,
                render: (_, record) => (
                    <div>
                        {record.items.map(t => (<div className="flex-center" key={t.id}>
                            <div className="ell1">{t.product_name}</div>
                            <span className="flex-shrink-0">&nbsp;*&nbsp;{t.number}</span>
                        </div>))}
                    </div>
                )
            },
            {
                title: '退货方式',
                dataIndex: 'return_method',
                render: (_) => REFUND_METHOD[_] || '-'
            },
            {
                title: '申请退款金额（¥）',
                dataIndex: 'amount',
            },
            {
                title: '实际退款金额（¥）',
                dataIndex: 'refund_amount',
            },
            {
                title: '商家备注',
                dataIndex: 'refusal_reason',
                width: 220
            },
            {
                title: '操作',
                fixed: 'right',
                dataIndex: 'oper',
                render: (_, record) => record.status === 1 ? (<div>
                    <span style={{ color: '#415AEE', cursor: 'pointer' }} onClick={() => onAuditShow(record, 1)}>拒绝</span>
                    <span style={{ color: '#415AEE', cursor: 'pointer', marginLeft: '20px' }} onClick={() => onAuditShow(record, 2)}>同意</span>
                </div>) : <span>-</span>
            },
        ].map(t => {
            t.align = 'center'
            !t.width && (t.width = 180)
            return t
        })

        return col
    }, [])

    useEffect(() => {
        getList()
    }, [pageInfo])


    // 搜索
    const onFinish = (values) => {
        onChange(1)
    }

    // 重置
    const onReset = () => {
        form.resetFields();
        onChange(1)
    }

    const onChange = (page) => {
        setPageInfo({
            ...pageInfo,
            page
        })
    }

    const getList = async() => {
        setLoading(true)
        try {
            const {dates, ...fData} = form.getFieldsValue(true)
            if (dates && dates.length > 0) {
                fData.start_time = dates[0].format('YYYY-MM-DD')
                fData.end_time = dates[1].format('YYYY-MM-DD')
            }
            const { data } = await getRefundList({
                ...pageInfo,
                ...fData
            })
            const list = data.data.map(t => {
                t.order_no = t.order.order_no
                delete t.order
                return t
            })
            setTotal(data.total)
            setList(list)
        } catch(e) {
            console.log(e)
        }
        setLoading(false)
    }

    return (
        <div className="refund-container">
            <Form className="search-form" form={form} layout="inline" onFinish={onFinish}>
                <Form.Item label="订单编号" name="order_no">
                    <Input />
                </Form.Item>
                <Form.Item label="用户ID" name="user_id">
                    <Input />
                </Form.Item>
                <Form.Item label="创建时间" name="dates">
                    <RangePicker />
                </Form.Item>
                <Form.Item label="退款状态" name="status">
                    <Select options={Object.entries(REFUND_STATUS).map(([key, value]) => ({ value: key, label: value }))} allowClear />
                </Form.Item>
                <Form.Item label="退款类型" name="type">
                    <Select options={Object.entries(REFUND_TYPE).map(([key, value]) => ({ value: key, label: value }))} allowClear />
                </Form.Item>
                <Form.Item style={{ marginRight: 0, width: '100%' }}>
                    <Button type="primary" htmlType="submit">搜索</Button>
                    <Button
                        type="link"
                        htmlType="button"
                        style={{ marginLeft: '5px' }}
                        onClick={onReset}
                    >重置</Button>
                </Form.Item>
            </Form>
            {/* {list.length > 0 && <div className="flex flex-end" style={{ marginBottom: '16px' }}>
                <Button type="primary">导出Excel</Button>
            </div>} */}
            <Table
                rowKey="id"
                columns={columns}
                dataSource={list}
                loading={loading}
                bordered
                scroll={{
                    x: 'max-content',
                    scrollToFirstRowOnChange: true,
                }}
                pagination={{
                    total,
                    current: pageInfo.page,
                    defaultPageSize: pageInfo.pagesize,
                    showSizeChanger: false,
                    showQuickJumper: true,
                    position: ["bottomCenter"],
                    showTotal: (total) => `共${total}条`,
                    onChange: onChange,
                }}
            />
            <RefundAuditModal ref={refundAuditRef} onOk={getList} />
        </div>
    )
}

export default RefundList