import React from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Radio,
  DatePicker,
  Message,
  Space,
  InputNumber,
  List,
} from "antd";
import { ADD_COUPON, COUPON_INFO } from "@/graphql/coupon";
import client from "@/graphql/apollo-config";
import ProductSelector from "@/components/productSelector";
import AddressSelect from "@/components/addressSelect";
import qs from "query-string";
import moment from "moment";
import UserSelector from "@/components/userSelector";
import Cookies from "js-cookie";

const canUserType = [
  {
    label: "新用户",
    value: 1,
  },
  {
    label: "全部用户",
    value: 2,
  },
  {
    label: "购买指定商品的用户",
    value: 3,
  },
  {
    label: "指定用户",
    value: 4,
  },

  {
    label: "进店领取",
    value: 7,
  },
  {
    label: "视频红包",
    value: 8,
  },
  {
    label: "成功拉新的用户(用户每邀请新用户下单完成都可获得红包券)",
    value: 5,
  },
  {
    label: "商家结算典金计划时平台自动发券",
    value: 6,
  },
  {
    label: "加入社群券",
    value: 9,
  },
  {
    label: "商家入驻券",
    value: 10,
  },
  {
    label: "用户首次在平台线下支付成功后发放",
    value: 11,
  },
];

const applicationSideType = [
  {
    label: "APP+小程序可用",
    value: 1,
  },
  {
    label: "仅APP可用",
    value: 2,
  },
];

const shopType = [
  {
    label: "全部",
    value: 1,
  },
  {
    label: "红包券商城商品",
    value: 2,
  },
  {
    label: "特推商城商品",
    value: 3,
  },
  {
    label: "特定商品",
    value: 4,
  },
  {
    label: "官方商城商品可用+其他商家高佣商品可用",
    value: 5,
  },
  {
    label: "树懒生活线下堂食",
    value: 6,
  },
];

const countNumberType = [
  {
    label: "有限制",
    value: 0,
  },
  {
    label: "无限制",
    value: 1,
  },
];

const effectTimeType = [
  {
    label: "立即生效",
    value: 1,
  },
  {
    label: "",
    value: 2,
  },
];

const couponType = [
  {
    label: "满减券",
    value: 1,
  },
  {
    label: "折扣券",
    value: 2,
  },
];

const MAX_LENGTH = {
  name: 15,
  remark: 50,
};

const maxMoneyType = [
  {
    label: "有限制",
    value: 1,
  },
  {
    label: "无限制",
    value: 0,
  },
];

const extraType = [
  {
    label: "全部可领",
    value: 1,
  },
  {
    label: "特定城市区域",
    value: 2,
  },
  {
    label: "商家周边",
    value: 3,
  },
];

const AddCoupon = (props) => {
  const { history } = props;
  const { id } = qs.parse(history.location.search);
  const [countType, setCountType] = React.useState(0);
  const [effectTime, setEffectTime] = React.useState(1);
  const [effectiveDate, seteffectiveDate] = React.useState(0);

  const [form] = Form.useForm();
  const onSubmit = async (values) => {
    const { grantTime, max_num, money, ...extValues } = values;
    if (effectTime === 2) {
      if (!+effectiveDate) {
        Message.info("生效时间不能为0和空");
        return;
      }
    }
    if (countType === countNumberType[0].value) {
      if (!max_num) {
        Message.info("请输入发放数量");
        return;
      }
    }
    const { data } = await client.mutate({
      mutation: ADD_COUPON,
      variables: {
        ...extValues,
        is_spend: 0,
        start_time: grantTime[0].startOf("day").unix(),
        end_time: grantTime[1].endOf("day").unix(),
        effective_date: effectiveDate || 0,
        max_num: countType === countNumberType[0].value ? max_num : 0,
        money: extValues.type === 2 ? money * 10 : money,
      },
    });
    if (data.addStoreCoupon) {
      Message.success("提交成功");
      setTimeout(() => {
        history.go(-1);
      }, 2000);
    }
  };

  React.useEffect(() => {
    if (id) {
      client
        .query({
          query: COUPON_INFO,
          variables: {
            id: +id,
          },
        })
        .then((res) => {
          const { start_time, end_time, effective_date, money, ...ext } =
            res.data.storeCouponInfo;
          seteffectiveDate(effective_date);
          form.setFieldsValue({
            ...ext,
            grantTime: [moment(start_time * 1000), moment(end_time * 1000)],
            money: ext.type === 2 ? money / 10 : money,
          });
        });
    }
  }, [form, id]);

  return (
    <div className="page-wrapper">
      <Form form={form} onFinish={onSubmit} autoComplete="off" disabled={!!id}>
        {(data) => {
          return (
            <>
              <Form.Item
                label="红包券名称"
                required
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder={`请输入红包券名称，最多${MAX_LENGTH.name}个字`}
                  maxLength={MAX_LENGTH.name}
                />
              </Form.Item>
              <Form.Item label="领取条件" name="usable_user" initialValue={1}>
                <Radio.Group>
                  {canUserType
                    .filter(
                      (_, index) => Cookies.get("is_spend") == 1 || index < 5
                    )
                    .map((item) => (
                      <Radio value={item.value} key={item.label}>
                        {item.label}
                      </Radio>
                    ))}
                </Radio.Group>
              </Form.Item>

              {data.usable_user === 3 && (
                <Form.Item
                  label="选择商品"
                  name="specify_product"
                  initialValue={[]}
                  required
                  rules={[
                    {
                      required: true,
                      message: "请选择商品",
                    },
                  ]}
                >
                  <ProductSelector />
                </Form.Item>
              )}
              {data.usable_user === 4 && (
                <Form.Item
                  label="选择用户"
                  name="specify_user"
                  initialValue={[]}
                  required
                  rules={[
                    {
                      required: true,
                      message: "请选择用户",
                    },
                  ]}
                >
                  <UserSelector mode="multiple" />
                </Form.Item>
              )}
              <Form.Item label="可领区域" name="receive_area" initialValue={1}>
                <Radio.Group>
                  {extraType.map((item) => (
                    <Radio value={item.value} key={item.label}>
                      {item.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              {data.receive_area === 2 && (
                <Form.Item
                  label=" "
                  colon={false}
                  name="specify_area"
                  required
                  rules={[
                    {
                      required: true,
                      message: "请选择区域",
                    },
                  ]}
                >
                  <AddressSelect />
                </Form.Item>
              )}
              {data.receive_area === 3 && (
                <Form.Item label=" " colon={false}>
                  <Space>
                    <Form.Item
                      noStyle
                      name="nearby_area"
                      required
                      rules={[
                        {
                          required: true,
                          message: "请输入公里数",
                        },
                        {
                          validator: (_, v, cb) => {
                            if (v.split(".")[1]) {
                              return Promise.reject(
                                new Error("最大抵扣金额必须为整数")
                              );
                            }
                            cb();
                          },
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: 50,
                        }}
                        type="number"
                      />
                    </Form.Item>
                    <span>公里</span>
                  </Space>
                </Form.Item>
              )}
              <Form.Item label="可用端" name="usable_side" initialValue={1}>
                <Radio.Group>
                  {applicationSideType.map((item) => (
                    <Radio value={item.value} key={item.label}>
                      {item.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="可用商品类型"
                name="usable_shop"
                initialValue={1}
              >
                <Radio.Group>
                  {shopType
                    .filter((item) => {
                      if (item.value === 6) {
                        return Cookies.get('enterprise_id') && Cookies.get('enterprise_id') != 0
                      } else {
                        return Cookies.get("is_spend") == 1
                          ? true
                          : ![2, 3, 5].includes(item.value)
                      }
                    })
                    .map((item) => (
                      <Radio value={item.value} key={item.label}>
                        {item.label}
                      </Radio>
                    ))}
                </Radio.Group>
              </Form.Item>
              {(data.usable_shop === 6 && data.specify_product?.length > 0) && (
                <Form.Item label="商品">
                  <List>
                    {data.specify_product.map((t) => (
                      <List.Item
                        key={t.id}
                        style={{
                          flexDirection: 'column',
                          alignItems: 'start',
                          justifyContent: 'start',
                          padding: '5px 0',
                        }}
                      >
                        <div>商品ID：{t.id}</div>
                        <div>商品名称：{t.name}</div>
                      </List.Item>
                    ))}
                  </List>
                </Form.Item>
              )}
              {data.usable_shop === 4 && (
                <Form.Item
                  label="选择商品"
                  name="limit_product"
                  initialValue={[]}
                  required
                  rules={[
                    {
                      required: true,
                      message: "请选择商品",
                    },
                  ]}
                >
                  <ProductSelector />
                </Form.Item>
              )}

              <Form.Item label="类型" name="type" initialValue={1}>
                <Radio.Group
                  onChange={() =>
                    form.setFieldsValue({
                      ...form.getFieldValue(),
                      money: 0,
                    })
                  }
                >
                  {couponType.map((item) => (
                    <Radio value={item.value} key={item.label}>
                      {item.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              {data.type === 2 && (
                <>
                  <Form.Item
                    label="最大抵扣金额"
                    name="is_max_money"
                    initialValue={1}
                  >
                    <Radio.Group>
                      {maxMoneyType.map((item) => (
                        <Radio value={item.value} key={item.label}>
                          {item.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  {data.is_max_money === 1 && (
                    <Form.Item
                      label=" "
                      name="max_money"
                      required={false}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: "请输入最大抵扣金额",
                        },
                      ]}
                      getValueFromEvent={(event) => {
                        return +event.target.value ? +event.target.value : "";
                      }}
                    >
                      <Input type="number" placeholder="请输入金额" />
                    </Form.Item>
                  )}
                </>
              )}
              {data.type === 1 ? (
                <Form.Item
                  label="红包券金额"
                  required
                  name="money"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator(_, v, cb) {
                        if (v <= 0) {
                          return Promise.reject(new Error("红包券金额需大于0"));
                        }
                        cb();
                      },
                    },
                  ]}
                >
                  <Input type="number" placeholder={`请输入红包券金额`} />
                </Form.Item>
              ) : (
                <Form.Item
                  label="折扣比例"
                  required
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  extra="请输入0-10内的折扣比例"
                >
                  <Space>
                    <Form.Item
                      noStyle
                      name="money"
                      initialValue={0}
                      rules={[
                        {
                          validator(_, v, cb) {
                            const decimalLength =
                              `${v}`.split(".")[1]?.length || 0;
                            if (decimalLength > 1) {
                              return Promise.reject(
                                new Error("小数不能超过2位，请重新输入")
                              );
                            }
                            cb();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: 50,
                        }}
                        min={0}
                        max={10}
                      />
                    </Form.Item>
                    <span>折</span>
                  </Space>
                </Form.Item>
              )}

              <Form.Item label="使用门槛" extra="满0可用既无门槛" required>
                <Space>
                  <span>满</span>
                  <Form.Item
                    noStyle
                    name="full"
                    initialValue={0}
                    rules={[
                      {
                        required: true,
                        message: "请输入使用门槛金额",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: 50,
                      }}
                      type="number"
                    />
                  </Form.Item>
                  <span>元可用</span>
                </Space>
              </Form.Item>

              <div
                style={{
                  display: "flex",
                }}
              >
                <Form.Item
                  label="有效周期"
                  required
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator(_, v, cb) {
                        if (v <= 0) {
                          return Promise.reject(new Error("有效周期需大于0"));
                        }
                        cb();
                      },
                    },
                  ]}
                  name="validity"
                >
                  <Input
                    type="number"
                    style={{
                      width: 50,
                      marginRight: 10,
                    }}
                  />
                </Form.Item>
                <div
                  style={{
                    lineHeight: "35px",
                  }}
                >
                  天内有效
                </div>
              </div>

              <Form.Item
                label="生效时间"
                name="is_effective"
                initialValue={effectTime}
              >
                <Radio.Group
                  onChange={(v) => {
                    setEffectTime(v.target.value);
                  }}
                >
                  {effectTimeType.map((item) => (
                    <Radio value={item.value} key={item.label}>
                      {item.label ? (
                        item.label
                      ) : (
                        <Form.Item noStyle>
                          领券后
                          <Input
                            type="number"
                            onChange={({ target: { value } }) => {
                              seteffectiveDate(value);
                            }}
                            value={effectiveDate}
                            style={{
                              width: 50,
                              margin: "0 5px",
                              border: "none",
                              borderBottom: "1px solid #d9d9d9",
                            }}
                            disabled={effectTime === 1}
                          />
                          天开始生效
                        </Form.Item>
                      )}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="发放数量"
                style={{
                  width: 300,
                }}
                name="is_limitless"
                initialValue={0}
              >
                <Select onChange={(v) => setCountType(v)}>
                  {countNumberType.map((item) => (
                    <Select.Option value={item.value} key={item.label}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label=" "
                colon={false}
                style={{
                  width: 300,
                }}
                name={countType === countNumberType[0].value ? "max_num" : null}
                noStyle={countType !== countNumberType[0].value}
              >
                <Input
                  placeholder="请输入发放数量"
                  type={
                    countType === countNumberType[0].value ? "number" : "hidden"
                  }
                  min={1}
                />
              </Form.Item>
              <Form.Item
                name="grantTime"
                label="发放周期"
                required
                rules={[
                  {
                    required: true,
                    message: "请选择发放周期",
                  },
                ]}
              >
                <DatePicker.RangePicker />
              </Form.Item>
              <Form.Item label="备注信息" name="remake">
                <Input.TextArea
                  placeholder={`最多${MAX_LENGTH.remark}个字`}
                  maxLength={MAX_LENGTH.remark}
                />
              </Form.Item>
              {!id && (
                <Form.Item
                  className="page-wrapper-bottom"
                  label=" "
                  colon={false}
                >
                  <Button
                    onClick={() => window.history.back()}
                    style={{
                      marginRight: 10,
                    }}
                  >
                    取消
                  </Button>
                  <Button type="primary" htmlType="submit">
                    提交
                  </Button>
                </Form.Item>
              )}
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default AddCoupon;
