import http from '../request'

// 订单状态描述
export const ORDER_STATUS = {
    0: '未支付',
    1: '已完成',
    2: '已下单',
    3: '已关闭',
    4: '配送中',
    5: '退款中',
    6: '已退款',
    7: '商家取消',
    8: '骑手已接单',
    9: '骑手已到店',
    10: '骑手已送达',
    11: '配送异常',
    71: '配送方取消',
    72: '骑手取消',
}

// 退款 remark_type 描述
export const REMARK_TYPE_STATUS = {
    11: '商家发起退款',
    12: '骑手发起退款',
    22: '配送方发起退款'
}

// 订单是否进行中
export const isOrderInProgress = (status) => [2, 4, 8, 9, 10, 11].includes(+status)

// 订单是否已取消
export const isOrderCanceled = (status) => [3, 7, 71, 72].includes(+status)

// 订单列表
export const getOrderList = (params) => http.get('/order/index', params)

// 堂食订单列表
export const getInOrderList = (params) => http.get('/order/in-order', params)

// 订单详情
export const getOrderDetail = (id) => http.get(`/order/${id}/show`)

// 堂食订单详情
export const getInOrderDetail = (id) => http.get(`/order/${id}/in-order-show`)

// 出餐
export const diningOut = (id) => http.post('/order/completed', { id })

// 商家退款
export const refundSubmit = (params) => http.post('/order/refund-apple', params)

// 订单备注
export const noteSubmit = (params) => http.post('/order/note', params)

// 退款列表
export const getRefundList = (params) => http.get('/order/refund-list', params)

// 退款审核
export const refundAuditSubmit = (params) => http.post('/order/refund-audit', params)

// 设置打包费（按商品）
export const setGoodsPackage = (params) => http.post('/order/setting-goods-package', params)

// 获取打包费参数信息
export const getPackage = () => http.get('/order/package')

// 设置打包费参数
export const setPackage = (params) => http.post('/order/setting-package', params)

// 配送订单增加物流公司
export const setExpressNo = (params) => http.post('/order/send-good', params)

// 拆单
export const setSeparateUpdate = (params) => http.post('/order/separate-update', params)

// 合单
export const setMergeUpdate = (params) => http.post('/order/merge-update', params)