import React from 'react';

const IndexCard = (props) => {
  const { topTitle,topTip, firstTitle, secondTitle, firstMoney, secondMoney } = props

  return <div className="finace-wrapper">
    <p className="finace-title">{topTitle}</p>
    <p className="finace-tip">{topTip}</p>
    <div className="finace-top">
          <span className='flex-span'>
            <div className='finace-card'>
              <p>{firstTitle}</p>
              <h2><span>¥</span>{firstMoney}</h2>
            </div>
          </span>
      <span className='flex-span'>
            <div className='finace-card'>
              <p>{secondTitle}</p>
              <h2><span>¥</span>{secondMoney}</h2>
            </div>
          </span>
    </div>
  </div>
}

export default IndexCard