import React,{ useState,useEffect } from 'react';
import { Switch, Input, Select, Message } from 'antd';
import {MY_CONFIG, PAGE} from "../../../graphql/login";
import "./index.less";
import client from "@/graphql/apollo-config";

const Explain = (props) => {
  const [noClick, setClick] = useState(false)
  const [content, setContent] = useState('')
  const [time, setTime] = useState(5)
  const [n, setName] = useState('')
  let name = ''

  useEffect(() => {
    if (props.location.search.indexOf("name") == -1){
      name = props.location.search.slice(4)
    }else{
      name = props.location.search.slice(6)
    }
    setName(name)
    init(name);
    if (name == 'hongbao_agreement'){
      let timer = 5;
      let t = setInterval(() => {
        timer--;
        setTime(timer);
        if (timer <= 0 ) {
          clearInterval(t);
          setTime(0);
          t = null;
          setClick(true)
        } else {
          setTime(timer)
        }
      }, 1000);
      return (()=>{
        clearTimeout(t)
      })
    }
  }, []);

  const init = async(name) =>{
    console.log(props.location.search.slice(6),1111)
    if (props.location.search.indexOf("name") == -1){
      try {
        const { data } = await client.query({
          query: PAGE,
          variables:{
            id:name
          },
          fetchPolicy: "no-cache"
        });
        if (data){
          setContent(data.page.content)
        }
      } catch (error) {}
    } else{
      try {
        const { data } = await client.query({
          query: MY_CONFIG,
          variables:{
            name:name
          },
          fetchPolicy: "no-cache"
        });
        if (data){
          setContent(data.myConfig)
        }
      } catch (error) {}
    }
  }

  const confirm = async() =>{
    if (noClick){
      props.history.push('/index/hongbaoset')
      // try {
      //   const { data } = await client.query({
      //     query: ACTIVITY,
      //     fetchPolicy: "no-cache"
      //   });
      //   if (data.hongbaoEnroll){
      //     Message.info('签约成功，返红包活动已开启')
      //     props.history.push('/')
      //   }
      // } catch (error) {}
    }
  }

  return <div className='explain-wrapper'>
    <div dangerouslySetInnerHTML = {{ __html: content }} />
    {
      n == 'hongbao_agreement' && <div className='btn-wrapper'>
        <div onClick={()=>confirm()} className='btn' style={{backgroundColor:noClick?'#F4981D':'#F5D09F'}}>
          已阅读,确认报名{time > 0 &&'（' + time +')s'}
        </div>
      </div>
    }
  </div>
};


export default Explain;
