import React, { useEffect, useMemo, useState } from "react";
import { Table, Avatar, Spin } from "antd";
import { getColumnDataProps, getColumnSearchProps } from "../order/common";
import { useLazyQuery } from "react-apollo";
import { INVITATION_USER } from "@/graphql/store";
import moment from "moment";
import avater from "@/assets/images/avater.png";
import {Get_StoreCommissions_Query} from "../../graphql/products";
import client from "../../graphql/apollo-config";

const Invitation =()=> {
  const [sendVal, setVal] = useState({
    username: null,
    start_time: null,
    end_time: null,
    is_direct: null,
    limit: 10,
    page: 1
  })
  const [myData, setData] = useState({})
  const [current, setCurrent] = useState('')
  const [total, setTotal] = useState('')

  //获取数据
  const getData = async () => {
    try {
      const { data } = await client.query({
        query: INVITATION_USER,
        variables: sendVal,
        fetchPolicy: "no-cache"
      });
      if (data){
        data.childUser.data.map((i) => {
          i.key = i.id + ' '
          return i
        })
        setData(data.childUser)
        setCurrent(data.childUser.current_page)
        setTotal(data.childUser.total)
      }
    } catch (error) { }
  }

  //工具函数
  const getTime = (time) => {
    return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss");
  };

  // 统一重置
  const handleReset = (clearFilters, col) => {
    clearFilters();
    setVal({
      ...sendVal,
      username: null,
      start_time: null,
      end_time: null,
      is_direct:null,
      page: 1
    })
  };

  // 搜索用户名
  const handleSearch = (selectedKeys, confirm, col) => {
    confirm();
    setVal({
      ...sendVal,
      username: selectedKeys[0],
      page: 1
    })
  };

  // 时间
  const handleDate = (
    value,
    dateString,
    col,
    confirm,
  ) => {
    confirm();
    setVal({
      ...sendVal,
      start_time: parseInt(Math.floor(value[0].valueOf() / 1000)),
      end_time: parseInt(Math.floor(value[1].valueOf() / 1000)),
      page: 1
    })
  };

  useEffect(() => {
    getData();
  }, [sendVal]);

  const pageChange = (e) => {
    setVal({
      ...sendVal,
      page: e
    })
  };

  const getColumnStatusProps = (dataIndex) => {
    let filters
    switch (dataIndex) {
      case 'is_direct':
        filters =[
            { text: '否', value: 0 },
            { text: '是', value: 1 }
          ]
        break;
      default:
        break
    }
    return {
      filters: filters,
      filterMultiple: false
    }
  }

  const renderAvatar = (avatarurl) => {
    return <Avatar src={avatarurl ? avatarurl.url : avater}></Avatar>;
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (extra.action == "paginate") return
    if (filters.is_direct !== null) {
      setVal({
        ...sendVal,
        is_direct: Number(filters.is_direct[0]),
        page: 1
      })
    }else{
      setVal({
        ...sendVal,
        is_direct: 0,
        page: 1
      })
    }
  }

  const columns = [
    {
      title: "头像",
      dataIndex: "avatar",
      key: "avatar",
      align: "center",
      render: renderAvatar,
    },
    {
      title: "公域流量",
      dataIndex: "public",
      key: "public",
      align: "center",
      render: (()=>{
        return '是'
      }),
    },
    {
      title: "私域流量",
      dataIndex: "is_direct",
      key: "is_direct",
      align: "center",
      render: ((e)=>{
        return e==0?'否':'是'
      }),
      ...getColumnStatusProps('is_direct'),
    },
    {
      title: "昵称",
      dataIndex: "username",
      key: "username",
      align: "center",
      ...getColumnSearchProps({ title: "昵称" }, handleSearch, handleReset),
    },
    {
      title: "邀请时间",
      dataIndex: "bind_parent_time",
      key: "bind_parent_time",
      align: "center",
      ...getColumnDataProps("邀请时间", handleReset, handleDate),
      render: getTime,
    },
  ];
  return (
    <div>
      <Table
        dataSource={myData.data}
        bordered={true}
        columns={columns}
        pagination={{
          total: total,
          current: current,
          onChange: pageChange,
        }}
        onChange={(pagination, filters, sorter, extra) => onChange(pagination, filters, sorter, extra)}/>
    </div>
  );
}

export default Invitation;
