import qs from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { Table, Button, Form, Input, DatePicker, Select } from 'antd'
import { getCouponUsedDetail } from '@/apis/mealCoupon'
import { formatAmount, toExportExcel } from '@/utils'
import './index.less'

const { Option } = Select;
const { RangePicker } = DatePicker;

const typeOptions = [
    { id: 0, name: '普通物流订单' },
    { id: 1, name: '外卖订单' },
    { id: 2, name: '扫码点餐订单' },
]

const CouponRecordDetail = ({ location }) => {
    const { store_id } = qs.parse(location.search)
    const [pageInfo, setPageInfo] = useState({
        page: 1,
        pagesize: 10,
    })
    const [total, setTotal] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const columns = useMemo(() => {
        const col = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 100
            },
            {
                title: '用户ID',
                dataIndex: 'user_id',
                width: 100
            },
            {
                title: '用户账号',
                dataIndex: 'mobile'
            },
            {
                title: '使用餐券金额',
                dataIndex: 'amount',
                render: (text, record, index) => `-${text}`
            },
            {
                title: '订单金额',
                dataIndex: 'total',
                render: (text, record, index) => `${text || '-'}`
            },
            {
                title: '订单编号',
                dataIndex: 'order_no'
            },
            {
                title: '订单类型',
                dataIndex: 'order_type_name'
            },
            {
                title: '使用时间',
                dataIndex: 'created_at'
            },
            {
                title: '抵券商家',
                dataIndex: 'f_short_name'
            },
        ].map(t => {
            !t.width && (t.width = 180)
            return t
        })

        return col
    }, [])

    useEffect(() => {
        getList()
    }, [pageInfo])

    // 搜索
    const onFinish = (values) => {
        onChange(1)
    }

    // 重置
    const onReset = () => {
        form.resetFields();
        onChange(1)
    }

    // page change
    const onChange = (page) => {
        setPageInfo({
            ...pageInfo,
            page
        })
    }

    // 获取列表数据
    const getList = async() => {
        setLoading(true)
        try {
            const {dates, ...fData} = form.getFieldsValue(true)
            if (dates && dates.length > 0) {
                fData.start_time = dates[0].format('YYYY-MM-DD')
                fData.end_time = dates[1].format('YYYY-MM-DD')
            }
            const { data } = await getCouponUsedDetail({
                store_id: store_id,
                ...pageInfo,
                ...fData
            })
            setTotal(data.total)
            setTotalAmount(data.total_amount)
            setList(data.data)
        } catch(e) {
            console.log(e)
        }
        setLoading(false)
    }

    // 导出Excel
    const onExportExcel = async() => {
        if (loading) return;
        setLoading(true)
        try {
            const {dates, ...fData} = form.getFieldsValue(true)
            if (dates && dates.length > 0) {
                fData.start_time = dates[0].format('YYYY-MM-DD')
                fData.end_time = dates[1].format('YYYY-MM-DD')
            }
            const { data } = await getCouponUsedDetail({
                store_id: store_id,
                page: 1,
                pagesize: total,
                ...fData
            })
            const list = data.data.map(t => {
                t.amount = `-${t.amount}`
                t.total = t.total || '-'
                return t
            })
            toExportExcel({
                fileName: `本店餐券使用详情${Date.now()}`,
                data: list,
                header: columns.map(t => t.title),
                filter: columns.map(t => t.dataIndex)
            })
        } catch(e) {
            console.log(e)
        }
        setLoading(false)
    }

    return (
        <div className="mealCoupon-form">
            <Form form={form} layout="inline" onFinish={onFinish}>
                <Form.Item label="用户ID" name="user_id">
                    <Input />
                </Form.Item>
                <Form.Item label="用户账号" name="mobile">
                    <Input />
                </Form.Item>
                <Form.Item label="订单编号" name="order_no">
                    <Input />
                </Form.Item>
                <Form.Item label="订单类型" name="order_type">
                    <Select placeholder="请选择">
                        {typeOptions.map((item) => (
                            <Option value={item.id} key={item.id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="抵券商家" name="store_name">
                    <Input />
                </Form.Item>
                <Form.Item label="发放时间" name="dates">
                    <RangePicker />
                </Form.Item>
                <Form.Item style={{ marginRight: 0, width: '100%' }}>
                    <Button type="primary" htmlType="submit">搜索</Button>
                    <Button
                        type="link"
                        htmlType="button"
                        style={{ marginLeft: '5px' }}
                        onClick={onReset}
                    >重置</Button>
                    <Button
                        type="primary"
                        htmlType="button"
                        style={{ float: 'right' }}
                        onClick={onExportExcel}
                    >导出Excel</Button>
                </Form.Item>
            </Form>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={list}
                loading={loading}
                bordered
                scroll={{
                    x: 'max-content',
                    scrollToFirstRowOnChange: true,
                }}
                pagination={{
                    total,
                    current: pageInfo.page,
                    defaultPageSize: pageInfo.pagesize,
                    showSizeChanger: false,
                    showQuickJumper: true,
                    position: ["bottomCenter"],
                    showTotal: (total) => `共${total}条`,
                    onChange: onChange,
                }}
            />
            {list.length > 0 && <div className="mealCoupon-form-footer">合计使用券¥{formatAmount(totalAmount)}</div>}
        </div>
    )
}

export default CouponRecordDetail