import { Button, Upload, Modal, Input, Message } from 'antd';
import React, { useState, useReducer, useRef, useEffect, useMemo } from 'react';
import { OSS } from "../../../graphql/store";
import { BIND_BANK_CARD, BANK_CARD } from "../../../graphql/finance";
import { useQuery, useMutation } from "react-apollo";
import { operateImg } from "../../../utils/index"
import "./index.less";
import app from "../../../app.json";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
// emoji
const emojiRule = /[^\u4E00-\u9FA5|\d|\a-zA-Z|\r\n\s,.?!，。？！…—&$=()-+/*{}[\]]|\s/g

const initState = {
  bank_account: '',
  bank_name: '',
  bank_account_name: '',
}

const connectReducer = (state, { type, payload }) => {
  switch (type) {
    case 'upDateAll' :
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}

const Connect = ({ history }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([])
  const [state, dispatch] = useReducer(connectReducer, initState)
  const {
    bank_account,
    bank_name,
    bank_account_name
  } = state

  const { data } = useQuery(OSS, {
    fetchPolicy: 'network-only'
  })

  const { data: bcData } = useQuery(BANK_CARD, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res && res.bankCard && res.bankCard.bank_account) {
        dispatch({
          type: 'upDateAll', 
          payload: {
            bank_account: res.bankCard.bank_account,
            bank_name: res.bankCard.bank_name,
            bank_account_name: res.bankCard.bank_account_name,
          }
        })
        if (res.bankCard.image.length > 0) {
          setFileList(res.bankCard.image.map(i => ({
            ...i,
            name: new Date().getTime(),
            uid: new Date().getTime(),
          })))
        }
        
      }
    },
      onError:() =>{}
  })
  const isBd = useMemo(() => {
    return bcData && bcData.bankCard.bank_account
  }, [bcData])

  const getImage = (fileList) => {
    return fileList.map(i => i.url)
  }

  const [bindBankCard] = useMutation(BIND_BANK_CARD, {
    variables: {
      image: getImage(fileList),
      bank_account,
      bank_name,
      bank_account_name,
    },
    onCompleted: () => {
      Message.success('提交成功')
      history.push("/")
    },
      onError:() =>{}
  })
  const handleCancel = () => {
    setPreviewVisible(false)
  };
  const handlePreview = async (file)=> {
    if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
    }
    let url = ''
    if (file.url.indexOf(`${app.oss_host}`) != -1){
      url = file.url
    }else{
      url = `${app.oss_host}` + file.url
    }
    console.log(url,11111)
    setPreviewImage(url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  };
  const handleChange = ({ fileList }) => {
    console.log(fileList[0])
    if (fileList[0] && fileList[0].status !== 'removed'){
      const isLt2M = fileList[0].size / 1024 / 1024 < 1

      if (!isLt2M && fileList[0].type.indexOf("image") != -1) {
        Message.error('图片大小不能超过1m');
        return
      }
    }
    const newFileList = fileList && fileList.map(i => ({
      ...i,
      url: data.oss.dir + i.uid + i.name
    }))
    setFileList([
      ...newFileList
    ])
  };
  const getExtraData = (file) => {
    const OSSData = data && data.oss
    return {
      key: OSSData.dir + file.uid + file.name,
      OSSAccessKeyId: OSSData.accessid,
      policy: OSSData.policy,
      Signature: OSSData.signature,
    };
  };


  const rule1 = [
    {
      rule: getImage(fileList).length < 1,
      title: "请上传银行卡图片"
    },
    {
      rule: bank_account == '',
      title: "请填写开户银行卡号"
    },
    {
      rule: !/^[0-9]{10,20}$/.test(bank_account),
      title: "请填写正确的开户银行卡号"
    },
    {
      rule: bank_name == '',
      title: "请填写开户银行卡姓名"
    },
    {
      rule: emojiRule.test(bank_name),
      title: "请填写正确的开户银行卡姓名"
    },
    {
      rule: bank_account_name == '',
      title: "请填写银行卡开户姓名"
    },
    {
      rule: emojiRule.test(bank_account_name),
      title: "请填写正确的开户银行卡开户姓名"
    },
  ]

  const submit = () => {
    if (!verification(rule1)) return
    bindBankCard()
  }

  const verification = (rule) => {
    for(let item of rule) {
        if (item.rule) {
          Message.info(item.title)
          return false
        }
    }
    return true
  }
  return <div className='connect'>
    <h1>绑定收款银行信息</h1>
    <div className='connect-card'>
      <div className='connect-card_list'>
        <p style={{marginRight:56}}>上传银行卡正面照片
          <i className="red">*</i>
        </p>
        <div className="connect-upload-wrapper">
          {
            fileList.length < 1 && <div className="upload-img">
              <i className="iconfont">&#xe717;</i>
            </div>
          }
          <Upload
            listType="picture-card"
            action={`https://${data && data.oss.host}`}
            fileList={fileList}
            onPreview={(file) => handlePreview(file)}
            onChange={handleChange}
            data={(file) => getExtraData(file)}
          >
            {fileList.length >= 1 || isBd ? null :<Button className="upload-btn">选择文件</Button>}
          </Upload>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="cool" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </div>
      </div>
      <div className='connect-card_list'>
        <p style={{marginRight:68}}>填写开户银行卡号
          <i className="red">*</i>
        </p>
        <div>
          <Input
            style={{width:732}}
            placeholder="填写开户银行卡号"
            value={bank_account}
            disabled={isBd}
            onChange={e => dispatch({type: 'upDateAll', payload: {bank_account: e.target.value}})}
          />
        </div>
      </div>
      <div className='connect-card_list'>
        <p style={{marginRight:94}}>填写开户银行和<br/>支行名称
          <i className="red">*</i>
        </p>
        <div>
          <Input
            style={{width:732}}
            placeholder="如XX银行XX市分行XX支行"
            value={bank_name}
            disabled={isBd}
            onChange={e => dispatch({type: 'upDateAll', payload: {bank_name: e.target.value}})}
          />
        </div>
      </div>
      <div className='connect-card_list'>
        <p style={{marginRight:52}}>填写银行卡开户姓名
          <i className="red">*</i>
        </p>
        <div>
          <Input
            style={{width:732}}
            placeholder="填写银行卡开户姓名"
            value={bank_account_name}
            disabled={isBd}
            onChange={e => dispatch({type: 'upDateAll', payload: {bank_account_name: e.target.value}})}
          />
        </div>
      </div>
      {
        !isBd &&  <div className='connect-button' onClick={() => submit()} >
          提交
        </div>
      }
    </div>
  </div>
}

export default Connect