import React from 'react';
import Block from './block';
// import { ActivityIndicator } from 'antd';

const Contact = () => {
    return (
        <Block>
            <div style={style.wrapper}>
                <div style={style.top}>联系客服</div>
                <div style={style.middle}>手机：16675114227</div>
                    <div style={style.middle}>微信：Ffxl-68</div>
                    {/* <div style={style.bottom}>邮箱：654004149@qq.com</div> */}
            </div>
        </Block>
    );
};


const style = {
    wrapper:{
        color: '#333333',
        fontWeight: 'bold',
    },
    top:{
        fontSize: '18px',
        

    },
    middle:{
        fontSize: '16px',
        fontWeight: 400,
        marginTop: '11px',
    },
    bottom:{
        fontSize: '16px',
        fontWeight: 400,
        marginTop: '11px',
    }
}

export default Contact;
