/* eslint-disable eqeqeq */
import moment from "moment";
import React from "react";
import { Button, Input, Space, DatePicker, InputNumber } from "antd";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
// 处理订单状态
export function getStatus(status, product_type, delivery_type) {
  console.log(delivery_type);
  switch (status) {
    case 0:
      return "待支付";
    case 1:
      return "已完成";
    case 2:
      if (product_type == 0) {
        if (delivery_type === 3) {
          return "备餐中";
        } else {
          return "等待配送";
        }
      }
      return "待使用";
    case 3:
      return "已关闭";
    case 4:
      return "配送中";
    case 5:
      return "退款中";
    case 6:
      return "备餐中";
    default:
      return "-";
  }
}

// 处理售后状态
export function getServiceStatus(service_status) {
  switch (service_status) {
    case 1:
      return "已退款";
    case 2:
      return "已部分退款";
    default:
      return "-";
  }
}

// 处理时间
export function getDate(date) {
  if (date === 0) return "-";
  return moment(date * 1000).format("YYYY-MM-DD HH:mm:ss");
}

// 处理商品名称
export function getProductName(orderItem) {
  let name = "";
  orderItem.forEach((item) => {
    name += `${(item.product && item.product.name) || ""}${
      item.spec ? "/" + item.spec.name : ""
    } * ${item.number}\xa0\xa0\xa0`;
  });
  // if (orderItem[0] && orderItem[0].product.supplier) {
  //   name += " [" + orderItem[0].product.supplier + "]";
  // }
  return name;
}

// 处理支付方式
export function getPayType(pay_type) {
  switch (pay_type) {
    case 1:
      return "支付宝支付";
    case 2:
      return "微信支付";
    case 3:
      return "微信支付";
    case 4:
      return "微信支付";
    case 5:
      return "微信支付";
    case 6:
      return "微信支付";
    case 8:
      return "微信支付";
    case 10:
      return "（测试用）跳过支付";
    default:
      return "";
  }
}

// 处理备注
export function getNote(note) {
  let remark = "";
  note.forEach((item) => {
    remark += `${item.user.username} ${item.content} ${getDate(
      item.created_at
    )} ${"\n"}`;
  });
  return remark;
}

// 处理配送状态
export function getDeliveryStatus (delivery_status, delivery_type) {
  if (delivery_type == 1 || delivery_type == 2||delivery_type == 3) {
    return '-'
  }
  switch (delivery_status) {
    case 0:
      return "待配送";
    case 1:
      return "订单已推单";
    case 2:
      return "订单已被骑手接单";
    case 3:
      return "骑手已到店";
    case 4:
      return "配送中";
    case 5:
      return "已送达";
    case 6:
      return "已取消";
    default:
      return "-";
  }
}

// 是否显示退款按钮
export function getCanType2(
  refund_amount,
  status,
  finished_at,
  delivery_status,
  id,
  delivery_type
) {
  if (delivery_type == 1) {
    return (
      refund_amount == 0 &&
      (status == 2 || status == 4) &&
      ((Number(id) == 1 && finished_at == 0) ||
        (Number(id) == 0 && (delivery_status <= 0 || delivery_status >= 6)))
    );
  }
  const canType2 =
    refund_amount == 0 &&
    status == 2 &&
    ((Number(id) == 1 && finished_at == 0) ||
      (Number(id) == 0 && (delivery_status <= 0 || delivery_status >= 6)));
  return canType2;
}

// 是否显示核销按钮
export function getCanType3(status, finished_at, service_status, id) {
  const canType3 =
    Number(id) == 1 &&
    finished_at == 0 &&
    status == 2 &&
    (service_status == 2 || service_status == 0);
  return canType3;
}

// 是否显示配送按钮
export function getCanType4(status, delivery_status, id) {
  const canType4 = delivery_status == 0 && status == 2 && Number(id) == 0;
  return canType4;
}

// 是否显示取消配送按钮
export function getCanType5(status, delivery_status, id, delivery_type) {
  const canType5 =
    delivery_status > 0 &&
    delivery_status < 5 &&
    status == 4 &&
    Number(id) == 0;
  if (delivery_type == 1) {
    return false;
  }
  return canType5;
}

// 是否显示取消订单按钮
export function getCanType6(status) {
  const canType6 = status == 0;
  return canType6;
}

// 是否显示发货
export function getCanType7(status) {
  return status == 2;
}

// 是否显示打印
export function getCanType8(delivery_type, status) {
  return (
    (delivery_type !== 2 || delivery_type !== 3) &&
    status != 0 &&
    status != 1 &&
    status != 3
  );
}

// 是否显示填写物流
export function getCanType9(status, express_code) {
  return (
    (status != 1 && status != 3 && status != 0) ||
    ((status == 1 || status == 3) && express_code != "")
  );
}

export const getColumnSearchProps = (col, handleSearch, handleReset) => {
  let searchInput;
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`搜索 ${col.title}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, col)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, col)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, height: 27 }}
            >
              搜索
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, col)}
              size="small"
              style={{ width: 90, height: 27 }}
            >
              重置
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#F4981D" : undefined }} />
    ),
  };
};

export const getColumnDataProps = (col, handleResetData, onChangeData) => {
  const dateFormat = "YYYY/MM/DD";
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          <RangePicker
            showTime={{ format: "HH:mm:ss" }}
            value={selectedKeys}
            format={dateFormat}
            onChange={(value, dateString) =>
              onChangeData(
                value,
                dateString,
                col,
                confirm,
                clearFilters,
                setSelectedKeys
              )
            }
          />
        </div>

        <Space
          style={{ width: "100%", justifyContent: "flex-end", marginTop: 8 }}
        >
          <Button
            onClick={() => handleResetData(clearFilters)}
            size="small"
            style={{ width: 60, height: 25 }}
          >
            重置
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <CalendarOutlined style={{ color: filtered ? "#F4981D" : undefined }} />
    ),
  };
};

export const getColumnStatusProps = (col, id) => {
  let filters;
  switch (col.key) {
    case "status":
      filters = [
        { text: "待支付", value: 0 },
        { text: "已完成", value: 1 },
        { text: id === 0 ? "等待配送" : "待使用", value: 2 },
        { text: "已关闭", value: 3 },
      ];
      if (id == 0) {
        filters.push({ text: "配送中", value: 4 });
      }
      filters.push({ text: "退款中", value: 5 });
      break;
    case "service_status":
      filters = [
        { text: "已退款", value: 1 },
        { text: "已部分退款", value: 2 },
      ];
      break;
    case "code_status":
      filters = [
        { text: "未核销", value: 0 },
        { text: "已核销", value: 1 },
      ];
      break;
    default:
      break;
  }
  return {
    filters: filters,
    filterMultiple: false,
  };
};

export const getColumnNumberProps = (col, numberSearch, numberReset) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <InputNumber
              value={selectedKeys[0]}
              onChange={(value) => {
                selectedKeys[0] = value;
                setSelectedKeys(selectedKeys);
              }}
              style={{ width: 88, marginBottom: 8, display: "block" }}
            />
            <span>-</span>
            <InputNumber
              value={selectedKeys[1]}
              onChange={(value) => {
                selectedKeys[1] = value;
                setSelectedKeys(selectedKeys);
              }}
              style={{ width: 88, marginBottom: 8, display: "block" }}
            />
          </div>

          <Space>
            <Button
              type="primary"
              onClick={() => numberSearch(selectedKeys, confirm, col)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, height: 27 }}
            >
              搜索
            </Button>
            <Button
              onClick={() => numberReset(clearFilters, col)}
              size="small"
              style={{ width: 90, height: 27 }}
            >
              重置
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#F4981D" : undefined }} />
    ),
  };
};

// 筛选方式为输入
const columnsExtraKeyType1 = [
  "order_no",
  "product_name",
  "mobile",
  "pay_user_account",
  "address_name",
  "address_mobile",
  "express_no",
  "delivery_name",
  "delivery_mobile",
];
// 筛选方式选日期
const columnsExtraKeyType2 = ["created_at", "finished_at"];
// 筛选方式状态
const columnsExtraKeyType3 = ["status", "service_status", "code_status"];
// 筛选方式范围
const columnsExtraKeyType4 = [
  "total",
  "actual_amount",
  "product_amount",
  "refund_amount",
  "hongbao",
  "rebate_hongbao",
  "hongbao_subsidy",
];
export const columnsArr = (
  temp,
  {
    handleRender,
    handleSearch,
    handleResetData,
    handleReset,
    numberSearch,
    numberReset,
    onChangeData,
    handleLogistics,
    handlePrint,
    id,
  }
) =>
  temp.map((col) => {
    const type1 = columnsExtraKeyType1.find((i) => i === col.key);
    const type2 = columnsExtraKeyType2.find((i) => i === col.key);
    const type3 = columnsExtraKeyType3.find((i) => i === col.key);
    const type4 = columnsExtraKeyType4.find((i) => i === col.key);
    col.width = "200px";
    if (col.key === "address") {
      col.width = "200px";
    }
    if (col.key === "product_name") {
      col.width = "600px";
    }
    if (col.key === "handle") {
      col.render = handleRender;
    }
    if (col.key === "logistics") {
      col.render = handleLogistics;
    }
    if (col.key === "print") {
      col.render = handlePrint;
    }
    col.align = "center";
    col.className = "Order_columns";
    if (col.key === "handle") {
      col.width = "150px";
    }
    if (col.key === "content") {
      col.width = "300px";
    }
    if (type1) {
      return {
        ...col,
        ...getColumnSearchProps(col, handleSearch, handleReset),
      };
    }
    if (type2) {
      return {
        ...col,
        ...getColumnDataProps(col, handleResetData, onChangeData),
      };
    }
    if (type3) {
      return {
        ...col,
        ...getColumnStatusProps(col, id),
      };
    }
    if (type4) {
      return {
        ...col,
        ...getColumnNumberProps(col, numberSearch, numberReset),
      };
    }
    return col;
  });
