import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Badge, Button, DatePicker, Empty, Form, Input, Modal, Pagination, Select, Spin, message } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import RefundModal from './components/refundModal'
import RemarkModal from './components/remarkModal'
import client from '@/graphql/apollo-config'
import { Get_Store_Categorys } from '@/graphql/products'
import { SUPPLIERS_LIST } from '@/graphql/store'
import { useLazyQuery } from 'react-apollo'
import { ORDER_STATUS, REMARK_TYPE_STATUS, isOrderInProgress, isOrderCanceled, getOrderList, diningOut } from '@/apis/order'
import { timestampToString, copy, excelColumnNumberToCodes } from '@/utils'
import { useWebSocket } from '@/WebSocketContext'
import FileSaver from 'file-saver'
import './TakeoutDelivery.less'

const Excel = require('exceljs');

const { Option } = Select;
const { RangePicker } = DatePicker;

const tabs = [
    { id: 0, name: '今日订单' },
    { id: 1, name: '全部订单' },
    { id: 2, name: '未支付' },
    { id: 3, name: '制作中' },
    { id: 4, name: '待核销' },
    { id: 6, name: '已完成' },
    { id: 7, name: '退款中' },
    { id: 8, name: '已退款' },
    { id: 9, name: '已关闭' },
]

const TakeoutSelfHelp = ({ history }) => {
    const isMounted = useRef(false)
    const { orders } = useWebSocket()
    const [form] = Form.useForm()
    const [categroyList, setCategroyList] = useState([])
    const [status, setStatus] = useState(0)
    const [pageInfo, setPageInfo] = useState({
        page: 1,
        pagesize: 10,
    })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const refundModalRef = useRef(null)
    const remarkModalRef = useRef(null)

    const [getSuppliers, { data: supplierData }] = useLazyQuery(
        SUPPLIERS_LIST,
        {
            variables: {
                page: 1,
                limit: 999,
            },
            fetchPolicy: 'no-cache',
        }
    )

    useEffect(() => {
        getSuppliers()
        getProductCategory()
    }, [])

    useEffect(() => {
        getList()
    }, [pageInfo])

    useEffect(() => {
        if (isMounted.current) {
            !loading && getList()
        } else {
            isMounted.current = true
        }
    }, [
        orders.wm_take_order_num_today,
        orders.wm_take_order_num_all,
        orders.wm_take_order_num
    ])

    const getProductCategory = async () => {
        try {
            const { data } = await client.query({
                query: Get_Store_Categorys,
                variables: {
                limit: 100,
                page: 1,
                },
                fetchPolicy: "no-cache",
            });
            setCategroyList(data.ProductCategories.data);
        } catch (error) {
            console.error(error);
        }
    }

    // tab change
    const onTabChange = (id) => {
        if (status === id) return
        setStatus(id)
        onChange(1)
    }

    // 搜索
    const onFinish = (values) => {
        onChange(1)
    }

    // 重置
    const onReset = () => {
        form.resetFields();
        onChange(1)
    }

    // page change
    const onChange = (page) => {
        setPageInfo({
            ...pageInfo,
            page
        })
    }

    // 获取列表数据
    const getList = async() => {
        setLoading(true)
        try {
            const {dates, ...fData} = form.getFieldsValue(true)
            if (dates && dates.length > 0) {
                fData.start_time = dates[0].format('YYYY-MM-DD')
                fData.end_time = dates[1].format('YYYY-MM-DD')
            }
            const { data } = await getOrderList({
                type: 2,
                status: status,
                ...pageInfo,
                ...fData,
            })
            data.data.forEach((item) => {
                const t = (48*60*60*1000) - (Date.now() - item.pay_at * 1000)
                let isRefund = false
                if (isOrderInProgress(item.status) || (item.status === 1 && t >= 0)) {
                    isRefund = true
                }
                item.isRefund = isRefund
            })
            setTotal(data.total)
            setList(data.data)
        } catch(e) {
            console.log(e)
        }
        setLoading(false)
    }

    // 出餐
    const onDiningOut = (id) => {
        Modal.confirm({
            title: '是否确认出餐？',
            content: '请确认菜品无误后再出餐',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            onOk: async () => {
                try {
                    const res = await diningOut(id)
                    if (res.errors.code === 1) {
                        message.success('出餐成功')
                        getList()
                    }
                } catch(e) {
                    console.log(e)
                }
            }
        })
    }

    // 申请退款
    const onRefund = (item) => {
        const t = (48*60*60*1000) - (Date.now() - item.pay_at * 1000)
        if (item.status === 1 && t < 0) {
            message.error('已超过48小时，不可申请退款')
            return
        }
        refundModalRef.current && refundModalRef.current.show({
            order_id: item.id,
            freight: +item.freight,
            package_price: +item.package_price,
            package_status: item.package_status,
            package_type: item.package_type,
            order_type: item.order_type,
            pack_type: item.pack_type,
            list: item.order_items
        })
    }

    // 添加备注
    const addRemark = (item) => {
        remarkModalRef.current && remarkModalRef.current.show({
            order_id: item.id,
            content: item.notes?.content || ''
        })
    }

    // 导出Excel
    const onExportExcel = async () => {
        if (loading) return;
        setLoading(true)
        let listExportExcel = []
        try {
            const {dates, ...fData} = form.getFieldsValue(true)
            if (dates && dates.length > 0) {
                fData.start_time = dates[0].format('YYYY-MM-DD')
                fData.end_time = dates[1].format('YYYY-MM-DD')
            }
            const { data: res } = await getOrderList({
                type: 2,
                status: status,
                page: 1,
                pagesize: total,
                ...fData,
            })
            listExportExcel = res.data.flatMap(item => {
                if (item.order_items.length > 0) {
                    return item.order_items.map(subItem => {
                        return { ...item, order_items: subItem }
                    })
                }
                return { ...item, order_items: null }
            })
        } catch(e) {}

        const header = [
            '订单编号', '下单时间', '完成时间', '状态', '商品','单价','数量', 'sku编码', '供应商', '供货价', '商品金额', '使用红包',
            '使用餐券', '打包费', '实收款', '返红包', '发放餐券', '售后状态', '退款金额',
            '支付方式', '买家名称', '买家手机号', '预留电话', '核销号', '买家留言', '备注', '订单来源'
        ]
        const filter = [
            'order_no', 'created_at', 'finished_at', 'status', 'order_items','product_price','number', 'sku', 'supplier', 'product_offer_price', 'product_amount', 'hongbao',
            'deduction_amount', 'package_price', 'actual_amount', 'rebate_hongbao', 'rebate_amount', 'sales_status', 'refund_amount',
            'pay_type', 'user_name', 'user_mobile', 'address_mobile', 'qrcode', 'remake', 'notes', 'source'
        ]
        const data = listExportExcel.map(item => {
            const refund_amount = item.order_refund && item.order_refund.status == 2 ? item.order_refund.refund_amount : '0.00'
            let sales_status = ''
            if (item.order_refund) {
                if (item.order_refund.status == 1) {
                    sales_status += `${REMARK_TYPE_STATUS[item.order_refund.remark_type] || '买家发起退款'}\n`
                } else if (item.order_refund.status == 2) {
                    sales_status += `${item.actual_amount == refund_amount ? '已退款' : '已部分退款'}\n已退款¥${refund_amount}\n`
                } else if (item.order_refund.status == 3) {
                    sales_status += '退款失败\n'
                } else if (item.order_refund.status == 4) {
                    sales_status += '用户撤销退款\n'
                }
                sales_status += `申请退款原因：${item.order_refund.remark_type_text}`
            }
            return {
                order_no: item.order_no,
                created_at: timestampToString(item.created_at * 1000, 2),
                finished_at: item.finished_at > 0 ? timestampToString(item.finished_at * 1000, 2) : '',
                status: ORDER_STATUS[item.status],
                order_items: item.order_items?.product_name || '-',
                product_price: item.order_items ? `¥${item.order_items.product_price}` : '-',
                number: item.order_items?.number || '-',
                sku: item.order_items?.sku || '-',
                supplier: item.order_items?.supplier || '-',
                product_offer_price: item.order_items ? `¥${item.order_items.product_offer_price}` : '-',
                product_amount: `¥${item.product_amount}`,
                hongbao: `¥${item.hongbao}`,
                deduction_amount: `¥${item.deduction_amount}`,
                package_price: `¥${item.package_price}`,
                actual_amount: `¥${(item.actual_amount - refund_amount).toFixed(2)}`,
                rebate_hongbao: `¥${item.rebate_hongbao}`,
                rebate_amount: `¥${item.rebate_amount}`,
                sales_status: sales_status,
                refund_amount: `¥${refund_amount}`,
                pay_type: ({ 0: '余额支付', 1: '微信支付' })[item.pay_type],
                user_name: item.user?.username,
                user_mobile: item.user?.mobile,
                address_mobile: item.address?.mobile,
                qrcode: item.qrcode,
                remake: item.remake,
                notes: item.notes?.content,
                source: item.is_client == 1 ? '自助下单' : '小程序'
            }
        })
        const workbook = new Excel.Workbook();
        const sheet = workbook.addWorksheet('Sheet1');
        const groupedData = data.reduce((acc, item, dataIndex) => {
        const key = `${item.order_no}`;
            if (!acc[key]) {
                acc[key] = { items: [], dataIndex };
            }
            acc[key].items.push(item);
            return acc;
        }, {});

        sheet.columns = header.map((item, index) => ({
            header: item,
            key: filter[index],
            width: 20
        }));

        const rowReferences = [];
        data.forEach((item, dataIndex) => {
            const row = sheet.addRow(item);
            rowReferences.push({ row, dataIndex })
        });

        Object.keys(groupedData).forEach((key, groupIndex) => {
            const group = groupedData[key];
            if (group.items.length > 1) {
                const firstRowReference = rowReferences.find(ref => ref.dataIndex === group.dataIndex);
                if (firstRowReference) {
                    const firstRowIndex = firstRowReference.row.number
                    const listrange = [...excelColumnNumberToCodes(1, 4), ...excelColumnNumberToCodes(11, firstRowReference.row.cellCount)]
                    listrange.forEach(item => {
                        sheet.mergeCells(`${item}${firstRowIndex}:${item}${firstRowIndex + group.items.length - 1}`);
                        sheet.getCell(`${item}${firstRowIndex}`).alignment = { vertical: 'middle' };
                    });
                }
            }
        });
        const fileName = `外卖自取${Date.now()}.xlsx`;
        workbook.xlsx.writeBuffer().then(buffer => {
            FileSaver.saveAs(new Blob([buffer], {type: 'application/octet-stream'}), fileName);
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className="takeout-container">
            <div className="takeout-tabs">
                {tabs.map(item => (
                    <div
                        key={item.id}
                        className={`tab-item ${status === item.id ? 'active' : ''}`}
                        onClick={() => onTabChange(item.id)}
                    >
                        {item.name}
                        {item.id === 0 && `(${orders.wm_take_order_num_today})`}
                        {item.id === 1 && `(${orders.wm_take_order_num_all})`}
                        {item.id === 3 && <Badge count={orders.wm_take_order_num} />}
                    </div>
                ))}
            </div>
            <Form className="takeout-form" form={form} layout="inline" onFinish={onFinish}>
                <Form.Item label="订单编号" name="order_no">
                    <Input />
                </Form.Item>
                <Form.Item label="商品名称" name="product_name">
                    <Input />
                </Form.Item>
                <Form.Item label="商品ID" name="product_id">
                    <Input />
                </Form.Item>
                <Form.Item label="创建时间" name="dates">
                    <RangePicker />
                </Form.Item>
                <Form.Item label="买家姓名" name="user_name">
                    <Input />
                </Form.Item>
                <Form.Item label="买家手机号" name="phone">
                    <Input />
                </Form.Item>
                <Form.Item label="商品类目" name="product_category_id">
                    <Select placeholder="请选择">
                        {categroyList.map((item) => (
                            <Option value={item.id} key={item.id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="供应商" name="supplier_id">
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {(supplierData?.suppliers?.data || []).map((i) => (
                            <Option value={i.id} key={i.id}>
                                {i.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item style={{ marginRight: 0, width: '100%' }}>
                    <Button type="primary" htmlType="submit">搜索</Button>
                    <Button
                        type="link"
                        htmlType="button"
                        style={{ marginLeft: '5px' }}
                        onClick={onReset}
                    >重置</Button>
                </Form.Item>
            </Form>
            {list.length > 0 && <div className="flex flex-end" style={{ marginBottom: '16px' }}>
                <Button type="primary" onClick={onExportExcel}>导出Excel</Button>
            </div>}
            <Spin spinning={loading}>
                <div className="order-list">
                    {list.map(item => (
                        <div className="item-content" key={item.id}>
                            <div className="item-header">
                                <div className="item-header__inner">
                                    <div className="flex align-center">
                                        <div className={classNames('item-status', {
                                            erro: [2, 4, 8, 9].includes(item.status),
                                            warn: [5, 11].includes(item.status),
                                            succ: [1, 10].includes(item.status),
                                        })}>{ ({ 2: '制作中', 4: '待核销' })[item.status] || ORDER_STATUS[item.status]}</div>
                                        <div className="item-label">
                                            <span>订单编号：</span>{item.order_no}
                                            <div className="icon-copy" onClick={() => copy(item.order_no)}></div>
                                        </div>
                                    </div>
                                    <div className="item-label"><span>下单时间：</span>{timestampToString(item.created_at * 1000, 2)}</div>
                                    <div className="item-label"><span>买家：</span>{item.user?.username}</div>
                                    <div className="item-label"><span>买家手机号：</span>{item.user?.mobile}</div>
                                </div>
                                <div className="item-label"><span>订单来源：</span>{item.is_client == 1 ? '自助下单' : '小程序'}</div>
                            </div>
                            <table className="item-inner">
                                <colgroup>
                                    <col style={{ width: '22%' }} />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col style={{ width: '125px' }} />
                                    <col style={{ width: '134px' }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>商品信息</th>
                                        <th>单价</th>
                                        <th>数量</th>
                                        <th>供应商</th>
                                        <th>供货价</th>
                                        <th>商品金额</th>
                                        <th>售后状态</th>
                                        <th>实收款</th>
                                        <th>预计取餐时间</th>
                                        <th>操作</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.order_items.map((good, goodIdx) => (<tr key={good.id}>
                                        <td>
                                            <div className="item-goods flex">
                                                <div className="item-goods__media flex-shrink-0">
                                                    <img className="item-goods__img" src={good.product_cover} alt="" />
                                                </div>
                                                <div className="item-goods__inner flex-1">
                                                    <div className="item-goods__title bold ell1">{good.product_name}</div>
                                                    <div className="item-goods__subtl">{good.product_spec_val}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="bold">¥{good.product_price}</div>
                                        </td>
                                        <td>
                                            <div className="bold">{good.number}</div>
                                        </td>
                                        <td>
                                            <div>{good.supplier}</div>
                                        </td>
                                        <td>
                                            <div className="bold">¥{good.product_offer_price}</div>
                                        </td>
                                        {goodIdx === 0 && <>
                                            <td rowSpan={item.order_items.length}>
                                                <div className="bold">¥{item.product_amount}</div>
                                            </td>
                                            <td rowSpan={item.order_items.length}>
                                                {item.order_refund ? <>
                                                    {item.order_refund.status == 1 && <div>{REMARK_TYPE_STATUS[item.order_refund.remark_type] || '买家发起退款'}</div>}
                                                    {item.order_refund.status == 2 && <div>{item.actual_amount == item.order_refund.refund_amount ? '已退款' : '已部分退款'}</div>}
                                                    {item.order_refund.status == 2 && <div className="item-subtl flex">已退款<span className="item-erro" style={{ marginLeft: '3px' }}>¥{item.order_refund.refund_amount}</span></div>}
                                                    {item.order_refund.status == 3 && <div>退款失败</div>}
                                                    {item.order_refund.status == 4 && <div>用户撤销退款</div>}
                                                    <div className="item-subtl">申请退款原因：{item.order_refund.remark_type_text}</div>
                                                </> : <div>-</div>}
                                            </td>
                                            <td rowSpan={item.order_items.length}>
                                                {(item.status == 0 || isOrderCanceled(item.status)) ?
                                                    <div className="item-price">¥0.00</div> : 
                                                    <>
                                                        {item.order_refund && item.order_refund.status == 2
                                                            ? <div className="item-price">¥{(item.actual_amount - item.order_refund.refund_amount).toFixed(2)}</div>
                                                            : <div className="item-price">¥{item.actual_amount}</div>}
                                                        {Number(item.package_price) > 0 && <div className="item-subPrice">含打包费¥{+item.package_price}</div>}
                                                        {Number(item.freight) > 0 && <div className="item-subPrice">含配送费¥{+item.freight}</div>}
                                                    </>}
                                            </td>
                                            <td rowSpan={item.order_items.length}>
                                                <span>{timestampToString(item.estimated_time * 1000, 4)}</span>
                                            </td>
                                            <td rowSpan={item.order_items.length}>
                                                <div className="flex-space">
                                                    <span className="item-link" onClick={() => {
                                                        history.push(`/takeoutSelfHelpDetail?id=${item.id}`);
                                                    }}>详情</span>
                                                    {item.status == 2 && <span className="item-link" onClick={() => onDiningOut(item.id)}>出餐</span>}
                                                    {item.isRefund && <span className="item-link" onClick={() => onRefund(item)}>退款</span>}
                                                    {item.status == 5 && <span className="item-link disabled">退款</span>}
                                                </div>
                                            </td>
                                        </>}
                                    </tr>))}
                                    <tr>
                                        <td colSpan={10}>
                                            <div className="item-label">
                                                <span>预留电话：</span>{item.address ? item.address.mobile : '-'}
                                                {!!item.address && <div className="icon-copy" onClick={() => copy(item.address.mobile)}></div>}
                                            </div>
                                            <div className="item-label">
                                                <span>买家留言：</span>{item.remake || '-'}
                                            </div>
                                            <div className="item-label">
                                                <span>商家备注：</span>
                                                {!!item.notes && <div style={{ marginRight: '5px' }}>{item.notes.content}</div>}
                                                <span className="item-link" onClick={() => addRemark(item)}>{item.notes ? '修改备注' : '添加备注'}</span>
                                                <span>（仅商家可见）</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                    {list.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '50px 0' }} />}
                </div>
            </Spin>
            {list.length > 0 && <Pagination
                total={total}
                current={pageInfo.page}
                defaultPageSize={pageInfo.pagesize}
                showQuickJumper
                showSizeChanger={false}
                showTotal={total => `共${total}条`}
                style={{
                    textAlign: 'center',
                    marginTop: '26px'
                }}
                onChange={onChange}
            />}
            <RefundModal ref={refundModalRef} onOk={() => getList()} />
            <RemarkModal ref={remarkModalRef} onOk={() => getList()} />
        </div>
    )
}

export default TakeoutSelfHelp