import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { Button, Message, Modal } from "antd";
import Cookies from "js-cookie";
import { isPhone } from "../utils/regexp";
import {
  SEND_SMS_CAPTCHA,
  REGISTER_STORE,
  RESET_PWD,
} from "../graphql/login";
// import { captcha } from '../utils/captcha';
import { getQueryString } from "../utils/index";


let timer = "";
const Layout = props => {

  const { history } = props;
  const [registObj, setRegistObj] = useState({
    mobile: "",
    sms_code: "",
    password: "",
    confirm_password: "",
    pos_code: getQueryString('code')
  });
  const [loading, setLoading] = useState(false);
  const [codeSecond, setCodeSecond] = useState(0);
  // const [value, setValue] = useState()
  // 跳转至忘记密码
  // const goForgetPwd = () => {
  //   history.push("/forgetpwd");
  // };

  // console.log(history,1111)

  // 获取短信验证码
  const [sendSmsCaptcha] = useMutation(SEND_SMS_CAPTCHA, {
    //请求完毕之后的回调，可以进行更新及其它操作
    update(_, { data }) {
      if (data.captcha) {
        let newCodeSecond = 60;
        timer = setInterval(() => {
          newCodeSecond--;
          setCodeSecond(newCodeSecond);
          if (
            newCodeSecond <= 0 ||
            (props.form === "register" && window.location.pathname.indexOf("login") === -1)
            || (props.form === "forgetpwd" && window.location.pathname.indexOf("forgetpwd") === -1)
          ) {
            clearInterval(timer);
            setCodeSecond(0);
            timer = null;
          }
        }, 1000);
        Message.success("发送成功");
      }
    },
    // 请求失败异常的回调
    onError(e) {
      clearInterval(timer);
      setCodeSecond(0);
      if (e.graphQLErrors instanceof Array && e.graphQLErrors.length > 0) {
        e.graphQLErrors.forEach(err => {
          let statusCode = err.code;
          if (statusCode === 41027) {
            Modal.confirm({
              content: "此手机号已注册，您可以尝试去登录",
              okText: "去登录",
              onOk() {
                history.push("/login");
              },
              onCancel() { }
            });
          }
        });
      }
    },
    // 请求带的参数
    variables: {
      mobile: registObj.mobile,
      type: props.form === "forgetpwd" ? 2 : 0,
      // value: value,
    }
  });

  // 验证是否可以获取验证码
  const getSmsCode = async () => {
    if (!codeSecond) {
      //	发送请求
      if (!registObj.mobile) {
        Message.error("请输入手机号");
      } else {
        toCaptcha();
      }
    }
  };

  const toCaptcha = () => {
    sendSmsCaptcha();
    // captcha(() => {
    //   let captchaIns
    //   window.initNECaptcha({
    //     element: '#captcha',
    //     captchaId: '3a0bc0da0f9449858140422b0a128f80',
    //     mode: 'popup',
    //     width: '320px',
    //     enableClose: false,
    //     feedbackEnable: false,
    //     onVerify: (err, data) => {
    //       if (data && data.validate) {
    //         setValue(data.validate)
    //         sendSmsCaptcha();
    //       }
    //     }
    //   }, (instance) => {
    //     // 初始化成功后得到验证实例instance，可以调用实例的方法
    //     captchaIns = instance
    //     captchaIns && captchaIns.popUp()
    //   }, (err) => {
    //     // 初始化失败后触发该函数，err对象描述当前错误信息
    //   })
    // })
  }

  const [registerMutation] = useMutation(
    props.form === "forgetpwd" ? RESET_PWD : REGISTER_STORE,
    {
      //请求完毕之后的回调，可以进行更新及其它操作
      update(_, { data }) {
        setLoading(false);
        let t =
          props.form === "register"
            ? data.registerStore.remember_token
            : data.resetPassword.remember_token;
        let mobile =
          props.form === "register"
            ? data.registerStore.mobile
            : data.resetPassword.mobile;

        Cookies.set("token", t, {
          path: "/",
          expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
        });
        Cookies.set("mobile", mobile, {
          path: "/",
          expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
        });
        window.location = "/";
      },
      // 请求失败异常的回调
      onError(e) {
        setLoading(false);
      },
      // 请求带的参数
      variables: {
        phone: registObj.mobile,
        password: registObj.password,
        type:props.form === "forgetpwd"?2:undefined,
        code: registObj.sms_code,
        codeType: props.form === "register" ? 0 : 2,
        pos_code: registObj.pos_code
      }
    }
  );
  const onRegister = async () => {
    if (!registObj.mobile) {
      Message.error("请输入手机号");
    } else if (!isPhone(registObj.mobile)) {
      Message.error("手机号码格式不正确，请重新输入");
    } else if (!registObj.sms_code) {
      Message.error("请输入验证码");
    } else if (!registObj.password) {
      Message.error("请输入登录密码");
    } else if (registObj.password.length < 6) {
      Message.error("请输入6位及以上密码");
    } else if (registObj.password != registObj.confirm_password) {
      Message.error("请输入确认登录密码");
    } else {
      setLoading(true);
      registerMutation();
    }
  };

  return (
    <div>
      <input
        className="ipt"
        type="text"
        placeholder="请输入手机号"
        maxLength="11"
        value={registObj.mobile}
        onChange={e => {
          let value = e.target.value;
          setRegistObj(state => ({
            ...state,
            mobile: value
          }));
        }}
      />

      <div className="verifica-code">
        <input
          className="ipt"
          type="text"
          placeholder="请输入验证码"
          value={registObj.sms_code}
          onChange={e => {
            let value = e.target.value;
            setRegistObj(state => ({
              ...state,
              sms_code: value
            }));
          }}
        />
        <div onClick={getSmsCode} className="code">
          <span className="href">
            {!!codeSecond ? codeSecond + "s后重新获取" : "获取验证码"}
          </span>
        </div>
      </div>
      <input
        className="ipt"
        type="password"
        placeholder="请输入登录密码"
        value={registObj.password}
        onChange={e => {
          let value = e.target.value;
          setRegistObj(state => ({
            ...state,
            password: value
          }));
        }}
      />
      <input
        className="ipt"
        type="password"
        placeholder="确认登录密码"
        value={registObj.confirm_password}
        onChange={e => {
          let value = e.target.value;
          setRegistObj(state => ({
            ...state,
            confirm_password: value
          }));
        }}
      />
      <Button
        onClick={onRegister}
        className="btn-login"
        type="primary"
        block
        loading={loading}
        size="large"
      >
        {history.location.pathname == "/forgetpwd"?'重置密码':'注册'}
      </Button>
      {/*{props.form !== "forgetpwd" ? (*/}
      {/*<p*/}
      {/*style={{*/}
      {/*textAlign: "right",*/}
      {/*fontSize: "12px",*/}
      {/*color: "rgba(17,17,17,1)",*/}
      {/*marginTop: "10px"*/}
      {/*}}*/}
      {/*onClick={goForgetPwd}*/}
      {/*>*/}
      {/*<span className={"href"}>忘记密码</span>*/}
      {/*</p>*/}
      {/*) : null}*/}
      <div className='login-tip'>登录代表您已同意树懒生活
            <div className='blue' onClick={() => history.push(`/resources?name=store_agreement`)}>用户协议</div>、
            <div className='blue' onClick={() => history.push(`/resources?name=store_privacy_agreement`)}>隐私协议</div>
      </div>
      <div id="captcha" ></div>
    </div>
  );
};
export default Layout;
