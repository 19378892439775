import React,{ useState } from 'react';
import {Modal, Col} from 'antd';
import './tipsModal.less';

const TipsModal = (props) => {
    const { showTips,title, content, cancelText, okText, onOk,onCancel} = props

    return <Modal
        cancelText={cancelText}
        okText={okText}
        visible={showTips}
        onOk={() => {
            if (typeof onOk == 'function'){
                onOk();
            }
        }}
        onCancel={() => {
            if (typeof onCancel == 'function'){
                onCancel();
            }
        }}
    >
        <div className='tips-container'>
            <h2>{title}</h2>
            <Col span={20}>{content}</Col>
        </div>
    </Modal>
}

export default TipsModal