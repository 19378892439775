import React, { useState, useEffect } from "react";
import { Input, Radio, Message, DatePicker, Modal } from "antd";
import {
  ADD_COMMISSION_RULE,
  EDIT_COMMISSION_RULE,
  EDIT_RULE,
  EDIT_RULES,
} from "../../../graphql/commission";
import "./index.less";
import moment from "moment";
import client from "../../../graphql/apollo-config";

const SetCommission = (props) => {
  const [commissionInfo, setCommissionInfo] = useState({
    name: "赏金计划规则",
    start_at: "",
    end_at: "",
    rule_type: 1,
    commission1: "",
    commission2: "",
    commission3: "",
    status: "",
  });
  const [commissionList, setCommissionList] = useState([]);
  const [minCommission, setMinCommission] = useState(0);
  const [minCommissionQr, setMinCommissionQr] = useState(0);
  const [index, setIndex] = useState(100);
  const [id, setId] = useState("");
  const [type, setType] = useState("");

  const { RangePicker } = DatePicker;

  const search = props.location.search;
  const rule1 = [
    {
      rule: type !== "default" && commissionInfo.start_at === "",
      title: "请选择开始时间",
    },
    {
      rule: type !== "default" && commissionInfo.end_at === "",
      title: "请选择结束时间",
    },
    {
      rule: commissionInfo.rule_type === 100,
      title: "请选择销售方式",
    },
    {
      rule: commissionInfo.rule_type === 0 && commissionInfo.commission1 === "",
      title: "请选择档位",
    },
    {
      rule: commissionInfo.rule_type === 1 && commissionInfo.commission3 === "",
      title: "请填写扫码付款赏金计划比例",
    },
    {
      rule: commissionInfo.rule_type === 1 && commissionInfo.commission2 === "",
      title: "请填写线上商城赏金计划比例",
    },
    {
      rule:
        commissionInfo.rule_type === 1 &&
        commissionInfo.commission2 < minCommission,
      title: `线上商城赏金计划比例不能低于${minCommission}%`,
    },
    {
      rule:
        commissionInfo.rule_type === 1 &&
        commissionInfo.commission3 < minCommissionQr,
      title: `扫码付款赏金计划比例不能低于${minCommissionQr}%`,
    },
    {
      rule:
        commissionInfo.rule_type === 1 &&
        (commissionInfo.commission2 <= 0 || commissionInfo.commission2 > 100),
      title: "请填入0~100范围内数字",
    },
    {
      rule:
        commissionInfo.rule_type === 1 &&
        (commissionInfo.commission3 <= 0 || commissionInfo.commission3 > 100),
      title: "请填入0~100范围内数字",
    },
    {
      rule:
        commissionInfo.rule_type === 1 &&
        commissionInfo.commission2 &&
        commissionInfo.commission2.toString().indexOf(".") !== -1 &&
        commissionInfo.commission2.toString().split(".")[1].length > 2 &&
        commissionInfo.commission3 &&
        commissionInfo.commission3.toString().indexOf(".") !== -1 &&
        commissionInfo.commission3.toString().split(".")[1].length > 2,
      title: "最多只能保存两位小数",
    },
  ];

  useEffect(() => {
    let initId = "";
    if (search.indexOf("type") !== -1) {
      setType("default");
    }
    if (search.indexOf("id") !== -1) {
      if (search.indexOf("&") !== -1) {
        setId(search.slice(search.indexOf("=") + 1, search.indexOf("&")));
        initId = search.slice(search.indexOf("=") + 1, search.indexOf("&"));
      } else {
        setId(search.slice(search.indexOf("=") + 1));
        initId = search.slice(search.indexOf("=") + 1);
      }
    }
    init(initId);
  }, []);

  const init = async (initId) => {
    if (initId) {
      try {
        const { data } = await client.query({
          query: EDIT_RULES,
          variables: {
            id: initId,
          },
          fetchPolicy: "no-cache",
        });
        if (data) {
          const rule = data.user.store.category_commission_rule;
          if (rule) {
            setCommissionList(rule.commission_list);
            setMinCommission(rule.min_commission);
          }
          const a = data.commissionRule;
          if (a.rule_type === 0) {
            setIndex(
              rule.commission_list.findIndex((i) => {
                return i === a.commission;
              })
            );
            setCommissionInfo({
              name: a.name,
              start_at: a.start_at,
              end_at: a.end_at,
              rule_type: a.rule_type,
              status: a.status,
              commission1: a.commission,
              commission2: "",
            });
          } else {
            setCommissionInfo({
              name: a.name,
              start_at: a.start_at,
              end_at: a.end_at,
              rule_type: a.rule_type,
              status: a.status,
              commission1: "",
              commission2: a.commission,
              commission3: a.commission_qr,
            });
          }
        }
      } catch (error) {}
    } else {
      try {
        const { data } = await client.query({
          query: EDIT_RULE,
          fetchPolicy: "no-cache",
        });
        if (data.user.store.category_commission_rule) {
          const { commission_list, min_commission, min_commission_qr } =
            data.user.store.category_commission_rule;
          setCommissionList(commission_list);
          setMinCommission(min_commission);
          setMinCommissionQr(min_commission_qr);
        }
      } catch (error) {}
    }
  };

  const verification = (rule) => {
    for (let item of rule) {
      if (item.rule) {
        Message.info(item.title);
        return false;
      }
    }
    return true;
  };

  const setGear = (e) => {
    if (commissionInfo.rule_type === 0) {
      setCommissionInfo({
        ...commissionInfo,
        commission1: commissionList[e.target.value],
      });
      setIndex(e.target.value);
    } else {
      setCommissionInfo({
        ...commissionInfo,
        commission2: e.target.value,
      });
    }
  };

  const save = async () => {
    if (!verification(rule1)) return;
    Modal.confirm({
      content: "提交后不允许修改赏金计划比例，是否确认提交",
      onOk() {
        saveData();
      },
    });
  };

  const saveData = async () => {
    let variab = {};
    if (type === "default") {
      variab = {
        name: commissionInfo.name,
        commission:
          commissionInfo.rule_type === 0
            ? commissionInfo.commission1
            : commissionInfo.commission2,
        commission_qr:
          commissionInfo.rule_type === 0
            ? commissionInfo.commission1
            : commissionInfo.commission3,
        start_at: 0,
        end_at: 0,
        status: commissionInfo.status,
        rule_type: commissionInfo.rule_type,
        ...(id ? { id: id } : {}),
      };
    } else {
      variab = {
        ...(id ? { id: id } : {}),
        name: commissionInfo.name,
        commission:
          commissionInfo.rule_type === 0
            ? commissionInfo.commission1
            : commissionInfo.commission2,
        commission_qr:
          commissionInfo.rule_type === 0
            ? commissionInfo.commission1
            : commissionInfo.commission3,
        start_at: commissionInfo.start_at,
        end_at: commissionInfo.end_at,
        status: commissionInfo.status,
        rule_type: commissionInfo.rule_type,
      };
    }
    try {
      const { data } = await client.mutate({
        mutation: id ? EDIT_COMMISSION_RULE : ADD_COMMISSION_RULE,
        variables: variab,
        fetchPolicy: "no-cache",
      });
      if (data) {
        Message.success("保存成功");
        props.history.push("/commission");
      }
    } catch (error) {}
  };

  const title = () => {
    if (type === "default") {
      if (id) {
        return "编辑默认赏金计划规则";
      } else {
        return "新增默认赏金计划规则";
      }
    } else {
      if (id) {
        return "编辑促销赏金计划规则";
      } else {
        return "新增促销赏金计划规则";
      }
    }
  };

  const onChange = (dates, dateStrings) => {
    const startTime = Date.parse(dateStrings[0]) / 1000;
    const endTime = Date.parse(dateStrings[1]) / 1000;
    setCommissionInfo({
      ...commissionInfo,
      start_at: startTime,
      end_at: endTime,
    });
  };

  // 修改数据
  const changeData = (name, value) => {
    console.log(name, value);
    setCommissionInfo({
      ...commissionInfo,
      [name]: value,
    });
  };

  const dateFormat = "YYYY-MM-DD HH:mm:ss";

  return (
    <div className="setcommission-wrapper">
      <div className="commission-info">
        <div className="fz18 bold">{title()}</div>
        {type !== "default" && (
          <div className="commission-content_wrapper">
            <div className="title">有效时间</div>
            <RangePicker
              allowClear={false}
              value={
                commissionInfo.start_at
                  ? [
                      moment(
                        moment(commissionInfo.start_at * 1000).format(
                          dateFormat
                        )
                      ),
                      moment(
                        moment(commissionInfo.end_at * 1000).format(dateFormat)
                      ),
                    ]
                  : ""
              }
              showTime={{ format: dateFormat }}
              format={dateFormat}
              onChange={onChange}
            />
          </div>
        )}
        <div className="commission-content_wrapper">
          <div
            className="title"
            style={{ width: 478, marginBottom: 8, marginTop: 6 }}
          >
            请选择销售方式：
          </div>
        </div>
        <Radio.Group
          value={Number(commissionInfo.rule_type)}
          onChange={(e) => {
            changeData("rule_type", e.target.value);
          }}
        >
          {/* <Radio value={0} style={{ display: "block", fontSize: 16 }}>
            销售方式一
          </Radio>
          <Radio.Group
            value={index}
            onChange={(e) => setGear(e)}
            style={{ marginLeft: 24 }}
          >
            {commissionList.map((i, j) => {
              return (
                <Radio
                  key={j + ""}
                  disabled={commissionInfo.rule_type !== 0}
                  value={Number(j)}
                  style={{ display: "block", fontSize: 16 }}
                >
                  第{j + 1}档：{i}%
                </Radio>
              );
            })}
          </Radio.Group> */}
          {/* <Radio
            value={1}
            style={{ display: "block", fontSize: 16, marginTop: 10 }}
          >
            销售方式二
          </Radio> */}
          <div className="commission-content_wrapper" style={{ marginTop: 0 }}>
            <div className="title" style={{ marginLeft: 24, fontSize: 16 }}>
              请填写扫码付款消费金比例
            </div>
            <div className="content">
              <Input
                type="number"
                onChange={(e) => {
                  setCommissionInfo({
                    ...commissionInfo,
                    commission3: e.target.value,
                  });
                }}
                disabled={commissionInfo.rule_type !== 1}
                value={commissionInfo.commission3}
                style={{ width: 60 }}
              />
            </div>
            <div>%</div>
            <span className="tip">（最低填入{minCommissionQr}%)</span>
          </div>
          <div className="commission-content_wrapper" style={{ marginTop: 10 }}>
            <div className="title" style={{ marginLeft: 24, fontSize: 16 }}>
              请填写线上商城消费金比例
            </div>
            <div className="content">
              <Input
                type="number"
                onChange={(e) => setGear(e)}
                disabled={commissionInfo.rule_type !== 1}
                value={commissionInfo.commission2}
                style={{ width: 60 }}
              />
            </div>
            <div>%</div>
            <span className="tip">（最低填入{minCommission}%)</span>
          </div>
        </Radio.Group>
      </div>
      <div className="comfirm-btn" onClick={() => save()}>
        保存
      </div>
    </div>
  );
};

export default SetCommission;
