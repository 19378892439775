import gql from "graphql-tag";

export const USER = gql`
  query {
    user {
      id
      store {
        id
        product_type
        delivery_type
        fnpt_freight_type
        fnpt_customer_freight
        self_customer_freight
        is_express
        open_supplier
        is_spend
      }
      can_commission
      pending_commission_withdraw
      today_turnover
      total_turnover
    }
    bankCard {
      image {
        id
        url
      }
      bank_account
      bank_name
      bank_account_name
    }
  }
`;

export const BANK_CARD = gql`
  query {
    bankCard {
      image {
        id
        url
      }
      bank_account
      bank_name
      bank_account_name
    }
  }
`;

export const WITH_DRAW = gql`
  query ($limit: Int, $page: Int) {
    withdraws(limit: $limit, page: $page) {
      data {
        created_at
        service_charge
        amount_type
        amount
        status
      }
      total
      current_page
    }
    user {
      id
      store {
        id
      }
      today_turnover_qr
      total_turnover_qr
      today_turnover
      total_turnover
      today_commission
      total_commission
      today_hongbao_subsidy
      total_hongbao_subsidy

      can_commission
      pending_commission_withdraw
      commission_today_is_withdraw
      store_turnover_withdraw
      store_commission_withdraw
    }
    bankCard {
      image {
        id
        url
      }
      bank_account
      bank_name
      bank_account_name
    }
  }
`;

// export const ADD_WITH_DRAW = gql`
//   mutation (
//     $phone: String!
//     $code: String!
//     $ali: String!
//     $name: String!
//     $amount: Float!
//     $codeType: Int!
//     $type: Int!
//   ) {
//     addWithdraw(
//       phone: $phone
//       code: $code
//       ali: $ali
//       name: $name
//       amount: $amount
//       codeType: $codeType
//       type: $type
//     )
//   }
//`

export const BIND_BANK_CARD = gql`
  mutation (
    $image: [String]!
    $bank_account: String!
    $bank_name: String!
    $bank_account_name: String!
  ) {
    bindBankCard(
      image: $image
      bank_account: $bank_account
      bank_name: $bank_name
      bank_account_name: $bank_account_name
    ) {
      image {
        id
        url
      }
      bank_account
      bank_name
      bank_account_name
    }
  }
`;
export const ADD_WITH_DRAW = gql`
  mutation ($amount: Float) {
    addWithdraw(amount: $amount)
  }
`;
