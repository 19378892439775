import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Input, Modal, message } from 'antd'
import { noteSubmit } from '@/apis/order'
import './remarkModal.less'

const RemarkModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [title, setTitle] = useState('添加备注')
    const [info, setInfo]= useState({})
    const [value, setValue] = useState('')

    useImperativeHandle(ref, () => ({
        show: (data) => {
            const { content, ..._data } = data
            setInfo(_data)
            setValue(content || '')
            setTitle(content ? '修改备注' : '添加备注')
            setVisible(true)
        }
    }))

    const onSubmit = async () => {
        if (!value) return message.warning('请输入备注');
        try {
            const params = {
                order_id: info.order_id,
                note: value
            }
            const res = await noteSubmit(params)
            message.success('提交成功')
            setVisible(false)
            typeof props.onOk === 'function' && props.onOk()
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <Modal
            open={visible}
            width={450}
            title={title}
            className="custom-modal remark-modal"
            centered
            onOk={onSubmit}
            onCancel={() => setVisible(false)}
        >
            <div className="flex">
                <div className="remark-modal-label flex-shrink-0">备注</div>
                <div className="remark-modal-inner flex-1">
                    <Input.TextArea rows={4} value={value} onChange={e => setValue(e.target.value)} />
                </div>
            </div>
        </Modal>
    )
})

export default RemarkModal