import React, { useEffect, useRef, useState } from 'react';
import Block from './block';
import app from "@/app.json";

const QRCode = require("qrcode-react");

function StoreQrcodeView({ id }) {
    const [base64, setBase64] = useState('')
    const qrcodeRef = useRef(null)

    useEffect(() => {
        if (id) {
            const canvas = qrcodeRef.current.refs.canvas
            setBase64(canvas.toDataURL())
        }
    }, [id])

    return (
        <Block>
            <div style={style.wrapper}>
                <div style={style.left}>
                    <img src={base64} style={{ width: '92px', height: '92px' }} />
                </div>
                <div style={style.right}>
                <div style={style.title}>商家码</div>
                    <div>下载商家码让用户自助下单</div>
                    <a href={base64} download="store-qrcode.png" style={{ color: '#3958f7', marginTop: '7px' }}>下载</a>
                </div>
            </div>
            <div style={style.qr}>
                <QRCode
                    ref={qrcodeRef}
                    value={`${app.host}/storeDetail?id=${id}&product_type=1&order_type=2`}
                    size={500}
                />
            </div>
        </Block>
    )
}

const style = {
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        margin:'2px 0'
    },
    left: {},
    right: {
        marginLeft:'14px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '14px',
        lineHeight: 1.4
    },
    title: {
        color: '#333333',
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '8px',
        lineHeight: 1,
    },
    qr: {
        position: 'fixed',
        top: '200%',
        left: '200%',
        visibility: 'hidden'
    }
}

export default StoreQrcodeView