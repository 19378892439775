import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { Checkbox, Modal, message,Radio } from 'antd'
import { setSeparateUpdate,setMergeUpdate } from '@/apis/order'
import './splitModal.less'

const splitModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [info, setInfo]= useState({})
    const [goods, setGoods] = useState([])
    const splitid = info.split===1




    // 商品选中 change
    const onGoodChange = (index, is) => {
        const _list = [...goods]
        const item = _list[index]
        item.checked = is
        if (is && item.value === 0) {
            item.value = item.number
        }
        setGoods(_list)
    }

    const onSubmit = async() => {
        console.log(info)
        if (goods.every(t => !t.checked)) return message.warning('请选择拆单商品')
        try {
            const params = {
                order_id: info.order_id,
                ids: JSON.stringify(goods.filter(t => t.checked).map(t => ( t.id ))),
            }
            if(splitid){
                const res = await setSeparateUpdate(params)
            }else{
                const res = await setMergeUpdate(params)
            }
            
            message.success('提交成功')
            setVisible(false)
            typeof props.onOk === 'function' && props.onOk()
        } catch(e) {
            console.log(e)
        }
    }

    useImperativeHandle(ref, () => ({
        show: (data) => {
            const {list, ..._data} = data
            setGoods(list.map(t => ({
                id: t.id,
                cover: t.product_cover,
                name: t.product_name,
                price: +t.product_price,
                package_price: +t.package_price,
                number: t.number,
                value:  t.number,
                supplier:t.supplier,
                checked: false,
            })))
            setInfo(_data)
            setVisible(true)
        }
    }))
    const [value, setValue] = useState(1);

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

    return (
        <Modal
            open={visible}
            width={510}
            title={splitid?'拆单发货':'合单发货'}
            className="custom-modal refund-modal"
            centered
            okText={splitid?'拆单':'合单'}
            onOk={onSubmit}
            onCancel={() => setVisible(false)}
        >
            <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>商品</Radio>
                <Radio value={2}>供应商</Radio>
            </Radio.Group>
            <div className="refund-modal-title">选择商品<span>*</span></div>
            <div className="refund-modal-list">
                {goods.map((item, index) => (<div className="item-content" key={item.id}>
                    <Checkbox checked={item.checked} onChange={e => onGoodChange(index, e.target.checked)} />
                    <div className="item-media flex-shrink-0">
                        <img className="item-img" src={item.cover} alt="" />
                    </div>
                    <div className="item-inner flex-1">
                        <div className="item-title flex-space">
                            <div className="ell1">{item.name}</div>
                            <div className="item-price">¥{item.price}</div>
                        </div>
                        <div className="flex-space align-center">
                            <div className="item-num">x{item.number}</div>
                            {value===2&&<div className='item-num-buttom'>{item.supplier}</div>}
                        </div>
                    </div>
                </div>))}
            </div>

        </Modal>
    )
})

export default splitModal