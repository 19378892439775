import React, { useState } from "react";
import { Upload, Button, Message, Modal } from "antd";
import { getBase64 } from "@/utils";
import app from "../../app.json";
import ImgCrop from "antd-img-crop";

import lrz from "lrz";
import md5 from "md5";
import Cookies from "js-cookie";

import "antd/es/modal/style";
import "antd/es/slider/style";
import "./index.less";

const videoVerify = (file) => {
  const isVideo = file.type.indexOf("video") != -1;
  // const isLt10M = file.size / 1024 / 1024 < 100;
  if (!isVideo) {
    Message.error("只支持视频类型文件");
  }
  // if (!isLt10M) {
  //   Message.error("视频大小不能超过100m!");
  // }
  return isVideo;
};
const imgVerify = async (file) => {
  // const isImg = file.type.indexOf("image") != -1;
  // const isLt1M = file.size / 1024 / 1024 < 1;
  // if (!isImg) {
  //   Message.error("只支持图片类型文件");
  // }
  // if (!isLt1M) {
  //   Message.error("图片大小不能超过1m!");
  // }
  // return isImg && isLt1M;
  const p = await compress(file);

  //官方文档 ：参数为上传的文件，函数若返回 false 则停止上传。
  //支持返回一个 Promise 对象，
  //Promise 对象 reject 时则停止上传
  //resolve 时开始上传（ resolve 传入 File 或 Blob 对象则上传 resolve 传入对象）。

  return p;
};
const compress = (file) => {
  try {
    let ratio = 1;
    const { size } = file;

    //图片大于1M就压缩，否则不压缩
    if (size !== undefined && size > 1048576) {
      //将大于1M的图片，压缩后大致使图片大小保持在1M左右
      ratio = (1024 * 1024) / size + "";

      ratio = parseFloat(ratio).toFixed(2);

      console.log("开始压缩", ratio);
      return lrz(file, { quality: ratio, width: 1500 })
        .then((rst) => {
          //数字越小，压缩率越高
          console.log(rst);
          return backPromise(rst);
        })
        .catch(() => {
          console.log("压缩失败");
          return false;
        });
    } else {
      console.log("没压缩");
      return true;
    }
  } catch (e) {}
};
const backPromise = (res) => {
  return new Promise(function (resolve, reject) {
    if (res instanceof Object) {
      //将压缩后的base64字符串转换为文件对象

      let file = dataURLtoFile(res.base64, res.origin.name);
      console.log("base64tofile", file);

      //需要给文件对象一个唯一的uid属性否则将会报错

      Object.assign(file, { uid: file.lastModified });
      resolve(file);
      console.log("成功");
    } else {
      reject("压缩失败");
    }
  });
};

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(",");
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  //转换成file对象
  return new File([u8arr], filename, { type: mime });
  //转换成成blob对象
  //return new Blob([u8arr],{type:mime});
};

const FileUpload = (props) => {
  /**
   * type 1为图片上传（默认） 2为视频上传
   */
  const {
    type = 1,
    fileList = [],
    OSSData,
    listChange,
    onSuccess,
    children,
    maxLen = 1,
    disabled,
    needCrop = false,
    aspect = 1 / 1,
    multiple = false,
  } = props;
  const [showPreview, setShowPreview] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [_fileList, setFileList] = useState([]);
  const onChange = (e) => {
    setFileList(e.fileList);
    if (!e.file.status || e.file.status === "uploading") return;
    if (e.file.status === "removed") {
      // 删除
      let newList =
        type === 2
          ? []
          : e.fileList.filter((i) => {
              return i.uid !== e.file.uid;
            });
      listChange(newList);
    } else {
      let newList = [];

      // 添加
      e.fileList.forEach((item) => {
        if (item.url) {
          newList.push(item);
        } else {
          if (item.status && item.type) {
            const suffix = item.name.slice(item.name.lastIndexOf("."));
            item.url = `${app.oss_host}` + OSSData.dir + item.uid + suffix;
            newList.push(item);
          }
        }
      });
      console.log(newList, "-----e.fileList");
      if (onSuccess && e.file.status === "done") {
        onSuccess(newList);
      } else {
        listChange(newList);
      }
    }
  };

  React.useEffect(() => {
    setFileList(fileList);
  }, [fileList]);

  // 预览
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewUrl(file.url || file.preview);
    setShowPreview(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const onRemove = () => {};
  const getExtraData = (file) => {
    const suffix = file.name.slice(file.name.lastIndexOf("."));

    file.uid = md5(
      `${file.name}${new Date().getTime()}${Cookies.get("store_id") || 0}`
    );

    console.log(file, "-----extra data---------");
    return {
      key: OSSData.dir + file.uid + suffix,
      OSSAccessKeyId: OSSData.accessid,
      policy: OSSData.policy,
      Signature: OSSData.signature,
    };
  };

  const uploadMemo = () => {
    return (
      <Upload
        listType="picture-card"
        fileList={_fileList}
        disabled={disabled}
        multiple={multiple}
        maxCount={maxLen}
        accept={type === 1 ? 'image/*' : 'video/*'}
        action={`https://${OSSData.host}`}
        onPreview={onPreview}
        onChange={(e) => onChange(e, 1)}
        onRemove={() => onRemove()}
        beforeUpload={type === 1 ? imgVerify : videoVerify}
        data={(file) => getExtraData(file)}
      >
        {children ? (
          children
        ) : fileList.length >= maxLen ? null : (
          <Button className="upload-btn">选择文件</Button>
        )}
      </Upload>
    );
  };

  return (
    <div className="content file-content">
      <div className="upLoad-content bus-upload category-content_upload">
        {/* {(fileList || fileList.length < 1) && (
          <div className="upload-img">
            {fileList.length < 1 && (
              <div className="upload-img">
                {type === 2 ? (
                  <i className="iconfont">&#xe718;</i>
                ) : (
                  <i className="iconfont">&#xe717;</i>
                )}
              </div>
            )}
          </div>
        )} */}
        {needCrop ? (
          <ImgCrop aspect={aspect} rotate={true}>
            {uploadMemo()}
          </ImgCrop>
        ) : (
          uploadMemo()
        )}
        <Modal
          visible={showPreview}
          title={previewTitle}
          footer={null}
          onCancel={() => {
            setShowPreview(false);
          }}
        >
          {type === 2 ? (
            <video
              controls="controls"
              style={{ width: "100%" }}
              src={previewUrl}
            />
          ) : (
            <img alt="预览" style={{ width: "100%" }} src={previewUrl} />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default FileUpload;
