import React from "react";
import { Table, Button, Space, Form, Col, Row } from "antd";
import client from "@/graphql/apollo-config";
import { POSTCODE_LIST } from "@/graphql/store";
import moment from "moment";

const PaymentCode = (props) => {
  const { history } = props;
  const [dataSource, setDataSource] = React.useState([]);

  const [form] = Form.useForm();

  const requestData = React.useCallback(async () => {
    try {
      const {
        data: { posCodes },
      } = await client.query({
        query: POSTCODE_LIST,
        fetchPolicy: "no-cache",
      });
      setDataSource(posCodes);
    } catch (e) {}
  }, []);

  React.useEffect(() => {
    requestData();
  }, [requestData]);

  return (
    <div className="supplyChain-wrapper">
      <Form
        form={form}
        onFinish={(v) => {
          requestData();
        }}
      >
        <Row
          style={{
            margin: "10px 0",
          }}
        >
          <Col span={24}>
            <Button
              type="primary"
              onClick={() => history.push("/paymentCode/add")}
            >
              绑定
            </Button>
          </Col>
        </Row>

        <Table
          dataSource={dataSource}
          rowKey="id"
          columns={[
            {
              dataIndex: "id",
              title: "收款码ID",
              // render: (_, record) => {
              //   return record.store.pos_code;
              // },
            },
            {
              dataIndex: "name",
              title: "收款码名称",
            },

            {
              dataIndex: "bind_at",
              title: "添加时间",
              render: (value) => {
                return moment(value * 1000).format("YYYY-MM-DD hh:mm:ss");
              },
            },

            {
              dataIndex: "operation",
              title: "操作",
              render: (_, record) => (
                <Space size="middle">
                  <Button
                    type="link"
                    onClick={() =>
                      history.push(
                        `/paymentCode/add?record=${JSON.stringify({
                          name: record.name,
                          pos_code_id: record.id,
                        })}`
                      )
                    }
                  >
                    编辑
                  </Button>
                  <Button
                    type="link"
                    danger
                    onClick={() => history.push(`/download?id=${record.code}`)}
                  >
                    查看
                  </Button>
                </Space>
              ),
            },
          ]}
        />
      </Form>
    </div>
  );
};

export default PaymentCode;
