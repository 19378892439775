import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Input,
  Space,
  DatePicker,
  InputNumber,
  Message,
  Row,
  Col,
  Select,
} from "antd";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import { useLazyQuery } from "react-apollo";
import moment from "moment";
import ExportJsonExcel from "js-export-excel";
import { Get_ScanPayRecord_Query } from "@/graphql/products";
import client from "@/graphql/apollo-config";
import { USER } from "@/graphql/finance";
import { POSTCODE_LIST } from "@/graphql/store";

const { RangePicker } = DatePicker;

const Record = () => {
  const [tabData, setTabData] = useState([]);
  const [current, setCurrent] = useState("");
  const [total, setTotal] = useState("");
  const [posCodeList, setPosCodeList] = useState([]);
  const [sendData, setData] = useState({
    store_id: null,
    order_no: null,
    created_at_start: null,
    created_at_end: null,
    total_start: null,
    total_end: null,
    rebate_hongbao_start: null,
    rebate_hongbao_end: null,
    hongbao_start: null,
    hongbao_end: null,
    actual_amount_start: null,
    actual_amount_end: null,
    limit: 10,
    page: 1,
    pos_code_name: null,
  });

  const [statusTitles, setStatusTitles] = useState([
    { text: "已完成", value: "1" },
  ]);

  useEffect(() => {
    requestStoreId();
    getPosCodeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendData]);

  const requestStoreId = async () => {
    try {
      const { data } = await client.query({
        query: USER,
        fetchPolicy: "no-cache",
      });
      console.log("d", data.user.store.id);
      if (data.user.store.id) {
        setData({ ...sendData, store_id: data.user.store.id });
      } else {
        Message.info("您还没有店铺!");
      }
    } catch (e) {}
  };

  const getInfo = async () => {
    if (!sendData.store_id) {
      return;
    }
    try {
      const { data } = await client.query({
        query: Get_ScanPayRecord_Query,
        variables: sendData,
        fetchPolicy: "no-cache",
      });
      data.StoreOrderQr.data.map((i) => {
        i.key = i.id + " ";
        i.pay_at = getDate(i.pay_at);
        i.received_at = getDate(i.received_at);
        if (i.pay_type == 0) {
          i.pay_type = "微信支付";
        } else {
          i.pay_type = "支付宝支付";
        }
        i.name = i.user && i.user.username ? i.user.username : "";
        i.pay_amount = (i.total - i.hongbao).toFixed(2);
        return i;
      });
      setTabData([...data.StoreOrderQr.data]);
      setCurrent(data.StoreOrderQr.current_page);
      setTotal(data.StoreOrderQr.total);
    } catch (error) {}
  };

  // 处理请求返回的数据
  const getDataSource = (data) => {
    const publicData = data.StoreOrderQr.data.map((item, index) => ({
      key: index + "",
      id: item.id,
      order_no: item.order_no,
      pay_at: getDate(item.created_at),
      total: item.total,
      pay_amount: (item.total - item.hongbao).toFixed(2),
      rebate_hongbao: item.rebate_hongbao,
      hongbao: item.hongbao,
      received_at: getDate(item.received_at),
      actual_amount: item.actual_amount,
      hongbao_subsidy: item.hongbao_subsidy,
      pay_type: item.pay_type == 0 ? "微信支付" : "支付宝支付",
      name: item.user && item.user.username ? item.user.username : "",
    }));

    return publicData;
  };

  // 处理时间
  const getDate = (date) => {
    if (date === 0) return "-";
    return moment(date * 1000).format("YYYY-MM-DD HH:mm:ss");
  };

  const getColumnSearchProps = (dataIndex) => {
    let searchInput;
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`搜索`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, height: 27 }}
              >
                搜索
              </Button>
              <Button
                onClick={() =>
                  handleReset(selectedKeys, clearFilters, dataIndex)
                }
                size="small"
                style={{ width: 90, height: 27 }}
              >
                重置
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#FFD600" : undefined }} />
      ),
    };
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    console.log(selectedKeys[0], selectedKeys[0] !== undefined);
    if (selectedKeys[0] !== undefined) {
      if (dataIndex == "order_no") {
        setData({
          ...sendData,
          order_no: selectedKeys[0],
          page: 1,
        });
      }
    }
  };
  const handleReset = (selectedKeys, clearFilters, dataIndex) => {
    clearFilters();
    if (selectedKeys[0] !== undefined) {
      if (dataIndex == "order_no") {
        setData({
          ...sendData,
          order_no: null,
          page: 1,
        });
      }
    }
  };

  const getColumnDataProps = (col) => {
    const dateFormat = "YYYY/MM/DD";
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <div>
            <RangePicker
              showTime={{ format: "HH:mm:ss" }}
              value={selectedKeys}
              format={dateFormat}
              onChange={(value, dateString) =>
                onChangeData(
                  value,
                  dateString,
                  confirm,
                  clearFilters,
                  setSelectedKeys,
                  col
                )
              }
            />
            <Space style={{ marginLeft: 20 }}>
              <Button
                onClick={() => handleResetData(clearFilters)}
                size="small"
                style={{ width: 60, height: 25 }}
              >
                重置
              </Button>
            </Space>
          </div>
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined style={{ color: filtered ? "#FFD600" : undefined }} />
      ),
    };
  };

  const handleResetData = (clearFilters) => {
    clearFilters();
    setData({
      ...sendData,
      created_at_start: null,
      created_at_end: null,
    });
  };

  const onChangeData = (
    value,
    dateString,
    confirm,
    clearFilters,
    setSelectedKeys
  ) => {
    if (value !== null) {
      confirm();
      setSelectedKeys([value[0], value[1]]);
      setData({
        ...sendData,
        created_at_start: value[0].unix(),
        created_at_end: value[1].unix(),
        page: 1,
      });
    } else {
      clearFilters();
      setSelectedKeys([null, null]);
      setData({
        ...sendData,
        created_at_start: null,
        created_at_end: null,
        page: 1,
      });
    }
  };

  const onChange = (filters, dataIndex, action) => {
    if (action.action == "paginate") return;
    if (filters.order_no !== null) {
      setData({
        ...sendData,
        order_no: filters.order_no[0],
        page: 1,
      });
    } else {
      setData({
        ...sendData,
        order_no: null,
        page: 1,
      });
    }
  };

  const onChangePagination = (page) => {
    setData({
      ...sendData,
      page: page,
    });
  };

  const getColumnNumberProps = (col) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <InputNumber
                value={selectedKeys[0]}
                onChange={(value) => {
                  selectedKeys[0] = value;
                  setSelectedKeys(selectedKeys);
                }}
                style={{ width: 88, marginBottom: 8, display: "block" }}
              />
              <span>-</span>
              <InputNumber
                value={selectedKeys[1]}
                onChange={(value) => {
                  selectedKeys[1] = value;
                  setSelectedKeys(selectedKeys);
                }}
                style={{ width: 88, marginBottom: 8, display: "block" }}
              />
            </div>

            <Space>
              <Button
                type="primary"
                onClick={() => numberSearch(selectedKeys, confirm, col)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, height: 27 }}
              >
                搜索
              </Button>
              <Button
                onClick={() => numberReset(clearFilters, col)}
                size="small"
                style={{ width: 90, height: 27 }}
              >
                重置
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#FFD600" : undefined }} />
      ),
    };
  };
  const numberSearch = (selectedKeys, confirm, col) => {
    confirm();
    console.log(selectedKeys[0], selectedKeys[1]);
    if (col == "total") {
      setData({
        ...sendData,
        total_start: selectedKeys[0],
        total_end: selectedKeys[1],
        page: 1,
      });
    } else if (col == "actual_amount") {
      setData({
        ...sendData,
        actual_amount_start: selectedKeys[0],
        actual_amount_end: selectedKeys[1],
        page: 1,
      });
    } else if (col == "rebate_hongbao") {
      setData({
        ...sendData,
        rebate_hongbao_start: selectedKeys[0],
        rebate_hongbao_end: selectedKeys[1],
        page: 1,
      });
    } else if (col == "hongbao") {
      setData({
        ...sendData,
        hongbao_start: selectedKeys[0],
        hongbao_end: selectedKeys[1],
        page: 1,
      });
    }
  };

  const numberReset = (clearFilters, col) => {
    clearFilters();
    if (col == "total") {
      setData({
        ...sendData,
        total_start: null,
        total_end: null,
        page: 1,
      });
    } else if (col == "actual_amount") {
      setData({
        ...sendData,
        actual_amount_start: null,
        actual_amount_end: null,
        page: 1,
      });
    } else if (col == "rebate_hongbao") {
      setData({
        ...sendData,
        rebate_hongbao_start: null,
        rebate_hongbao_end: null,
        page: 1,
      });
    } else if (col == "hongbao") {
      setData({
        ...sendData,
        hongbao_start: null,
        hongbao_end: null,
        page: 1,
      });
    }
  };
  const columns = [
    {
      title: "交易编号",
      dataIndex: "order_no",
      ...getColumnSearchProps("order_no"),
    },
    {
      title: "收款码名称",
      dataIndex: "pos_code_name",
    },
    {
      title: "付款时间",
      dataIndex: "pay_at",
      ...getColumnDataProps("order_created_at"),
    },
    {
      title: "订单金额（¥）",
      dataIndex: "total",
    },
    {
      title: "实付金额（¥）",
      dataIndex: "pay_amount",
    },
    {
      title: "返红包",
      dataIndex: "rebate_hongbao",
      ...getColumnNumberProps("rebate_hongbao"),
    },
    {
      title: "使用红包",
      dataIndex: "hongbao",
      ...getColumnNumberProps("hongbao"),
    },
    {
      title: "实际到账",
      dataIndex: "actual_amount",
      ...getColumnNumberProps("actual_amount"),
    },
    {
      title: "红包补贴",
      dataIndex: "hongbao_subsidy",
      ...getColumnNumberProps("hongbao_subsidy"),
    },
    {
      title: "到账时间",
      dataIndex: "received_at",
      // ...getColumnDataProps('received_at'),
    },
    {
      title: "支付方式",
      dataIndex: "pay_type",
    },
    {
      title: "用户昵称",
      dataIndex: "name",
    },
  ];

  const [getAllData] = useLazyQuery(Get_ScanPayRecord_Query, {
    variables: {
      ...sendData,
      page: 1,
      limit: total,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data) {
        exportExcel(data);
      }
    },
  });

  const exportExcel = (data) => {
    let option = {};
    option.fileName = new Date().getTime();
    option.datas = [
      {
        sheetData: getDataSource(data),
        sheetName: "sheet",
        sheetHeader: columns.map((i) => i.title),
        sheetFilter: columns.map((i) => i.key),
        columnWidths: Array(15).fill("10"),
      },
    ];
    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  const getPosCodeList = React.useCallback(async () => {
    try {
      const {
        data: { posCodes },
      } = await client.query({
        query: POSTCODE_LIST,
        fetchPolicy: "no-cache",
      });
      setPosCodeList(posCodes);
    } catch (e) {}
  }, []);

  return (
    <div className="order">
      <Row
        style={{
          marginBottom: 20,
        }}
      >
        <Col span={3}>
          <Select
            placeholder="请选择收款码"
            style={{
              width: "100%",
            }}
            value={sendData.pos_code_name}
            options={posCodeList.map((item) => ({
              label: item.name,
              value: item.name,
            }))}
            allowClear
            onClear={() =>
              setData({
                ...sendData,
                pos_code_name: "",
              })
            }
            onChange={(e) =>
              setData({
                ...sendData,
                pos_code_name: e,
                page: 1,
              })
            }
          ></Select>
        </Col>
        <Col push={1}>
          {/* <Button type="primary" onClick={() => onChangePagination(1)}>
            <span style={{ color: "white" }}>搜索</span>
          </Button> */}
          <Button
            type="primary"
            onClick={getAllData}
            style={{ marginLeft: 10 }}
          >
            <span style={{ color: "white" }}>导出Excel</span>
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={tabData}
        scroll={{
          x: true,
          scrollToFirstRowOnChange: true,
        }}
        rowKey="created_at"
        pagination={{
          position: ["bottomCenter"],
          defaultCurrent: 1,
          defaultPageSize: 10,
          current: current,
          total: total,
          onChange: onChangePagination,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={(_, filters, dataIndex, action) =>
          onChange(filters, dataIndex, action)
        }
      />
    </div>
  );
};

export default Record;
