import React, { useState, useEffect } from "react";
import "./index.less";
import { Table, Button, Message, Select, Input, Modal } from "antd";
import { COUPON_LIST, STOP_COUPON } from "../../graphql/coupon";
import client from "../../graphql/apollo-config";
import moment from "moment";

const HeaderCell = [
  {
    title: "创建时间",
    dataIndex: "created_at",
  },
  {
    title: "红包券名称",
    dataIndex: "name",
  },
  {
    title: "红包券类型",
    dataIndex: "type",
  },
  {
    title: "状态",
    dataIndex: "status",
  },
  {
    title: "发放周期",
    dataIndex: "time",
  },
  {
    title: "有效周期",
    dataIndex: "validity",
  },
  {
    title: "红包券金额",
    dataIndex: "money",
  },
  {
    title: "折扣比例",
    dataIndex: "discount",
  },
  // {
  //   title: "备注",
  //   dataIndex: "remake",
  // },
  {
    title: "操作",
    dataIndex: "optional",
  },
];

const statusTypes = [
  {
    label: "未开始",
    value: 1,
  },
  {
    label: "进行中",
    value: 2,
  },
  {
    label: "已结束",
    value: 3,
  },
];

const TYPE_MAP = {
  1: "满减券",
  2: "折扣券",
};

const Coupon = (props) => {
  const { history } = props;
  const [datas, setDatas] = useState([]);
  const [pageInfo, setpageInfo] = useState({
    limit: 15,
    page: 1,
  });
  const [pagenum, setPagenum] = useState(1);
  const [dataStatus, setDataStatus] = useState(1);
  const [serchName, setSerchName] = React.useState("");

  const requestData = React.useCallback(
    async (name) => {
      try {
        const {
          data: {
            storeCoupon: { data: datas, last_page },
          },
        } = await client.query({
          query: COUPON_LIST,
          variables: {
            ...pageInfo,
            status: dataStatus,
            ...(name
              ? {
                  name,
                }
              : {}),
          },
          fetchPolicy: "no-cache",
        });
        setDatas(datas);
        setPagenum(last_page);
      } catch (e) {}
    },
    [dataStatus, pageInfo]
  );

  useEffect(() => {
    requestData();
  }, [requestData]);

  //编辑
  const edit = (record) => {
    history.push(`/coupon/addCoupon?id=${record.id}`);
  };

  const onChange = (page) => {
    pageInfo.page = page;
    setpageInfo(pageInfo);
    requestData();
  };

  //禁用
  const setStatus = async (record) => {
    try {
      const { data } = await client.mutate({
        mutation: STOP_COUPON,
        variables: {
          coupon_id: +record.id,
        },
      });
      if (data.stopStoreCoupon) {
        Message.success("设置成功");
        requestData();
      }
    } catch (error) {}
  };

  const optionalRender = (_, record) => {
    return (
      <div className="optional">
        <span className="edit" onClick={() => edit(record)}>
          查看
        </span>
        {!record.is_termination && (
          <span
            className="preview"
            style={{
              color: "red",
            }}
            onClick={() => {
              Modal.confirm({
                content: "是否要终止红包券",
                okText: "确定",
                onOk() {
                  setStatus(record);
                },
              });
            }}
          >
            终止
          </span>
        )}
      </div>
    );
  };

  const columns1Getter = HeaderCell.map((col) => {
    switch (col.dataIndex) {
      case "optional":
        col.render = optionalRender;
        break;
      case "created_at":
        col.render = (_, record) => {
          return moment(record.created_at * 1000).format("YYYY-MM-DD HH:mm:ss");
        };
        break;
      case "money":
        col.render = (_, record) => {
          return record.type === 1 ? record.money : "-";
        };
        break;
      case "discount":
        col.render = (_, record) => {
          return record.type === 2 ? `${record.money / 10}折` : "-";
        };
        break;
      case "type":
        col.render = (_, record) => {
          return TYPE_MAP[record.type];
        };
        break;
      case "status":
        col.render = () => {
          return statusTypes.find((item) => item.value === dataStatus).label;
        };
        break;
      case "validity":
        col.render = (_, record) => {
          return record.validity + "天";
        };
        break;
      case "time":
        col.render = (_, record) => {
          return (
            moment(record.start_time * 1000).format("YYYY-MM-DD") +
            "~" +
            moment(record.end_time * 1000).format("YYYY-MM-DD")
          );
        };
        break;
      default:
        break;
    }
    col.align = "center";
    return col;
  });

  const TableProps = {
    columns: columns1Getter,
    dataSource: datas,
    bordered: true,
    pagination: {
      position: ["bottomCenter"],
      defaultCurrent: 1,
      defaultPageSize: pageInfo.limit,
      onChange: onChange,
      showSizeChanger: false,
      showQuickJumper: true,
      total: pagenum * pageInfo.limit,
      current: pageInfo.page,
    },
    scroll: {
      x: "max-content",
      scrollToFirstRowOnChange: true,
    },
  };

  const add = () => {
    history.push("/coupon/addCoupon");
  };

  return (
    <div className="product-wrapper">
      <div
        style={{
          marginBottom: 20,
        }}
      >
        <Button type="primary" onClick={add}>
          新增红包券
        </Button>
        <Select
          style={{
            width: 120,
            margin: "0 10px",
          }}
          value={dataStatus}
          onChange={(v) => {
            setDataStatus(v);
            setSerchName("");
            setpageInfo({
              ...pageInfo,
              page: 1,
            });
            requestData();
          }}
        >
          {statusTypes.map((item) => (
            <Select.Option value={item.value} key={item.label}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div>
        <Input
          placeholder="请输入红包券名称"
          style={{
            width: 230,
            marginRight: 10,
          }}
          value={serchName}
          allowClear
          onChange={({ target: { value } }) => setSerchName(value)}
        />
        <Button type="primary" onClick={() => requestData(serchName)}>
          确定
        </Button>
      </div>
      <Table {...TableProps} />
    </div>
  );
};

export default Coupon;
