// 柯里化( Currying )
const curryingCheck = reg => txt => reg.test(txt);

export const isPhone = curryingCheck(/^1[123456789]\d{9}$/);
export const isEmail = curryingCheck(/^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/);

//输入的字符是否为电话包含的字符
export const isInputTelephoneText = curryingCheck(/^[\d+-]*$/);

//输入的字符是否为Email包含的字符
export const isInputEmailText = curryingCheck(/^[\d\w@._-]*$/);

//输入的字符是否为不包含小数点的数字
export const isInputNumText = curryingCheck(/^[\d]*$/);

//统一社会信用代码
export const isSocialCreditCode = curryingCheck(/^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/);

