import React, { useState, useEffect, useMemo, useRef } from "react";
import { Layout, Menu, Dropdown, Badge } from "antd";
import { Link } from "react-router-dom";
import { CaretDownOutlined } from "@ant-design/icons";
import { withRouter } from "react-router";
import { RouterList as initRouterList, order_children } from "./router";
import { traverseArray, isNonEmptyArray } from "./utils";
import "../src/assets/less/common.less";
import "./app.less";
import Router from "./router";
import Cookies from "js-cookie";
import { USER } from "./graphql/router";
import { useLazyQuery } from "react-apollo";
import Context from "./utils/context";
import { WebSocketProvider, useWebSocket } from './WebSocketContext';

//热更新包裹器
import { hot } from "react-hot-loader/root";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const noBarPage = ["login", "forgetpwd", "resources"];
// 侧边栏 MenuItem
const MenuItem = (item, orders) => {
  return (
    <Menu.Item key={item.path} title={item.title}>
      <Link to={item.path}>{item.title}</Link>
      {item.title === '外卖配送订单' && <Badge count={orders.wm_order_new_num} style={{ boxShadow: 'none', marginLeft: 16 }} />}
      {item.title === '外卖自取订单' && <Badge count={orders.wm_take_order_num} style={{ boxShadow: 'none', marginLeft: 16 }} />}
      {item.title === '普通物流订单' && <Badge count={orders.logistics_order_new_num} style={{ boxShadow: 'none', marginLeft: 16 }} />}
      {item.title === '扫码点餐订单' && <Badge count={orders.scan_code_to_order} style={{ boxShadow: 'none', marginLeft: 16 }} />}
      {item.title === '退款/退货管理' && <Badge count={orders.refund_order_num} style={{ boxShadow: 'none', marginLeft: 16 }} />}
    </Menu.Item>
  );
};
// 侧边栏 MenuList 递归出所有子项
const siderListDom = (siderList, orders) => {
  return siderList.map((item) => {
    return item.hidden ? null : !item.children ? (
      MenuItem(item, orders)
    ) : (
      <SubMenu className="subMenu" key={item.path} title={item.title === '订单管理' ? (<>
        <span>{item.title}</span>
        <Badge count={orders.all_order_num} style={{ boxShadow: 'none', marginRight: 10 }} />
      </>) : item.title}>
        {siderListDom(item.children, orders)}
      </SubMenu>
    );
  });
};

const MenuListDom = ({ routerList, selectedKeys, defaultOpenKeys, onSelect }) => {
  const { orders } = useWebSocket()

  return (
    <Menu
      mode="inline"
      theme="dark"
      selectedKeys={selectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onSelect={onSelect}
      className="app-ant-menu-dark"
      style={{
        //height: "100%",
        minHeight: "100%",
        borderRight: 0,
      }}
    >
      {siderListDom(routerList, orders)}
    </Menu>
  )
}

const pathname = window.location.pathname;
const selectedList = traverseArray(initRouterList, pathname, "path");
const defaultRouterTopList = selectedList; // 页面顶部默认路由列表
const defaultOpenKeys = selectedList.map((item) => item.openKeys || item.path); //默认展开的侧边栏项
const ordersInterval = 3000;
const showRoute = [
  "首页",
  "入驻信息",
  "特约商户申请",
  // "线上开店认证",
  "重置密码",
  "登录",
];
const showRoute2 = [
  "首页",
  "入驻信息",
  "特约商户申请",
  // "线上开店认证",
  "重置密码",
  "登录",
  "赏金计划设置",
  "下载收款码",
  "扫码付款记录",
  "赏金计划收入明细",
  "邀请人数",
  "返红包记录",
];
// const mp3 = new Audio(require("./assets/mp3/neworders.mp3"));
// 函数组件 app

const App = (props) => {
  const [token, setToken] = useState(() => Cookies.get("token"));
  const [storeId, setStoreId] = useState(() => Cookies.get('store_id'));
  const [cuetone, setCuetone] = useState(() => {
    const cuetone = Cookies.get("cuetone");
    return (cuetone && JSON.parse(cuetone)) || 0;
  });

  const [getUser, { data }] = useLazyQuery(USER, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      setStoreId(data.user.store ? data.user.store.id : 0)
      Cookies.set("store_id", (data.user.store && data.user.store.id) || null);
      Cookies.set(
        "is_spend",
        (data.user.store && data.user.store.is_spend) || null
      );
      Cookies.set(
        "delivery_type",
        data.user.store ? Number(data.user.store.delivery_type) : null
      );
    },
    onError: () => {},
  });

  useEffect(() => {
    if (token) getUser();
  }, [token]);

  const RouterList = useMemo(() => {
    // 没有审核信息或者基本信息不是首次提交
    if (data && data.user) {
      const user = data.user.store;
      if (
        !user ||
        (user.store_base_audit && user.store_base_audit.is_first_audit)
      ) {
        let temp = [];
        initRouterList.forEach((i) => {
          if (showRoute.find((s) => s === i.title)) {
            temp.push(i);
          }
        });
        return temp;
      } else {
        // 基本信息审核通过但没有店铺信息审核或者门店信息审核不通过
        if (!user.store_audit) {
          let temp = [];
          initRouterList.forEach((i) => {
            if (showRoute2.find((s) => s === i.title)) {
              temp.push(i);
            }
          });
          return temp;
        } else {
          const type = user.product_type;
          Cookies.set("express", user.express);
          Cookies.set("store_type", type);
          const temp = initRouterList.map((i) => {
            if (i.title === "订单管理") {
              if (type === 0) {
                i.children = order_children.filter((item) =>
                  [
                    '外卖配送订单',
                    '外卖自取订单',
                    "同城配送订单",
                    "普通物流订单",
                    "扫码点餐订单",
                    "退款/退货管理",
                  ].includes(item.title)
                );
              } else {
                i.children = order_children.filter((item) =>
                  ["电子卡券订单", "退款/退货管理"].includes(item.title)
                );
              }
            }
            return i;
          });
          const hideRouter = ["商品类目", "物流设置", "桌台管理"];
          let temp2 = temp.filter(
            (i) => (type === 1 && !hideRouter.includes(i.title)) || type === 0
          );
          // if (type === 1) {
          //   temp2 = temp2.filter((i) => i.title !== "设置");
          // }
          temp2 = temp2.filter((item) => item.title !== "线上开店认证");
          temp2 = temp2.filter((item) =>
            item.title === "供应商管理" ? data.user.store.open_supplier : true
          );
          temp2 = temp2.filter(item=> item.title !== '入驻信息')

          return temp2;
        }
      }
    }
    return initRouterList;
  }, [data]);

  // 侧边栏选中的key
  const [selectedKeys, setSelectedKeys] = useState([
    selectedList.length
      ? selectedList[selectedList.length - 1].activeMenu ||
        selectedList[selectedList.length - 1].path
      : "",
  ]);
  useEffect(() => {
    // 路由监听
    props.history.listen((location, action) => {
      const traverseKeys = traverseArray(RouterList, location.pathname, "path");
      if (isNonEmptyArray(traverseKeys)) {
        const len = traverseKeys.length;
        setRouterTopList(traverseKeys);
        setSelectedKeys([
          traverseKeys[len - 1].activeMenu || traverseKeys[len - 1].path,
        ]);
        // location是location对象
        // action是动作名称，比如 "PUSH"
      }
    });
  }, []);

  // 页面顶部路由列表
  const [RouterTopList, setRouterTopList] = useState(defaultRouterTopList);

  // 侧边栏选中事件
  const onSelect = ({ key }) => {
    // setSelectedKeys([key]);
  };
  const loginOut = () => {
    Cookies.remove("token");
    Cookies.remove("mobile");
    Cookies.remove("store_id");
    localStorage.removeItem("join_store");
    localStorage.removeItem("store");
    setToken(null);
    setStoreId(0)
    props.history.push("/login");
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <span
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => loginOut()}
        >
          退出登录
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
  <Context.Provider value={{ token, storeId, cuetone, setToken, setCuetone }}>
    <WebSocketProvider>
      <div>
        {noBarPage.find(
          (item) => window.location.pathname.indexOf(item) !== -1
        ) ? (
          <Router />
        ) : (
          <div className="app-ant-layout">
            <Layout style={{ height: "100vh", overflow: "hidden" }}>
              <Header
                style={{
                  paddingLeft: "194px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="header-left">
                  <img
                    style={{
                      height: "36px",
                      marginRight: "18px",
                    }}
                    alt="logo"
                    src={require("./assets/images/logo2.png")}
                  />
                  <Link to="/" style={{ fontSize: "22px" }} className="black">
                    商家管理后台
                  </Link>
                  <span className="store_name">{Cookies.get("store_name")}</span>
                </div>
                <div className="header-right">
                  <Badge
                    className="header-right_msg"
                    count={data?.user.unsign_count || 0}
                  >
                    <Link to="/messages" className="header-icon">
                      <i className="iconfont">&#xe66a;</i>
                    </Link>
                  </Badge>
                  <span
                    className="black"
                    style={{
                      marginRight: "10px",
                      fontSize: "14px",
                    }}
                  >
                    欢迎您
                  </span>
                  <Dropdown overlay={menu}>
                    <a
                      href="/#"
                      className="ant-dropdown-link black fz12"
                      onClick={(e) => e.preventDefault()}
                    >
                      {Cookies.get("mobile")}{" "}
                      <CaretDownOutlined
                        style={{
                          marginLeft: "2px",
                          fontSize: "14px",
                        }}
                      />
                    </a>
                  </Dropdown>
                </div>
              </Header>
              <Layout>
                <Sider width={220} style={{ overflow: "auto" }} theme="dark">
                  <MenuListDom
                    routerList={RouterList}
                    selectedKeys={selectedKeys}
                    defaultOpenKeys={defaultOpenKeys.flat()}
                    onSelect={onSelect}
                  />
                </Sider>
                <Layout
                  style={{
                    padding: "24px",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                  }}
                >
                  <Content style={{ flex: 'none' }}>
                    <Router />
                  </Content>
                  {/* <Sider width={272} style={{backgroundColor:'#f0f2f5',opacity:'grey',marginLeft:'40px'}}>
                    <Content>
                       <Contacts></Contacts>
                    </Content>

                  </Sider> */}
                </Layout>
              </Layout>
            </Layout>
          </div>
        )}
      </div>
    </WebSocketProvider>
  </Context.Provider>
  );
};

export default hot(withRouter(App));
