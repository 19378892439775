import React,{ useState,useEffect } from 'react';
import { Switch, Input, Select, Radio, Message } from 'antd';
import LoginLayout from "../../components/LoginLayout.js";
import { MY_CONFIG } from "../../graphql/login";
import './index.less';
import client from "../../graphql/apollo-config";

const Resources = (props) => {
    const [res, setRes] = useState('')

    useEffect(() => {
        getRes()
    }, []);

    const getRes = async() =>{
        try {
            const { data } = await client.query({
                query: MY_CONFIG,
                variables:{
                    name:props.location.search.slice(6)
                },
                fetchPolicy: "no-cache"
            });
            if (data){
                setRes(data.myConfig)
            }
        } catch (error) {}
    }
    return (
        <LoginLayout>
            <div className='resources-wrapper'>
                <div dangerouslySetInnerHTML={{ __html: res }} />
            </div>
        </LoginLayout>
    )
};

export default Resources;
