/*
 * @Author: Fan
 * @Date: 2020-12-01 10:28:05
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-23 09:33:33
 * @Description: file content
 */
import gql from "graphql-tag";
export const USER = gql`
  query {
    user {
      id
      unsign_count
      store {
        id
        product_type
        is_spend
        express {
          code
          name
        }
        open_supplier
        cover {
          url
        }
        store_base_audit {
          status
          is_first_audit
        }
        store_base_platform_audit {
          status
          is_first_audit
        }
        is_first_store_audit
        store_audit {
          status
        }
      }
    }
  }
`;
