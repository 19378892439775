import React from "react";
// import { ActivityIndicator } from 'antd';
import { Layout } from "antd";
const { Header, Content } = Layout;
const LoginLayout = props => {
  return (
    <div>
      <Header style={style.headerStyle}>
        <div>
          <img
            style={style.headerLogo}
            alt="logo"
            src={require("../assets/images/logo2.png")}
          />
          <span style={{ fontSize: "18px", color: "#000" }}>
            商家管理后台
          </span>
        </div>
        <div>
          <span
            style={{ marginRight: "10px", fontSize: "12px", color: "#000" }}
          >
            欢迎登录{" "}
          </span>
        </div>
      </Header>
      <Content className="primaryBg" style={style.loginLayout}>
        {" "}
        <div style={style.loginLayoutPTop}>{props.children}</div>
      </Content>
    </div>
  );
};

const style = {
  headerStyle: {
    background: "#fff",
    paddingLeft: "154px",
    display: "flex",
    justifyContent: "space-between"
  },
  headerLogo: {
    height: "40px",
    marginRight: "18px"
  },
  colorBlack: {
    color: "#000"
  },
  loginLayout: {
    minHeight: "calc(100vh - 45px)"
  },
  loginLayoutPTop:{
    paddingTop:"160px"
  }
};

export default LoginLayout;
