import React, { useEffect, useMemo, useState } from 'react'
import { Table, Spin, Button } from 'antd'
import { getCouponDeduction } from '@/apis/mealCoupon'
import { formatAmount, toExportExcel } from '@/utils'
import './index.less'

const DeductCouponRecord = ({ history }) => {
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [isMainStore, setIsMainStore] = useState(1)
    const columns = useMemo(() => {
        return isMainStore ? [
            {
                title: '年月',
                dataIndex: 'month'
            },
            {
                title: '商家账号',
                dataIndex: 'store_mobile'
            },
            {
                title: '商家名称',
                dataIndex: 'short_name'
            },
            {
                title: '抵扣餐券金额',
                dataIndex: 'deduction_vouchers_total_amount',
                render: (text, record, index) => `¥${formatAmount(text)}`
            },
            {
                title: '抵扣餐券订单数',
                dataIndex: 'deduction_vouchers_order_num',
            },
            {
                title: '操作',
                dataIndex: 'oper',
                render: (text, record, index) => {
                    return (<span className="oper-btn" onClick={() => {
                        history.push(`/deductCouponRecord/detail?store_id=${record.store_id}`);
                    }}>查看明细</span>)
                }
            }
        ] : [
            {
                title: '年月',
                dataIndex: 'month'
            },
            {
                title: '抵扣餐券金额',
                dataIndex: 'deduction_vouchers_total_amount',
                render: (text, record, index) => `¥${formatAmount(text)}`
            },
            {
                title: '抵扣餐券订单数',
                dataIndex: 'deduction_vouchers_order_num',
            },
            {
                title: '操作',
                dataIndex: 'oper',
                render: (text, record, index) => {
                    return (<span className="oper-btn" onClick={() => {
                        history.push(`/deductCouponRecord/detail?store_id=${record.store_id}`);
                    }}>查看明细</span>)
                }
            }
        ]
    }, [isMainStore])

    useEffect(() => {
        getData()
    }, [])

    const getData = async() => {
        try {
            setLoading(true)
            const { data } = await getCouponDeduction().finally(() => {
                setLoading(false)
            })
            setIsMainStore(data.is_main_store)
            setList(data.list)
        } catch(e) {
            console.log(e)
        }
    }

    // 导出Excel
    const onExportExcel = () => {
        if (loading) return;
        const c = columns.filter(t => t.dataIndex != 'oper')
        const header = c.map(t => t.title)
        const filter = c.map(t => t.dataIndex)
        const data = list.map(t => {
            t.deduction_vouchers_total_amount = `¥${formatAmount(t.deduction_vouchers_total_amount)}`
            return t
        })
        toExportExcel({
            fileName: `它店餐券抵扣${Date.now()}`,
            data,
            header,
            filter
        })
    }

    return (
        <Spin spinning={loading}>
            <div className="mealCoupon-title">
                <div className="title">它店发放的餐券在本店抵扣列表</div>
                <Button type="primary" style={{ marginLeft: 'auto' }} onClick={onExportExcel}>导出Excel</Button>
            </div>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={list}
                bordered
                pagination={false}
            />
        </Spin>
    )
}

export default DeductCouponRecord