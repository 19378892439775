import React,{ useState,useEffect } from 'react';
import { Upload,Modal,Button,Message } from 'antd';
import {getBase64} from "../../utils";
import app from "../../app.json";
import ImgCrop from 'antd-img-crop';
import 'antd/es/modal/style';
import 'antd/es/slider/style';

const UploadImg = (props) => {
  const {portrait,changePortrait, OSSData } = props

  // 图片上传
  const [previewImg, setPreviewImg] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  // 店铺主图
  const handlePreview = async (file,i)=> {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview)
    setPreviewImg(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  };

  const onChange = (e,i) => {
    console.log('Aliyun OSS:',e.file)
    if (e.file.status !== 'removed'){
      const isLt2M = e.file.size / 1024 / 1024 < 1

      if (!isLt2M && e.file.type.indexOf("image") != -1) {
        Message.error('图片大小不能超过1m');
        return
      }
    }
    let b = []
    if (e.file.status === 'removed'){
      // 删除
      b = e.fileList.filter((i) =>{return i.uid != e.file.uid})
      changePortrait(b)
    } else{
      // 添加
      if (e.file.type.indexOf("image") != -1 ){
        for (let i = 0;i < e.fileList.length;i++){
          if (e.fileList[i].type){
            const suffix = e.fileList[i].name.slice(e.fileList[i].name.lastIndexOf('.'));
            e.fileList[i].url = `${app.oss_host}` + OSSData.dir + e.fileList[i].uid + suffix;
          }
        }
        changePortrait(e.fileList)
      } else{
        Message.info('请上传图片格式的文件')
      }
    }
  };
  const getExtraData = (file) => {
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    return {
      key: OSSData.dir + file.uid + suffix,
      OSSAccessKeyId: OSSData.accessid,
      policy: OSSData.policy,
      Signature: OSSData.signature,
    };
  };
  const onRemove = () => {};

  return <div>
    <div className="category-content_wrapper" style={{alignItems: "flex-start",marginBottom:0}}>
      <div className="title">
        店铺头像 <i className="red">*</i>
      </div>
      <div className="category-content_upload">
        <div className="upLoad-content">
          {
            portrait.length < 1 && <div className="upload-img">
              <i className="iconfont">&#xe717;</i>
            </div>
          }
          <ImgCrop aspect={1/1} rotate={true}>
            <Upload
              listType="picture-card"
              fileList={portrait}
              action={`https://${OSSData.host}`}
              onPreview={(file,name)=>handlePreview(file,1)}
              onChange={(e)=>onChange(e,1)}
              onRemove={()=>onRemove()}
              data={(file)=>getExtraData(file)}
            >
              {portrait.length < 1 && <Button className="upload-btn">选择文件</Button>}
            </Upload>
          </ImgCrop>
          <Modal
            visible={previewImg}
            title={previewTitle}
            footer={null}
            onCancel={()=>{setPreviewImg(false)}}
          >
            <img style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </div>
      </div>
    </div>
  </div>
}

export default UploadImg