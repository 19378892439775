import React from 'react'


function Block({children,backgroundColor}){
   if(backgroundColor){
     style.block={
       ...style.block,
       backgroundColor:backgroundColor
     }}else{
      style.block={
        ...style.block,
        backgroundColor:'#fff'
     }}
   
   return(
     <div style={style.block}>
       {children}
     </div>
   )
}

const style = {
  block:{
    width:'273px',
    //background: "#ffffff",
    padding: '20px',
    borderRadius: '6px',
    marginBottom: '20px'
  }
}

export default Block