import React from "react";
import { useQuery } from "react-apollo";
import { REGION } from "@/graphql/store";
import { Cascader } from "antd";

function AddressSelect(props) {
  const { value, onChange } = props;
  const regionRes = useQuery(REGION, {
    fetchPolicy: "no-cache",
  });

  return (
    <Cascader
      options={(regionRes.data?.region || []).map((item) => {
        return {
          label: item.name,
          value: item.name,
          children: item.data.map((city) => ({
            label: city.name,
            value: city.name,
            children: city.data.map((area) => ({
              label: area.name,
              value: area.name,
            })),
          })),
        };
      })}
      value={value}
      onChange={(value) => onChange && onChange(value)}
      placeholder="请选择区域"
    />
  );
}

export default AddressSelect;
