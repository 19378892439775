import React, { useState, useEffect } from "react";
import { Select, Input, Message } from "antd";
import { REGION } from "../../graphql/store";
import client from "../../graphql/apollo-config";

const { Option } = Select;
const { TextArea } = Input;

const Address = (props) => {
  const {
    title,
    province,
    city,
    area,
    address,
    showAddress,
    changeData,
    changeList,
    provinceName,
    cityName,
    areaName,
    disabled,
  } = props;
  const [region, setRegion] = useState([]);
  const [provinceArr, setProvinceArr] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [areaArr, setAreaArr] = useState([]);

  useEffect(() => {
    initAddress();
  }, [province]);

  const initAddress = async () => {
    try {
      const { data } = await client.query({
        query: REGION,
        fetchPolicy: "no-cache",
      });
      setRegion([...data.region]);

      console.log("REGION = =   ", data);
      const provinceArr = data.region.map((i) => {
        return {
          id: i.id,
          name: i.name,
        };
      });
      setProvinceArr([...provinceArr]);
      const cityArr = data.region.filter((i) => {
        return i.name === province;
      });
      setCityArr([...cityArr[0].data]);
      const areaArr = cityArr[0].data.filter((i) => {
        return i.name === city;
      });
      setAreaArr([...areaArr[0].data]);
    } catch (error) {}
  };

  // 地址选择
  const changeAddress = (value, i) => {
    if (i === 1) {
      const cityArr = region.filter((i) => {
        return i.name === value;
      });
      setCityArr([...cityArr[0].data]);
      changeList(value, i);
    } else if (i === 2) {
      const areaArr = cityArr.filter((i) => {
        return i.name === value;
      });
      setAreaArr([...areaArr[0].data]);
      changeList(value, i);
    } else if (i === 3) {
      changeData("area", value);
    } else if (i === 4) {
      changeData("address", value);
    }
  };

  // 跳转地图
  const goLocation = () => {
    if (city !== "" && address !== "") {
      window.open(
        `https://uri.amap.com/search?keyword=${address}&city=${city}&view=map&coordinate=gaode&callnative=0`
      );
    } else {
      Message.info("请完善地址信息");
    }
  };

  return (
    <div
      className="category-content_wrapper"
      style={{ alignItems: "flex-start" }}
    >
      <div className="title">
        {title} <i className="red">*</i>
      </div>
      <div className="content" style={{ flex: 1 }}>
        <div>
          <Select
            disabled={disabled}
            value={province == "" ? "选择省份" : province}
            style={{ width: 170, marginRight: 110, fontSize: 14 }}
            onChange={(v) => changeAddress(v, 1)}
          >
            {provinceArr.map((i) => {
              return (
                <Option value={i.name} key={i.id}>
                  {i.name}
                </Option>
              );
            })}
          </Select>
          <Select
            disabled={disabled}
            value={city == "" ? "选择市" : city}
            style={{ width: 170, marginRight: 110, fontSize: 14 }}
            onChange={(v) => changeAddress(v, 2)}
          >
            {cityArr.map((i) => {
              return (
                <Option value={i.name} key={i.id}>
                  {i.name}
                </Option>
              );
            })}
          </Select>
          <Select
            disabled={disabled}
            value={area == "" ? "选择区/县" : area}
            style={{ width: 170, fontSize: 14 }}
            onChange={(v) => changeAddress(v, 3)}
          >
            {areaArr.map((i) => {
              return (
                <Option value={i.name} key={i.id}>
                  {i.name}
                </Option>
              );
            })}
          </Select>
        </div>
        {showAddress && (
          <>
            <div style={{ marginTop: 25, position: "relative", width: 732 }}>
              <TextArea
                disabled={disabled}
                placeholder="请填写详细地址（楼栋门牌号）"
                value={address == "" ? "" : address}
                style={{ width: 732, height: 85, fontSize: 14 }}
                maxLength={50}
                onChange={(e) => changeAddress(e.target.value, 4)}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 6,
                  right: 10,
                  fontSize: 14,
                  color: "#999",
                }}
              >
                {address?.length || 0 + "/50"}
              </div>
            </div>
            <div className="loctaion" onClick={() => goLocation()}>
              查看定位
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Address;
