import http from '../request'

// 发放餐券记录
export const getCouponStatistic = () => http.get('/statistic/index')

// 发放餐券记录明细
export const getCouponGiveDetail = (params) => http.get('/statistic/give-detail', params)

// 发券使用记录
export const getCouponUsedList = () => http.get('/statistic/use')

// 发放餐券记录明细
export const getCouponUsedDetail = (params) => http.get('/statistic/use-detail', params)

// 餐券抵扣记录
export const getCouponDeduction = () => http.get('/statistic/deduction')

// 发放餐券记录明细
export const getCouponDeductionDetail = (params) => http.get('/statistic/deduction-detail', params)