import React, { useEffect, useRef, useState } from "react";
import { Input, Message, Modal, Select, Radio } from "antd";
import Address from "@/components/store/address";
import BusinessImg from "@/components/FileUpload";
import UploadImg from "@/components/store/uploadImg";
import IndexImg from "@/components/store/indexImg";
import Contact from "@/components/public/contact/contact";
import Centers from "@/components/public/contact/center";
import Client from "@/components/public/contact/client";
import Information from "@/components/public/contact/information";
import { handleImg, operateImg } from "@/utils/index";
import { MY_CONFIG } from "../../graphql/login";
import { isInputTelephoneText } from "@/utils/regexp";
import "./index.less";
import {
  AGENT,
  JOIN_INFO,
  STORE_BASE_PLATFORM_AUDIT,
} from "../../graphql/store";
import client from "../../graphql/apollo-config";

const axios = require("axios").default;

const { Option } = Select;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const JoinDz = (props) => {
  const [storeCategroy, setStoreCategroy] = useState([]); // 商家类目
  const [OSSData, setOSSData] = useState({});
  const [first, setFirst] = useState(true); // 是否首次
  // const [isFirstBase, setFirstBase] = useState(true) // 旧商家是否首次
  const [interval, setInterval] = useState(null);
  const LOCAL_KEY = "join_store";

  const [storeInfo, setStoreInfo] = useState({
    short_name: "", // 店铺简称
    store_category_id: "", // 商家类目
    store_category_name: "",
    province: "",
    city: "",
    area: "",
    lng: "",
    lat: "",
    product_type: 0,
    average_spend: "",
    lowest_delivery_price: "",
    code: "", // 推荐代理
    address: "", // 店铺地址
    hot_line: "", // 客服手机
    contact_name: "", // 联系人姓名
    cover: [], // 店铺头像
    images: [], // 店铺轮播图`
    video: [],
    house_num: [], // 门头照
    store_inner: [], // 店内照
    is_ushoufu: 0,
    notice: "",
  });
  const [storeBase, setStoreBase] = useState({
    reject_reason: null,
    status: null,
    reject_type: null,
  });

  const history = props.history;

  const rule1 = [
    {
      rule: !storeInfo.short_name || !storeInfo.short_name.trim(),
      title: "请填写店铺简称",
    },
    {
      rule: !storeInfo.store_category_id,
      title: "请选择店铺所属类目",
    },
    {
      rule: !storeInfo.province,
      title: "请选择省份",
    },
    {
      rule: !storeInfo.city,
      title: "请选择市",
    },
    {
      rule: !storeInfo.area,
      title: "请选择区/县",
    },
    {
      rule: !storeInfo.address,
      title: "请填写详细地址",
    },
    {
      rule: storeInfo.address && storeInfo.address.length < 5,
      title: "请填写5个字以上的详细地址",
    },
    {
      rule: !storeInfo.average_spend,
      title: "请填写人均消费",
    },
    {
      rule: (storeInfo.average_spend + "").indexOf(".") != -1,
      title: "人均消费不能为小数",
    },
    {
      rule: storeInfo.average_spend < 0,
      title: "人均消费不能小于0",
    },
    {
      rule:
        storeInfo.product_type == 0 && storeInfo.lowest_delivery_price === "",
      title: "请填写起送价",
    },
    {
      rule:
        storeInfo.product_type == 0 &&
        (storeInfo.lowest_delivery_price + "").indexOf(".") != -1,
      title: "起送价不能为小数",
    },
    {
      rule: storeInfo.product_type == 0 && storeInfo.lowest_delivery_price < 0,
      title: "人均消费不能小于0",
    },
    {
      rule: !storeInfo.contact_name || !storeInfo.contact_name.trim(),
      title: "请填写联系人姓名",
    },
    {
      rule: !storeInfo.hot_line,
      title: "请填写客服手机号码",
    },
    {
      rule: storeInfo.hot_line && storeInfo.hot_line.length !== 11,
      title: "客服电话格式不正确",
    },
    {
      rule: storeInfo.house_num && JSON.stringify(storeInfo.house_num) == "[]",
      title: "请上传门头照",
    },
    {
      rule: JSON.stringify(storeInfo.store_inner) == "[]",
      title: "请上传店内照",
    },
    {
      rule: storeInfo.cover.length < 1,
      title: "请上传店铺头像",
    },
    {
      rule: storeInfo.images.length < 1,
      title: "请上传店铺轮播图",
    },
  ];

  useEffect(() => {
    getStoreInfo();
  }, []);

  // 页面一进来
  const getStoreInfo = async () => {
    try {
      const { data } = await client.query({
        query: STORE_BASE_PLATFORM_AUDIT,
        fetchPolicy: "no-cache",
      });
      if (data) {
        setOSSData({
          ...data.oss,
        });
        setStoreCategroy([...data.storeCategory]);
        if (data.storeInfo) {
          const storeData = data.storeInfo;
          const isUshoufu = storeData.is_ushoufu;
          let a = null;
          let b = null;
          // 没有审核信息拿cookie里面,cookie返回的是数组，后台返回的是对象，所以要分开处理
          if (!storeData.store_base_platform_audit && !isUshoufu) {
            a =
              localStorage.getItem(LOCAL_KEY) != null
                ? JSON.parse(localStorage.getItem(LOCAL_KEY))
                : storeInfo;
            setInterval(5000);
          } else {
            // 首次就拿审核信息里面的，不是首次就拿门店信息
            a = storeData.store_base_platform_audit?.is_first_audit
              ? storeData.store_base_platform_audit
              : storeData;
          }
          b = {
            house_num: imgData(a.house_num && a.house_num.url, "house_num"),
            cover: imgData(a.cover && a.cover.url, "cover"),
            store_inner: imgData(
              a.store_inner && a.store_inner.url,
              "store_inner"
            ),
            video: imgData(a.video && a.video.url, "video"),
          };
          const storeInfo = {
            ...a,
            ...b,
          };
          if (storeInfo.video && storeInfo.video.length) {
            storeInfo.video = operateImg(
              storeInfo.video[0].url,
              storeInfo.video[0].url +
                "?x-oss-process=video/snapshot,t_1000,f_jpg"
            );
          }
          setStoreInfo(storeInfo);
          // 小于5代表没到补充资料那步，状态等于1是审核通过了，id存在是代表店铺成功开启
          // const isFirst =
          //   !storeData.store_base_platform_audit ||
          //   (storeData.store_base_platform_audit &&
          //     storeData.store_base_platform_audit.status < 5 &&
          //     storeData.store_base_platform_audit.status != 1 &&
          //     !storeData.id);
          // setFirst(isFirst);
          setStoreBase({
            reject_reason:
              data.storeInfo.store_base_platform_audit &&
              data.storeInfo.store_base_platform_audit.reject_reason,
            status:
              data.storeInfo.store_base_platform_audit &&
              data.storeInfo.store_base_platform_audit.status,
            reject_type:
              data.storeInfo.store_base_platform_audit &&
              data.storeInfo.store_base_platform_audit.reject_type,
          });
          if (
            data.storeInfo.is_ushoufu !== 1 &&
            data.storeInfo.store_base_platform_audit?.status !== 1
          ) {
            const { data: config } = await client.query({
              query: MY_CONFIG,
              variables: {
                name: "store_unushoufu_tip",
              },
              fetchPolicy: "no-cache",
            });
            if (config) {
              Modal.info({
                title: "提示",
                content: config.myConfig,
              });
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const imgData = (url, name) => {
    if (url) {
      let c = {};
      c.url = url;
      c.uid = name;
      c.name = name;
      return [c];
    } else {
      return [];
    }
  };

  useInterval(() => {
    localStorage.setItem(LOCAL_KEY, JSON.stringify(storeInfo));
  }, interval);

  const setStoreInfos = (name, value) => {
    setStoreInfo({
      ...storeInfo,
      [name]: value,
    });
  };

  // 修改数据
  const changeData = (name, value) => {
    switch (name) {
      case "hot_line":
        if (isInputTelephoneText(value)) {
          setStoreInfos(name, value);
        }
        break;
      default:
        setStoreInfos(name, value);
        break;
    }
  };

  // 提交修改
  const submitMutate = async (lng, lat) => {
    try {
      const { data } = await client.mutate({
        mutation: JOIN_INFO,
        variables: {
          short_name: storeInfo.short_name,
          province: storeInfo.province,
          city: storeInfo.city,
          area: storeInfo.area,
          address: storeInfo.address,
          lng: lng,
          lat: lat,
          code: storeInfo.code,
          store_category_id: storeInfo.store_category_id,
          house_num: handleImg(storeInfo.house_num),
          store_inner: handleImg(storeInfo.store_inner),
          contact_name: storeInfo.contact_name,
          hot_line: storeInfo.hot_line,
          images: handleImg(storeInfo.images),
          cover: handleImg(storeInfo.cover),
          notice: storeInfo.notice,
          video: handleImg(storeInfo.video),
          average_spend: storeInfo.average_spend,
          lowest_delivery_price: storeInfo.lowest_delivery_price,
          product_type: storeInfo.product_type,
        },
        fetchPolicy: "no-cache",
      });
      if (data.addStoreAuditBasePlatform) {
        Message.success("提交成功,请等待审核");
        localStorage.removeItem(LOCAL_KEY);
        setInterval(null);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {}
  };

  const verification = (rule) => {
    // 通过后不需要再验证
    if (!first) {
      return true;
    } else {
      for (let item of rule) {
        if (item.rule) {
          Message.info(item.title);
          return false;
        }
      }
      return true;
    }
  };

  // 店铺类目
  const changeCategroy = (value) => {
    const a = storeCategroy.filter((i) => {
      return i.name == value;
    });
    setStoreInfo({
      ...storeInfo,
      store_category_name: value,
      store_category_id: a[0].id,
    });
  };
  console.log(handleImg(storeInfo.images), storeInfo.images);
  // 通过地址获取经纬度
  const submit = async () => {
    if (verification(rule1)) {
      if (!first) {
        Message.info("不允许再次提交");
        return;
      }
      if (storeBase.status == 0 || storeBase.reject_type == 0) {
        Message.info("正在审核中，请勿重复提交");
      } else {
        //银行卡是否有效
        // const {data} = await axios.get(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?cardNo=${storeInfo.bank_account}&cardBinCheck=true`);
        // console.log('checkData = ', data)
        // if (data && data.validated) {
        const { data } = await axios.get(
          `https://restapi.amap.com/v3/geocode/geo?key=75060fae7d3d453c790b609a312a7a08&address=${
            storeInfo.city + storeInfo.area + storeInfo.address
          }&city=${storeInfo.city}`
        );
        const d = data.geocodes[0].location.indexOf(",");
        const lng = data.geocodes[0].location.slice(0, d);
        const lat = data.geocodes[0].location.slice(d + 1);
        submitMutate(lng, lat);
        // } else {
        //     Message.info('请填写有的效银行卡号')
        // }
      }
    }
  };

  const queryAgent = async (e) => {
    if (e) {
      try {
        const { data } = await client.query({
          query: AGENT,
          variables: {
            agent_mobile: e,
          },
          fetchPolicy: "no-cache",
        });
        if (!data.agent) {
          Message.info("没找到该代理手机号");
          changeData("code", "");
        }
      } catch (error) {}
    }
  };
  // 审核状态
  const getStatus = React.useCallback(() => {
    if (storeBase.status == 1) {
      return "审核通过";
    }
    switch (storeBase.status) {
      case 0:
        return "审核中";
      case 1:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        return "审核通过";
      case 2:
        return "审核不通过";
      default:
        break;
    }
    // switch (storeBase.status) {
    //   case 0:
    //     return "审核中";
    //   case 1:
    //     return "审核通过";
    //   case 2:
    //     return "审核不通过";
    //   case 3:
    //     return "审核中";
    //   case 4:
    //     return "待银行审核";
    //   case 5:
    //     return "审核结果：资料未完善，请在补充资料提交打款验证！";
    //   case 6:
    //     return "审核结果：资料未完善，请在补充资料提交商户授权认证！";
    //   case 7:
    //     return "待银行审核";
    //   default:
    //     break;
    // }
  }, [storeBase.status, storeInfo.is_ushoufu]);

  const showExample = (img) => {
    Modal.info({
      icon: null,
      content: (
        <img
          style={{ textAlign: "center", width: "530px" }}
          src={require(`../../assets/images/example/${img}.jpg`)}
          alt=""
        />
      ),
      width: 600,
    });
  };

  const changeList = (v, i) => {
    if (i == 1) {
      setStoreInfo({
        ...storeInfo,
        province: v,
        city: "",
        area: "",
      });
    } else {
      setStoreInfo({
        ...storeInfo,
        city: v,
        area: "",
      });
    }
  };

  const canEdit = React.useMemo(() => {
    return !storeInfo.id || storeInfo.store_base_platform_audit?.is_first_audit;
  }, [storeInfo]);

  return (
    <div className="index-container">
      <div className="index-wrapper">
        <div className="category-content-top">
          <div className="category-audit-tip_status">
            {storeBase.status == null && storeInfo.is_ushoufu == 0 ? (
              <span className="red">未认证</span>
            ) : (
              <span className="red">{getStatus()}</span>
            )}
            {storeBase.status == 2 && storeBase.reject_reason ? (
              <span className="rej">理由：{storeBase.reject_reason}</span>
            ) : null}
          </div>
        </div>
        <div className="category-content">
          <div>
            <div className="category-content_wrapper">
              <div className="title">
                店铺简称 <i className="red">*</i>
              </div>
              <div className="content">
                <Input
                  disabled={!first}
                  placeholder="请填写店铺简称"
                  value={storeInfo.short_name}
                  maxLength={20}
                  className="my-input"
                  onChange={(e) => changeData("short_name", e.target.value)}
                />
                <div className="suffix">
                  {storeInfo.short_name && storeInfo.short_name.length + "/20"}
                </div>
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                商家类目 <i className="red">*</i>
              </div>
              <div className="content">
                <Select
                  showSearch
                  disabled={!first}
                  className="my-input"
                  value={storeInfo.store_category_name}
                  placeholder="请选择店铺经营的类目"
                  onChange={(e) => changeCategroy(e)}
                >
                  {storeCategroy.map((i) => {
                    return (
                      <Option value={i.name} key={i.id}>
                        {i.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>

            <Address
              title={"店铺地址"}
              province={storeInfo.province}
              city={storeInfo.city}
              area={storeInfo.area}
              provinceName={"province"}
              cityName={"city"}
              areaName={"area"}
              address={storeInfo.address}
              showAddress={true}
              disabled={!first}
              changeList={(v, i) => changeList(v, i)}
              changeData={(name, value) => changeData(name, value)}
            />

            <div
              className="category-content_wrapper"
              style={{ alignItems: "flex-start" }}
            >
              <div className="title">
                店铺类型 <i className="red">*</i>
              </div>
              <div className="content">
                <Radio.Group
                  onChange={(e) => {
                    changeData("product_type", e.target.value);
                  }}
                  style={{ marginBottom: 28 }}
                  value={storeInfo.product_type}
                  disabled={!canEdit}
                >
                  <Radio value={0}>实物商品（需配送发货）</Radio>
                  <Radio value={1}>
                    电子卡券（系统自动生成券码，线上核销）
                  </Radio>
                </Radio.Group>
                <div
                  className="tip2 tip"
                  style={{
                    marginLeft: 24,
                    marginBottom: 0,
                    color: "#F4981D",
                  }}
                >
                  温馨提示：商品类型认证成功后，不允许修改
                </div>
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title">
                消费设置 <i className="red">*</i>
              </div>
              <div className="title" style={{ fontSize: 14, marginRight: 22 }}>
                人均消费
              </div>
              <div className="content">
                <Input
                  type="number"
                  value={storeInfo.average_spend}
                  suffix="元"
                  disabled={!first}
                  style={{ width: 120, marginRight: 80 }}
                  onChange={(e) => changeData("average_spend", e.target.value)}
                />
              </div>
              {storeInfo.product_type == 0 && (
                <>
                  <div
                    className="title"
                    style={{ fontSize: 14, marginRight: 22 }}
                  >
                    起送价
                  </div>
                  <div className="content">
                    <Input
                      type="number"
                      value={storeInfo.lowest_delivery_price}
                      suffix="元"
                      disabled={!first}
                      style={{ width: 120, marginRight: 80 }}
                      onChange={(e) =>
                        changeData("lowest_delivery_price", e.target.value)
                      }
                    />
                  </div>
                </>
              )}
            </div>

            <div className="category-content_wrapper">
              <div className="title" style={{ marginRight: 54 }}>
                联系人姓名 <i className="red">*</i>
              </div>
              <div className="content">
                <Input
                  placeholder="请填写联系人姓名"
                  value={storeInfo.contact_name}
                  className="my-input"
                  maxLength={20}
                  disabled={!first}
                  onChange={(e) => changeData("contact_name", e.target.value)}
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title" style={{ marginRight: 54 }}>
                客服电话 <i className="red">*</i>
              </div>
              <div className="content">
                <Input
                  disabled={!first}
                  placeholder="请填写客服手机号码"
                  value={storeInfo.hot_line}
                  className="my-input"
                  type="number"
                  onChange={(e) => changeData("hot_line", e.target.value)}
                />
              </div>
            </div>
            <div className="category-content_wrapper">
              <div className="title" style={{ marginRight: 39 }}>
                推荐人手机号 <i className="red"></i>
              </div>
              <div className="content">
                <Input
                  disabled={!canEdit}
                  placeholder="请填写推荐人的手机号码，若无可不填"
                  value={storeInfo.code}
                  maxLength={11}
                  className="my-input"
                  onBlur={(e) => queryAgent(e.target.value)}
                  onChange={(e) => changeData("code", e.target.value)}
                />
              </div>
            </div>

            <div
              className="category-content_wrapper"
              style={{ alignItems: "flex-start" }}
            >
              <div className="title" style={{ marginRight: 84 }}>
                门头照 <i className="red">*</i>
              </div>
              <BusinessImg
                OSSData={OSSData}
                listChange={(arr) => {
                  changeData("house_num", arr);
                }}
                fileList={storeInfo.house_num}
                disabled={!first}
              />
              <div className="img-tip">
                • 上传JPG、PNG格式，图片小于1M；
                <br />
                • 需拍摄完整牌匾、门框；
                <br />• 确保图像清晰；
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(4)}
                >
                  示例
                </p>
              </div>
            </div>

            <div
              className="category-content_wrapper"
              style={{ alignItems: "flex-start" }}
            >
              <div className="title" style={{ marginRight: 84 }}>
                店内照 <i className="red">*</i>
              </div>
              <BusinessImg
                OSSData={OSSData}
                listChange={(arr) => {
                  changeData("store_inner", arr);
                }}
                fileList={storeInfo.store_inner}
                disabled={!first}
              />
              <div className="img-tip">
                • 上传JPG、PNG格式，图片小于1M；
                <br />
                • 需拍摄完整店内环境；
                <br />
                • 确保图像清晰；
                <br />• 无店内照可上传租赁协议；
                <p
                  style={{ color: "#048ad3", cursor: "pointer" }}
                  onClick={() => showExample(1)}
                >
                  示例
                </p>
              </div>
            </div>
            <IndexImg
              portrait={storeInfo.cover}
              OSSData={OSSData}
              changePortrait={(arr) => {
                changeData("cover", arr);
              }}
            />
            <div className="tip3">
              *建议上传140*140px或比例1：1，JPG、PNG格式，图片小于1M。
            </div>

            <UploadImg
              imagesList={storeInfo.images}
              storeVideo={storeInfo.video}
              OSSData={OSSData}
              changeImages={(arr) => {
                changeData("images", arr);
              }}
              changeVideo={(arr) => {
                if (arr.length > 0) {
                  arr[0].thumbUrl =
                    arr[0].url + "?x-oss-process=video/snapshot,t_1000,f_jpg";
                }
                changeData("video", arr);
              }}
            />
            <div
              className="category-content_wrapper"
              style={{ alignItems: "flex-start" }}
            >
              <div className="title">
                店铺公告 <i className="red"></i>
              </div>
              <div
                className="content"
                style={{ position: "relative", width: 732 }}
              >
                <Input.TextArea
                  style={{ width: 732, height: 85, fontSize: 14 }}
                  value={storeInfo.notice}
                  placeholder="选填，店铺公告可输入新店开业优惠信息、店铺促销信息、店铺服务承诺等"
                  maxLength={100}
                  onChange={(e) => changeData("notice", e.target.value)}
                  disabled={!first}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: 6,
                    right: 10,
                    fontSize: 14,
                    color: "#999",
                  }}
                >
                  {storeInfo?.notice?.length || 0 + "/100"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="confirm-wrapper">
          <div
            className="confirm-next"
            style={{
              backgroundColor: !first ? "rgba(244,152,29,0.6)" : "#F4981D",
            }}
            onClick={() => submit()}
          >
            提交
          </div>
        </div>
      </div>
      <div className="index-contact">
        <Centers history={history} title={"公告通知"} type={3} />
        <Contact />
        <Information />
        <Client />
        <Centers history={history} title={"学习中心"} type={2} />
      </div>
    </div>
  );
};

export default JoinDz;
