import React from 'react';
import Block from './block';
import QR from '../../../assets/images/QR2.jpg'

function Information(){
  return(
    <Block>
      <div style={style.wrapper}>
        <div style={style.left}><img src={QR} width='92' height='92'></img></div>
        <div style={style.right}>
          <span>扫码关注 “树懒商圈”</span>
          <span style={{lineHeight: '14px'}}>获取更多开店资料</span>
        </div>
      </div>
    </Block>
  )
}

const style = {
  wrapper:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  left:{},
  right:{
    marginLeft:'9px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '14px'
   }
}

export default Information