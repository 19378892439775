import classNames from 'classnames'
import React, { useState, useEffect, useMemo } from 'react'
import { Button, message, Modal, Table } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getSonStore, unbindSonStore } from '@/apis/shop'
import { timestampToString } from '@/utils'
import './index.less'

const tabs = [
    { id: 0, name: '全部', value: undefined },
    { id: 1, name: '已添加', value: 1 },
    { id: 2, name: '待审核', value: 0 },
    { id: 3, name: '已驳回', value: 2 },
    { id: 4, name: '已解绑', value: -1 }
]

const SubStop = (props) => {
    const [current, setCurrent] = useState(0)
    const [pageInfo, setPageInfo] = useState({
        page: 1,
        pagesize: 20,
    })
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const columns = useMemo(() => {
        const col = [
            {
                title: '序号',
                dataIndex: 'id',
                align: 'center',
                width: 64,
            },
            {
                title: '提交时间',
                dataIndex: 'created_at',
                width: 158,
                render: (text, record, index) => {
                    return (
                        <div>{timestampToString(record.created_at * 1000, 2)}</div>
                    )
                }
            },
            {
                title: '子商家账号',
                dataIndex: 'mobile',
                width: 140,
            },
            {
                title: '子店铺简称',
                dataIndex: 'short_name',
                width: 132,
            },
            {
                title: '类目',
                dataIndex: 'store_category_name',
                width: 120,
            },
            {
                title: '客服电话',
                dataIndex: 'hot_line',
                width: 140,
            },
            {
                title: '店铺地址',
                dataIndex: 'address',
                width: 260,
                render: (text, record, index) => {
                    return (
                        <div>{record.city + record.area + record.address}</div>
                    )
                }
            },
            {
                title: '联系人',
                dataIndex: 'contact_name',
                align: 'center',
                width: 110,
            },
            {
                title: '联系电话',
                dataIndex: 'contact_mobile',
                width: 140,
            },
            {
                title: '状态',
                dataIndex: 'status',
                align: 'center',
                width: 110,
                render: (text, record, index) => {
                    return (
                        <div className={classNames('status-box', {
                            warn: record.status == 0,
                            erro: record.status == 2,
                            succ: record.status == 1
                        })}><span className="dot"></span>{record.status_name}</div>
                    )
                }
            },
            {
                title: '驳回理由',
                dataIndex: 'reject_reason',
                width: 160,
            },
            {
                title: '操作',
                fixed: 'right',
                dataIndex: 'oper',
                align: 'center',
                width: 120,
                render: (text, record, index) => {
                    if (record.status == 1) {
                        return <span className="oper-btn" onClick={() => beforeUnbind(record.id, -1)}>解绑</span>
                    } else if (record.status == -1) {
                        return <span className="oper-btn" onClick={() => beforeUnbind(record.id, 1)}>关联</span>
                    } else {
                        return <span>-</span>
                    }
                }
            },
        ]
        return col
    }, [])

    useEffect(() => {
        getList()
    }, [current, pageInfo.page])

    const getList = async () => {
        setLoading(true)
        try {
            const { data } = await getSonStore({
                ...pageInfo,
                status: tabs[current].value
            })
            if (data) {
                setList(data.data)
                setTotal(data.total)
            }
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    const onTabchange = (index) => {
        if (current === index) return
        setCurrent(index)
        onChange(1)
    }

    const onChange = (page) => {
        setPageInfo({
            ...pageInfo,
            page
        })
    }

    const beforeUnbind = (id, status) => {
        if (status === -1) {
            Modal.confirm({
                title: '确定解绑子店铺？',
                icon: <ExclamationCircleOutlined />,
                content: '解绑后，子店铺的订单信息将不再与主店铺或平台中心同步，所有在子店铺中展示的商品列表将自动消失，不再对外展示',
                okText: '确定',
                cancelText: '取消',
                onOk(close) {
                    unbind(id, status).then(() => {
                        close()
                    })
                }
            })
        } else {
            unbind(id, status)
        }
    }

    const unbind = async (id, status) => {
        try {
            const { data } = await unbindSonStore({
                id,
                status
            })
            getList()
            if (!data) {
                message.success(status === -1 ? '解绑成功' : '关联成功')
            } else if (data && data.code === 0) {
                Modal.error({
                    title: status === -1 ? '解绑失败' : '关联失败',
                    content: data.message,
                    okText: '确定',
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="subStop">
            <Button type="primary" size="large" style={{ width: '154px', marginBottom: 25 }} onClick={() => {
                props.history.push('/subStop/add')
            }}>添加子店铺</Button>
            <div className="subStop-card">
                <div className="subStop-btns">
                    {tabs.map((t, index) => (
                        <div
                            key={t.id}
                            className={`subStop-btn ${current === index ? 'active' : ''}`}
                            onClick={() => onTabchange(index)}
                        >{t.name}</div>
                    ))}
                </div>
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={list}
                    loading={loading}
                    bordered
                    scroll={{
                        x: 'max-content',
                        scrollToFirstRowOnChange: true,
                    }}
                    pagination={{
                        total,
                        current: pageInfo.page,
                        defaultPageSize: pageInfo.pagesize,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        position: ["bottomCenter"],
                        showTotal: (total) => `共${total}条`,
                        onChange: onChange,
                    }}
                />
            </div>
        </div>
    )
}

export default SubStop