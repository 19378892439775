/*
 * @Author: Fan
 * @Date: 2020-12-01 10:28:05
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-22 16:25:31
 * @Description: file content
 */
import React,{useState, useEffect } from 'react';
import Block from './block';
import { PAGES} from "@/graphql/login";
import { useLazyQuery } from "react-apollo";
import { useHistory } from "react-router";
const Center = (props) => {
  // type 1是公告通知，2是学习中心
  const { title, type } = props
  const history = useHistory();
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [showMore, setMore] = useState(false)

  useEffect(()=>{
    init()
  },[])

  const [init] = useLazyQuery(PAGES,{
    fetchPolicy: 'no-cache',
    variables:{
      type:type
    },
    onCompleted: (data) => {
      if (data){
        setAllData(data.pages)
        if (data.pages && data.pages.length > 5){
          setData(data.pages.slice(0,5))
          setMore(true)
        } else{
          setData(data.pages)
          setMore(false)
        }
      }
    },
    onError: () => {}
  });

  const more = () =>{
    if (showMore){
      setData(allData)
      setMore(false)
    }else{
      setData(allData.slice(0,5))
      setMore(true)
    }
  }

  return (
    <div>
      {
        data && data.length > 0 && <Block backgroundColor={type == 3?'#fff':'#dbebfd'}>
          <div style={style.wrapper}>
            <div style={style.top}>{title}</div>
            {
              data.map((i,index)=>{
                return <div
                  onClick={()=>history.push(`/index/explain?id=${i.id}`)}
                  style={style.middle} key={index}>{i.title}</div>
              })
            }
            {
              allData.length > 5 && <div style={style.bottom}><span onClick={()=>more()} style={style.href}>
              {showMore?'查看更多':'收起'}
            </span></div>
            }
          </div>
        </Block>
      }
    </div>
  );
};


const style = {
  wrapper:{
    color: '#333333',
    margin:'-2px 0'

  },
  top:{
    fontSize: '18px',
    fontWeight: 'bold',

  },
  middle:{
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '10px',
    cursor:'pointer',
  },
  bottom:{
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '12px',
    textAlign: 'center',
  },
  href:{
    color:'#3958F7',
    cursor: 'pointer'
  }
}

export default Center;
