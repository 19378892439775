import React from 'react';
import Block from './block';
import applets from '@/assets/images/applets.jpg'
function Client(){
  return(
    <Block>
      <div style={style.wrapper}>
        <div style={style.left}><img src={applets} width='92' height='92'/></div>
        <div style={style.right}>
          <div style={{fontSize: '18px',fontWeight:'bold',marginBottom:'9px', lineHeight: 1}}>用户端</div>
          <span>使用微信扫码，查看</span>
          <span> “树懒商圈” 小程序</span>
        </div>
      </div>
    </Block>
  )
}

const style = {
  wrapper:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin:'2px 0'
  },
  left:{marginLeft:'-5px'},
  right:{
    marginLeft:'14px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '14px',
    color: '#333333',
    lineHeight: 1.4
  }
}

export default Client