import React from "react";
import { Button, Modal, Radio, Form, DatePicker, message } from "antd";
import ExportJsonExcel from "js-export-excel";
import client from "@/graphql/apollo-config";

import moment from "moment";

const ExportBtn = (props) => {
  const { query, variables, total, columns } = props;
  const [visible, setVisible] = React.useState(false);

  const [form] = Form.useForm();

  const getDataSource = (data) => {
    return data.map((item) => ({
      ...item,
      productCategory: item.productCategory.name,
      specs: item.productSpecs
        .map((item) => `${item.name};`)
        .join("\n"),
      specs_price: item.productSpecs
        .map((item) => `${item.name}:￥${item.price};`)
        .join("\n"),
      specs_stock: item.productSpecs
        .map((item) => `${item.name}:${item.stock}件;`)
        .join("\n"),
      offer_price: item.productSpecs.length > 0 ? item.productSpecs
        .map((item) => `${item.name}:￥${item.offer_price};`)
        .join("\n") : `￥${item.product_offer_price}`,
      status: item.status ? "上架" : "下架",
      created_at: moment(item.created_at * 1000).format("YYYY-MM-DD HH:mm:ss"),
      module_type:
        item.module_type == 1
          ? "官方商城"
          : item.module_type == 2
          ? "特推商城"
          : item.module_type == 3
          ? "红包券商城"
          : "普通线上商城",
    }));
  };

  const getAllData = (time) => {
    client
      .query({
        query,
        variables: {
          ...variables,
          page: 1,
          limit: total,
          created_at_start: time ? time[0].unix() : null,
          created_at_end: time ? time[1].unix() : null,
        },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        if (data.product.data.length) {
          exportExcel(data.product.data);
        } else {
          message.info("商品列表为空");
          setVisible(false);
        }
      });
  };

  const exportExcel = (data) => {
    let option = {};
    option.fileName = new Date().getTime();
    const delField = ["cover", "optional", "weight"]; //要删除的字段
    const newArr = columns.filter((item) => {
      const isDel = delField.find((i) => i == item.dataIndex);
      return !isDel;
    });
    option.datas = [
      {
        sheetData: getDataSource(data),
        sheetName: "sheet",
        sheetHeader: newArr.map((i) => i.title),
        sheetFilter: newArr.map((i) => i.dataIndex),
        columnWidths: Array(15).fill("10"),
      },
    ];

    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
    message.success("导出成功");
    setVisible(false);
  };

  const onSubmit = () => {
    form.validateFields().then((value) => {
      getAllData(value.date);
    });
  };

  return (
    <>
      <Modal
        title="导出商品"
        visible={visible}
        onOk={onSubmit}
        onCancel={() => setVisible(false)}
      >
        <Form form={form}>
          {(data) => (
            <>
              <Form.Item name="type" initialValue={1}>
                <Radio.Group>
                  <Radio value={1}>全部</Radio>
                  {/* <Radio value={2}>按下单时间导出</Radio> */}
                </Radio.Group>
              </Form.Item>
              {data.type === 2 && (
                <Form.Item
                  name="date"
                  required
                  rules={[
                    {
                      required: true,
                      message: "请选择下单时间",
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    showTime={{ format: "HH:mm:ss" }}
                    format="YYY/MM/DD"
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Modal>
      <Button type="primary" onClick={() => setVisible(true)}>
        导出Excel
      </Button>
    </>
  );
};

export default ExportBtn;
