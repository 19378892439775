import React, { useState, useRef, useEffect } from "react";
import { Switch, Message } from "antd";
import {
  Xiu_Gai_Lei_Mu_Mutation,
  Get_One_Category,
  Add_Lei_Mu_Mutation,
} from "../../../graphql/products";
import client from "../../../graphql/apollo-config";
import "./index.less";

const NewCategory = (props) => {
  console.log(props);
  const { history } = props;
  const nameInput = useRef();
  const weightInput = useRef();

  const [show, setShow] = useState(1);

  const [info, setInfo] = useState({ weight: "", name: "" });
  const [updateInfo, setUpdataInfo] = useState({});

  //0是增加 1是修改
  const [type, setType] = useState(0);

  const change = (show) => {
    console.log("show =", show);
    setShow(show ? 1 : 0);
  };

  useEffect(() => {
    const cId = props.location.search.slice(4);
    if (cId) {
      requestCategory({ product_category_id: cId });
    }
  }, [props.location.search]);

  const requestCategory = async (dic) => {
    setType(1);
    try {
      const { data } = await client.query({
        query: Get_One_Category,
        variables: dic,
        fetchPolicy: "no-cache",
      });
      var dic = {};
      dic.name = data.productCategory.name;
      dic.weight = data.productCategory.sort_order;
      setShow(data.productCategory.status);
      setInfo(dic);
      setUpdataInfo(data.productCategory);
    } catch (e) {}
  };

  const confirm = async () => {
    console.log(nameInput.current.value);
    if (type == 0) {
      //增加
      var dic = {};
      dic.name = nameInput.current.value;
      dic.sort_order = weightInput.current.value;
      dic.status = show;
      if (!regular(dic)) {
        return;
      }
      try {
        const { data } = await client.mutate({
          mutation: Add_Lei_Mu_Mutation,
          variables: dic,
          fetchPolicy: "no-cache",
        });
        if (data.addProductCategory) {
          Message.success("提交成功");
          setTimeout(() => {
            history.go(-1);
          }, 2000);
        }
      } catch (e) {}
    } else {
      //修改
      var category = {};
      category.product_category_id = updateInfo.id;
      category.name = nameInput.current.value;
      category.sort_order = weightInput.current.value;
      category.status = show;

      if (!regular(category)) {
        return;
      }

      try {
        const { data } = await client.mutate({
          mutation: Xiu_Gai_Lei_Mu_Mutation,
          variables: category,
          fetchPolicy: "no-cache",
        });
        if (data.updateProductCategory) {
          Message.success("提交成功");
          setTimeout(() => {
            history.go(-1);
          }, 2000);
        }
      } catch (e) {}
    }
  };

  const regular = (category) => {
    let tip = "";
    if (category.name.length == 0) {
      Message.info("请填写类目");
      return false;
    } else if (category.sort_order.length == 0) {
      Message.info("请填写权重");
      return false;
    } else if (category.name.length > 20) {
      Message.info("类目名称不能超过20个字符");
      return false;
    } else if (!/(^[1-9]\d*$)/.test(category.sort_order)) {
      Message.info("权重只能输入正整数");
      return false;
    }
    return true;
  };

  const nameChange = (value) => {
    const name = value.value;
    var dic = { ...info, name: name };
    setInfo(dic);
  };
  const weightChange = (value) => {
    const weight = value.value;
    var dic = { ...info, weight: weight };
    setInfo(dic);
  };
  return (
    <div className="newclass-wrapper">
      <span className="title"> 新增/编辑商品类目:</span>
      <div className="content-wrapper">
        <div className="inputclass-wrapper">
          <span className="name">类目名称</span>
          <input
            ref={nameInput}
            className="input"
            value={info.name}
            onChange={nameChange}
          />
        </div>
        <div className="inputweight-wrapper">
          <span className="name">展示权重</span>
          <input
            ref={weightInput}
            className="input"
            value={info.weight}
            onChange={weightChange}
          />
        </div>
      </div>
      <div className="show-wrapper">
        <span className="name">是否显示</span>
        <Switch className="showbtn" checked={show == 1} onClick={change} />
      </div>
      <div className="confirm" onClick={confirm}>
        提交
      </div>
    </div>
  );
};

export default NewCategory;
