import gql from "graphql-tag";

// 特约商户申请
export const STORE_AUDIT_INFO = gql`
  query {
    user {
      parent_mobile
    }
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
    }
    additionalInfo {
      status
      reject_reason
      auth_image {
        url
      }
    }
    storeInfo {
      id
      status
      settle_status
      name
      company_name
      business_license_valid_start
      business_license_valid_end
      corporation
      id_card_no
      mobile
      id_card_valid_start
      id_card_valid_end
      bank_account_id_card_no
      bank_card_image {
        url
      }
      short_name
      store_category_id
      store_category_name
      province
      city
      area
      address
      hot_line
      mcc_code
      mcc_name
      industry_code
      industry_name
      code
      status
      credit_code
      bank_code
      ali_account
      business_license {
        url
      }
      house_num {
        url
      }
      checkout_counter {
        url
      }
      store_inner {
        url
      }
      corporation
      id_card_no
      id_card_front {
        url
      }
      id_card_back {
        url
      }
      id_card_hand {
        url
      }
      contact_name
      contact_card_front {
        url
      }
      contact_card_back {
        url
      }
      contact_card_address
      contact_card_valid_start
      contact_card_valid_end
      contact_id_card
      contact_mobile
      contact_email
      bank_account_name
      bank_account_type
      bank_account
      bank_name
      bank_branch
      bank_province
      bank_city
      bank_area
      bank_id_card_front {
        url
      }
      bank_id_card_back {
        url
      }
      bank_hand_image {
        url
      }
      opening_permit_image {
        url
      }
      is_ushoufu
      product_type
      average_spend
      lowest_delivery_price
      notice
      cover {
        url
      }
      images {
        url
      }
      video {
        url
      }
      email
      bank_mobile
      registered_capital
      bf_industry_code
      store_base_platform_audit {
        id
        is_first_audit
        reject_reason
        settle_status
        name
        company_name
        mobile
        short_name
        store_category_id
        store_category_name
        province
        city
        area
        address
        hot_line
        code
        status
        ali_account
        house_num {
          url
        }
        store_inner {
          url
        }
        contact_name
        product_type
        average_spend
        lowest_delivery_price
        notice
        cover {
          url
        }
        images {
          url
        }
        video {
          url
        }
      }
      store_base_audit {
        id
        is_first_audit
        reject_reason
        settle_status
        name
        company_name
        business_license_valid_start
        business_license_valid_end
        corporation
        id_card_no
        mobile
        bank_code
        id_card_valid_start
        id_card_valid_end
        bank_account_id_card_no
        bank_card_image {
          url
        }
        ali_account
        short_name
        store_category_id
        store_category_name
        province
        city
        area
        address
        hot_line
        mcc_code
        mcc_name
        industry_code
        industry_name
        code
        status
        credit_code
        business_license {
          url
        }
        house_num {
          url
        }
        checkout_counter {
          url
        }
        store_inner {
          url
        }
        corporation
        id_card_no
        id_card_front {
          url
        }
        id_card_back {
          url
        }
        id_card_hand {
          url
        }
        id_card_address
        contact_name
        product_type
        average_spend
        lowest_delivery_price
        notice
        cover {
          url
        }
        images {
          url
        }
        video {
          url
        }
        contact_card_front {
          url
        }
        contact_card_back {
          url
        }
        contact_card_address
        contact_card_valid_start
        contact_card_valid_end
        contact_id_card
        contact_mobile
        contact_email
        bank_account_name
        bank_account_type
        bank_account
        bank_name
        bank_branch
        bank_province
        bank_city
        bank_area
        bank_id_card_front {
          url
        }
        bank_id_card_back {
          url
        }
        bank_hand_image {
          url
        }
        opening_permit_image {
          url
        }
        email
        bank_mobile
        registered_capital
        bf_industry_code
      }
      store_audit {
        status
        reject_reason
        reject_type
      }
    }
    storeCategory {
      id
      name
    }
    storeIndustry {
      id
      name
      code
    }
  }
`;

// 入驻信息
export const STORE_BASE_PLATFORM_AUDIT = gql`
  query {
    user {
      parent_mobile
    }
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
    }
    storeInfo {
      id
      status
      short_name
      store_category_id
      store_category_name
      province
      city
      area
      address
      hot_line
      code
      notice
      average_spend
      house_num {
        url
      }
      store_inner {
        url
      }
      cover {
        url
      }
      images {
        url
      }
      video {
        url
      }
      average_spend
      lowest_delivery_price
      product_type
      contact_name
      delivery_type
      is_ushoufu
      store_base_platform_audit {
        id
        is_first_audit
        reject_reason
        settle_status
        name
        company_name
        mobile
        short_name
        store_category_id
        store_category_name
        province
        city
        area
        address
        hot_line
        code
        status
        house_num {
          url
        }
        store_inner {
          url
        }
        contact_name
        cover {
          url
        }
        images {
          url
        }
        video {
          url
        }
        notice
        average_spend
        product_type
        lowest_delivery_price
      }
      store_audit {
        status
        reject_reason
        reject_type
      }
    }
    storeCategory {
      id
      name
    }
  }
`;

// 入驻信息
export const JOIN_INFO = gql`
  mutation (
    $short_name: String!
    $province: String!
    $city: String!
    $area: String!
    $address: String!
    $lng: Float!
    $lat: Float!
    $code: String
    $store_category_id: Int!
    $house_num: String!
    $store_inner: String
    $contact_name: String!
    $hot_line: String!
    $images: [String]!
    $cover: String!
    $video: String
    $notice: String!
    $lowest_delivery_price: Float
    $average_spend: Float
  ) {
    addStoreAuditBasePlatform(
      short_name: $short_name
      province: $province
      city: $city
      area: $area
      address: $address
      lng: $lng
      lat: $lat
      code: $code
      store_category_id: $store_category_id
      house_num: $house_num
      store_inner: $store_inner
      contact_name: $contact_name
      hot_line: $hot_line
      images: $images
      cover: $cover
      video: $video
      notice: $notice
      lowest_delivery_price: $lowest_delivery_price
      average_spend: $average_spend
    ) {
      id
    }
  }
`;

// 修改基本信息
export const ADD_STORE_BASE = gql`
  mutation (
    $short_name: String!
    $province: String!
    $city: String!
    $area: String!
    $address: String!
    $lng: Float!
    $lat: Float!
    $code: String
    $store_category_id: Int!
    $settle_status: Int!
    $hot_line: String!
    $credit_code: String
    $business_license: String
    $business_license_valid_start: String
    $business_license_valid_end: String
    $house_num: String!
    $checkout_counter: String
    $store_inner: String
    $company_name: String
    $corporation: String!
    $mobile: String
    $bank_code: String!
    $id_card_no: String!
    $id_card_valid_start: String!
    $id_card_valid_end: String!
    $id_card_front: String!
    $id_card_back: String!
    $id_card_hand: String
    $contact_name: String!
    $contact_id_card: String!
    $contact_mobile: String!
    $contact_email: String!
    $bank_account_name: String!
    $bank_account_id_card_no: String!
    $bank_account_type: Int!
    $bank_account: String!
    $bank_name: String!
    $mcc_code: String!
    $industry_code: String!
    $bank_branch: String!
    $bank_province: String!
    $bank_area: String!
    $bank_city: String!
    $bank_id_card_front: String!
    $bank_id_card_back: String!
    $bank_hand_image: String
    $bank_card_image: String
    $opening_permit_image: String
    $ali_account: String
    $product_type: Int!
    $average_spend: Float
    $lowest_delivery_price: Float
    $video: String
    $notice: String
    $cover: String!
    $images: [String]!
    $id_card_address: String!
    $contact_card_front: String!
    $contact_card_back: String!
    $contact_card_address: String
    $contact_card_valid_start: String!
    $contact_card_valid_end: String!
    $email: String
    $bank_mobile: String
    $registered_capital: Float
    $bf_industry_code: String!
  ) {
    addStoreBase(
      short_name: $short_name
      province: $province
      city: $city
      area: $area
      address: $address
      lng: $lng
      lat: $lat
      code: $code
      mcc_code: $mcc_code
      industry_code: $industry_code
      store_category_id: $store_category_id
      settle_status: $settle_status
      hot_line: $hot_line
      credit_code: $credit_code
      business_license: $business_license
      business_license_valid_start: $business_license_valid_start
      business_license_valid_end: $business_license_valid_end
      house_num: $house_num
      checkout_counter: $checkout_counter
      store_inner: $store_inner
      company_name: $company_name
      corporation: $corporation
      mobile: $mobile
      bank_code: $bank_code
      bank_account_id_card_no: $bank_account_id_card_no
      bank_card_image: $bank_card_image
      id_card_no: $id_card_no
      id_card_valid_start: $id_card_valid_start
      id_card_valid_end: $id_card_valid_end
      id_card_front: $id_card_front
      id_card_back: $id_card_back
      id_card_hand: $id_card_hand
      contact_name: $contact_name
      contact_id_card: $contact_id_card
      contact_mobile: $contact_mobile
      contact_email: $contact_email
      bank_account_name: $bank_account_name
      bank_account_type: $bank_account_type
      bank_account: $bank_account
      bank_name: $bank_name
      bank_branch: $bank_branch
      bank_province: $bank_province
      bank_city: $bank_city
      bank_area: $bank_area
      bank_id_card_front: $bank_id_card_front
      bank_id_card_back: $bank_id_card_back
      bank_hand_image: $bank_hand_image
      opening_permit_image: $opening_permit_image
      ali_account: $ali_account
      product_type: $product_type
      average_spend: $average_spend
      lowest_delivery_price: $lowest_delivery_price
      video: $video
      notice: $notice
      cover: $cover
      images: $images
      id_card_address: $id_card_address
      contact_card_front: $contact_card_front
      contact_card_back: $contact_card_back
      contact_card_address: $contact_card_address
      contact_card_valid_start: $contact_card_valid_start
      contact_card_valid_end: $contact_card_valid_end
      email: $email
      bank_mobile: $bank_mobile
      registered_capital: $registered_capital
      bf_industry_code: $bf_industry_code
    ) {
      id
    }
  }
`;

// 店铺信息
export const STORE_INFO = gql`
  query {
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
    }
    storeInfo {
      settle_status
      is_first_store_audit
      mobile
      shop_id
      cover {
        url
      }
      images {
        url
      }
      video {
        url
      }
      business_license {
        url
      }
      credit_code
      product_type
      province
      city
      area
      address
      hot_line
      lowest_delivery_price
      delivery_price
      average_spend
      notice
      status
      open_status
      check_can_open
      is_ushoufu
      store_base_audit: store_base_platform_audit {
        id
        is_first_audit
      }
      store_audit {
        id
        settle_status
        mobile
        cover {
          url
        }
        images {
          url
        }
        video {
          url
        }
        business_license {
          url
        }
        credit_code
        province
        city
        area
        address
        hot_line
        product_type
        lowest_delivery_price
        delivery_price
        average_spend
        notice
        status
        open_status
        check_can_open
        reject_reason
      }
    }
  }
`;

// 获取类别
export const CODE = gql`
  query ($type: Int!, $settle_status: Int!) {
    code(type: $type, settle_status: $settle_status) {
      code
      name
    }
  }
`;

// 获取省列表
export const UNIONPAY_REGIONS = gql`
  query ($type: Int, $parent_code: String) {
    unionpayRegions(type: $type, parent_code: $parent_code) {
      id
      code
      name
      parent_id
      parent_code
    }
  }
`;

// 获取省列表
export const UNIONPAY_BANK_CATEGORIES = gql`
  query {
    unionpayBankCategories {
      id
      code
      name
    }
  }
`;

// 获取支行列表
export const UNIONPAY_BANK = gql`
  query ($city_code: String, $category_code: String) {
    unionpayBank(city_code: $city_code, category_code: $category_code) {
      id
      code
      name
      city_code
      category_code
    }
  }
`;

// 店铺信息
export const STORE_TASKS = gql`
  query {
    storeInfo {
      tasks
      is_hongbao
      hongbao_activity_end_at
      hongbao_ban_status
    }
  }
`;

// 修改店铺信息
export const ADD_STORE = gql`
  mutation (
    $product_type: Int!
    $lowest_delivery_price: Float
    $average_spend: Float
    $delivery_price: Float
    $images: [String]!
    $cover: String!
    $video: String
    $notice: String!
    $province: String!
    $city: String!
    $area: String!
    $address: String!
    $lng: Float!
    $lat: Float!
    $hot_line: String!
    $credit_code: String
    $business_license: String
  ) {
    addStore(
      product_type: $product_type
      lowest_delivery_price: $lowest_delivery_price
      delivery_price: $delivery_price
      average_spend: $average_spend
      cover: $cover
      images: $images
      video: $video
      notice: $notice
      province: $province
      city: $city
      area: $area
      address: $address
      lng: $lng
      lat: $lat
      hot_line: $hot_line
      credit_code: $credit_code
      business_license: $business_license
    ) {
      id
    }
  }
`;
// 上传营业执照
export const ADD_BUSINESS_LICENSE = gql`
  mutation (
    $credit_code: String!
    $business_license: String!
    $id_card_front: String!
    $id_card_no: String!
    $corporation: String!
  ) {
    addStoreLogistic(
      credit_code: $credit_code
      business_license: $business_license
      id_card_front: $id_card_front
      id_card_no: $id_card_no
      corporation: $corporation
    ) {
      id
      credit_code
      id_card_no
      corporation
      business_license {
        url
      }
      id_card_front {
        url
      }
    }
  }
`;

// 省市区查询
export const REGION = gql`
  query {
    region {
      id
      name
      data {
        id
        name
        data {
          id
          name
        }
      }
    }
  }
`;

// 商家类目
export const STORE_CATEGROY = gql`
  query {
    StoreCategory {
      id
      name
    }
  }
`;

// oss
export const OSS = gql`
  query {
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
    }
  }
`;

// 上传图片
export const UPLOAD_FILE = gql`
  query ($file: Upload) {
    uploadFile(file: $file)
  }
`;

// 用户信息
export const USER = gql`
  query {
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
      ossUrl
    }
    user {
      id
      store {
        id
        product_type
        delivery_type
        is_express
        status
        set_commission_rule
        store_category_id
        pos_code
        is_spend
      }
    }
    storeInfo {
      store_base_audit {
        status
        is_first_audit
      }
    }
  }
`;
// 用户信息
export const USER_AND_LOGISTICS = gql`
  query {
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
      ossUrl
    }
    user {
      id
      store {
        id
        product_type
        delivery_type
        is_express
        status
        set_commission_rule
        store_category_id
        pos_code
        is_spend
        open_supplier
      }
    }
    storeInfo {
      store_base_audit {
        status
        is_first_audit
      }
    }
  }
`;

// 开启店铺
export const CHANGE_STORE = gql`
  mutation (
    $open_status: Int!
    $is_scan: Int
    $is_express: Int
    $is_allday: Int
    $trade_start_time: Int
    $trade_end_time: Int
  ) {
    changeStore(
      open_status: $open_status
      is_scan: $is_scan
      is_express: $is_express
      is_allday: $is_allday
      trade_start_time: $trade_start_time
      trade_end_time: $trade_end_time
    ) {
      id
      open_status
    }
  }
`;

// 设置物流
export const Change_Store_Mutation = gql`
  mutation (
    $open_status: Int
    $fnpt_freight_type: Int
    $fnpt_customer_freight: Float
    $self_customer_freight: Float
    $is_express: Int
    $free_delivery: Int
    $delivery_type: Int
    $is_scan: Int
    $trade_start_time: Int
    $trade_end_time: Int
    $is_allday: Int
    $free_threshold: Float
    $delivery_price: Float
    $pickup_time: Int
    $province_freight: [ProvinceFreightInput]
  ) {
    changeStore(
      open_status: $open_status
      fnpt_freight_type: $fnpt_freight_type
      fnpt_customer_freight: $fnpt_customer_freight
      self_customer_freight: $self_customer_freight
      is_express: $is_express
      is_scan: $is_scan
      free_delivery: $free_delivery
      delivery_type: $delivery_type
      trade_start_time: $trade_start_time
      trade_end_time: $trade_end_time
      is_allday: $is_allday
      province_freight: $province_freight
      free_threshold: $free_threshold
      delivery_price: $delivery_price
      pickup_time: $pickup_time
    ) {
      id
      delivery_type
      fnpt_freight_type
      fnpt_customer_freight
      self_customer_freight
      is_express
      is_scan
    }
  }
`;

// 红包活动
export const UPDATE_DOWNLOAD = gql`
  mutation {
    updateStoreDownloadQr
  }
`;

export const POSTCODE_LIST = gql`
  query {
    posCodes {
      id
      name
      status
      code
      bind_at
    }
  }
`;

//返红包记录
export const HONGBAO_RECORDS = gql`
  query ($issue: Int, $limit: Int, $page: Int) {
    hongbaoRecords(issue: $issue, limit: $limit, page: $page) {
      data {
        issue
        month
        commission_rate
        start_time
        end_time
        month_income
        order_count
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`;

export const INVITATION_USER = gql`
  query (
    $username: String
    $start_time: Int
    $end_time: Int
    $limit: Int
    $page: Int
    $is_direct: Int
  ) {
    childUser(
      username: $username
      start_time: $start_time
      end_time: $end_time
      is_direct: $is_direct
      limit: $limit
      page: $page
    ) {
      data {
        id
        is_direct
        avatar {
          id
          url
          thumbnail {
            url
          }
        }
        username
        bind_parent_time
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`;

// 查询代理
export const AGENT = gql`
  query ($agent_mobile: String!) {
    agent(agent_mobile: $agent_mobile)
  }
`;

// 查询补充资料
export const ADDITIONALINFO = gql`
  query {
    additionalInfo {
      verify_amount
      auth_image {
        url
      }
      user_id
    }
  }
`;

// 提交补充资料
export const ADDITIONAL = gql`
  mutation ($settle_status: Int!, $verify_amount: Float, $auth_image: String) {
    additional(
      settle_status: $settle_status
      verify_amount: $verify_amount
      auth_image: $auth_image
    ) {
      verify_amount
      auth_image {
        url
      }
      user_id
    }
  }
`;

// 图片识别
export const IMAGE2TEXT = gql`
  query ($type: Int!, $url: String!, $side: String) {
    image2Text(type: $type, url: $url, side: $side) {
      name
      company_name
      id_card_no
      id_card_address
      id_card_valid_start
      id_card_valid_end
      bank_name
      bank_account
      credit_code
      business_license_valid_start
      business_license_valid_end
    }
  }
`;

export const BIND_PRINTER = gql`
  mutation ($feieyun_sn: String, $feieyun_key: String) {
    bindPrinters(feieyun_sn: $feieyun_sn, feieyun_key: $feieyun_key) {
      store_id
      feieyun_sn
      feieyun_key
      is_print_neworder
      print_num
    }
  }
`;

export const UNBIND_PRINTER = gql`
  mutation {
    unbindPrinter {
      print_num
    }
  }
`;

export const CHANGE_STORE_INFO = gql`
  mutation ($is_print_neworder: Int, $print_num: Int) {
    changeStoreInfo(
      is_print_neworder: $is_print_neworder
      print_num: $print_num
    ) {
      print_num
    }
  }
`;

//配送方式查询
export const DELIVERY_WAY = gql`
  query {
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
    }
    custvices {
      id
      name
      mobile
    }
    user {
      id
      store {
        id
        province
        city
        area
        address
        lng
        lat
        hot_line
        rebate_rate
        wecom_intro
        wecom_qrcode {
          url
        }
        notice
        cover {
          url
        }
        background_img {
          url
        }
        store_client {
          id
          background_img {
            url
          }
          background_logo {
            url
          }
        } 
        store_info {
          feieyun_sn
          feieyun_key
          is_print_neworder
          print_num
          free_threshold
          province_freight {
            province
            freight
          }
        }
        product_type
        average_spend
        lowest_delivery_price
        fnpt_freight_type
        fnpt_customer_freight
        self_customer_freight
        is_express
        is_scan
        is_allday
        free_delivery
        delivery_type
        status
        set_commission_rule
        store_category_id
        pos_code
        is_takeaway
        is_spend
        open_status
        credit_code
        corporation
        trade_start_time
        trade_end_time
        id_card_front {
          url
        }
        business_license {
          url
        }
        images {
          url
        }
      }
    }
    logistics {
      id
      title
      label
      type
      freight
      is_custom
      is_select
    }
  }
`;

export const FIND_USER = gql`
  query ($search: String, $mobile: String, $limit: Int, $page: Int) {
    findUser(search: $search, mobile: $mobile, limit: $limit, page: $page) {
      data {
        id
        username
        avatar {
          url
        }
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;

export const SUPPLIERS_LIST = gql`
  query ($limit: Int, $page: Int, $name: String, $mobile: String) {
    suppliers(limit: $limit, page: $page, name: $name, mobile: $mobile) {
      data {
        id
        name
        mobile
        address
        accounting_period
        weekdays_stop_order
        weekend_deliver
        remark
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;

export const GET_SUPPLIER = gql`
  query ($id: ID) {
    supplier(id: $id) {
      id
      name
      mobile
      address
      accounting_period
      weekdays_stop_order
      weekend_deliver
      remark
    }
  }
`;

export const CREATE_SUPPLIER = gql`
  mutation (
    $id: ID
    $name: String!
    $mobile: String!
    $address: String
    $accounting_period: String
    $weekdays_stop_order: String
    $weekend_deliver: String
    $remark: String
  ) {
    createSupplier(
      id: $id
      name: $name
      address: $address
      remark: $remark
      accounting_period: $accounting_period
      weekdays_stop_order: $weekdays_stop_order
      weekend_deliver: $weekend_deliver
      mobile: $mobile
    )
  }
`;

export const DEL_SUPPLIER = gql`
  mutation ($id: ID!) {
    deleteSupplier(id: $id)
  }
`;

export const CREATE_CUSTVICE = gql`
  mutation ($id: ID, $name: String!, $mobile: String!) {
    createCustvice(id: $id, name: $name, mobile: $mobile)
  }
`;

export const DEL_CUSTVICE = gql`
  mutation ($id: ID!) {
    deleteCustvice(id: $id)
  }
`;

export const BIND_POSCODE = gql`
  mutation ($pos_code_id: Int!, $name: String!) {
    bindPosCode(pos_code_id: $pos_code_id, name: $name)
  }
`;

// 商家返现比例（吃饭餐劵比例）设置
export const REBATE_RATE_SAVE = gql`
  mutation RebateRateSave($rebate_rate: Float!){
    RebateRateSave(rebate_rate: $rebate_rate){
      status
    }
  }
`;

// 商家企业微信设置
export const WECOM_QRCODE_SAVE = gql`
  mutation WecomQrcodeSave($wecom_intro: String!, $wecom_qrcode: String!){
    WecomQrcodeSave(wecom_intro: $wecom_intro, wecom_qrcode: $wecom_qrcode){
      status
    }
  }
`;

// 商家店铺店招设置
export const STORE_BACKGROUND_SAVE = gql`
  mutation StoreBackgroundSave($background_img: String!){
    StoreBackgroundSave(background_img: $background_img){
      status
    }
  }
`;

// 商家收银终端设置
export const STORE_CLIENT_SETTING = gql`
  mutation StoreClientSetting($background_img: String!, $background_logo: String!){
    StoreClientSetting(background_img: $background_img, background_logo: $background_logo){
      status
    }
  }
`;