import gql from "graphql-tag";

//红包券列表
export const COUPON_LIST = gql`
  query ($name: String, $status: Int!, $limit: Int, $page: Int) {
    storeCoupon(name: $name, status: $status, limit: $limit, page: $page) {
      data {
        id
        created_at
        usable_user
        created_at
        money
        name
        start_time
        end_time
        validity
        remake
        is_termination
        type
        full
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;

export const ADD_COUPON = gql`
  mutation (
    $is_spend: Int!
    $name: String!
    $usable_user: Int!
    $usable_side: Int!
    $usable_shop: Int
    $money: Float!
    $validity: Int!
    $is_effective: Int!
    $effective_date: Int!
    $is_limitless: Int!
    $max_num: Int!
    $start_time: Int!
    $end_time: Int!
    $type: Int!
    $is_max_money: Int
    $max_money: Float
    $remake: String
    $full: Float
    $specify_user: [CouponSpecifyUserInput]
    $specify_product: [CouponSpecifyProductInput]
    $limit_product: [CouponSpecifyProductInput]
    $specify_area: [String]
    $nearby_area: Int
    $receive_area: Int
  ) {
    addStoreCoupon(
      is_spend: $is_spend
      name: $name
      usable_user: $usable_user
      usable_side: $usable_side
      money: $money
      validity: $validity
      is_effective: $is_effective
      effective_date: $effective_date
      is_limitless: $is_limitless
      max_num: $max_num
      start_time: $start_time
      end_time: $end_time
      remake: $remake
      type: $type
      is_max_money: $is_max_money
      max_money: $max_money
      full: $full
      specify_user: $specify_user
      specify_product: $specify_product
      usable_shop: $usable_shop
      limit_product: $limit_product
      specify_area: $specify_area
      nearby_area: $nearby_area
      receive_area: $receive_area
    )
  }
`;

export const STOP_COUPON = gql`
  mutation ($coupon_id: Int!) {
    stopStoreCoupon(coupon_id: $coupon_id)
  }
`;

export const COUPON_INFO = gql`
  query ($id: Int!) {
    storeCouponInfo(id: $id) {
      name
      usable_user
      usable_side
      money
      validity
      is_effective
      effective_date
      is_limitless
      usable_shop
      max_num
      issue_num
      start_time
      end_time
      remake
      type
      is_max_money
      max_money
      full
      specify_user {
        id
        username
      }
      specify_product {
        id
        name
      }
      specify_area
      nearby_area
      receive_area
    }
  }
`;
