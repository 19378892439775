/*
 * @Author: Fan
 * @Date: 2020-12-15 15:47:31
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-23 10:53:42
 * @Description: file content
 */
import React from "react";
import "./index.less";
import { Spin } from "antd";
import moment from "moment";
import { useQuery } from "react-apollo";
import { isNonEmptyArray } from "@/utils";
import Contact from "@/components/public/contact/contact";
import Centers from "@/components/public/contact/center";
import Client from "@/components/public/contact/client";
import Information from "@/components/public/contact/information";
import { GET_STORE_SIGNS } from "@/graphql/storesigns";
import { Link } from "react-router-dom";
const Item = ({ item }) => {
  return (
    <Link to={"/msgdetail/" + item.id} className="msg-item">
      <div className="msg-item_left">
        {item.status !== 1 ? (
          <span className="msg-item_wait">[待处理]</span>
        ) : null}
        <span>{item.title}</span>
      </div>
      <span>
        {moment(item.created_at * 1000).format("YYYY-MM-DD HH:mm:ss")}
      </span>
    </Link>
  );
};
const list = [
  {
    id: 1,
    title: "111",
    status: 0,
    created_at: 1608607868,
  },
  {
    id: 2,
    title: "111222",
    status: 0,
    created_at: 1608607868,
  },
];
const Messages = (props) => {
  const history = props.history;
  const { data, loading } = useQuery(GET_STORE_SIGNS, {
    fetchPolicy: "no-cache",
  });
  console.log(data, "----");
  return (
    <div className="msg-flex">
      <div className="msg-wrapper">
        <div className="wrapper_title">消息通知</div>
        {loading ? (
          <div className="msg-center">
            <Spin />
          </div>
        ) : isNonEmptyArray(data.storeSigns) ? (
          data.storeSigns.map((item) => <Item item={item} key={item.id} />)
        ) : (
          <div className="msg-center">暂无数据</div>
        )}
      </div>
      <div className="index-contact">
        <Centers history={history} title={"公告通知"} type={3} />
        <Contact />
        <Information />
        <Client />
        <Centers history={history} title={"学习中心"} type={2} />
      </div>
    </div>
  );
};
export default Messages;
