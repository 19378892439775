import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, message } from 'antd'
import { setExpressNo } from '@/apis/order'

const ExpressModal = forwardRef((props, ref) => {
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [orderId, setOrderId]= useState(0)
    const [goodsIds, setGoodsIds] = useState([])

    useImperativeHandle(ref, () => ({
        show: (data) => {
            setOrderId(data.order_id)
            setGoodsIds(data.list)
            setVisible(true)
            form.resetFields()
        }
    }))

    const onSubmit = async () => {
        try {
            const params = await form.validateFields()
            const res = await setExpressNo({
                id: orderId,
                ids: JSON.stringify(goodsIds),
                ...params
            })
            message.success('提交成功')
            setVisible(false)
            typeof props.onOk === 'function' && props.onOk()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Modal
            open={visible}
            width={450}
            title="添加物流信息"
            className="custom-modal"
            centered
            onOk={onSubmit}
            onCancel={() => setVisible(false)}
        >
            <Form
                form={form}
                colon={false}
                labelAlign="left"
                autoComplete="off"
            >
                <Form.Item
                    name="express_code"
                    label="快递公司"
                    rules={[ { required: true } ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="express_no"
                    label="快递单号"
                    rules={[ { required: true } ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
})

export default ExpressModal