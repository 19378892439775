import React, { useState, useContext } from "react";
import Cookies from "js-cookie";
import { withApollo, useMutation } from "react-apollo";
import { Button, Tabs, Message } from "antd";
import LoginLayout from "../../components/LoginLayout.js";
import RegisterContent from "../../components/RegisterContent.js";
import { getQueryString } from "../../utils/index";
import { isPhone } from "../../utils/regexp";
import { LOGIN_STORE } from "../../graphql/login";
import "./index.less";
const { TabPane } = Tabs;

const Login = (props) => {
  const { history } = props;
  // 登录
  const [loading, setLoading] = useState(false);
  const [loginObj, setLoginObj] = useState({
    phone: "",
    password: "",
    pos_code: getQueryString("code"),
  });

  // 提交登录
  const confirmLogin = async () => {
    if (!loginObj.phone) {
      Message.error("请输入手机号");
    } else if (!isPhone(loginObj.phone)) {
      Message.error("手机号码格式不正确，请重新输入");
    } else if (!loginObj.password) {
      Message.error("请输入密码");
    } else {
      setLoading(true);
      loginMutation();
    }
  };

  // 登录
  const [loginMutation] = useMutation(LOGIN_STORE, {
    variables: loginObj,
    update: (_, { data }) => {
      Cookies.set("token", data.loginStore.remember_token, {
        path: "/",
        expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      });
      Cookies.set("mobile", data.loginStore.mobile, {
        path: "/",
        expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      });
      if (data.loginStore.store) {
        Cookies.set("store_id", data.loginStore.store.id, {
          path: "/",
          expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
        });
        Cookies.set("store_name", data.loginStore.store.name, {
          path: "/",
          expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
        });
      }
      Cookies.set("enterprise_id", data.loginStore.enterprise_id, {
        path: "/",
        expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      });
      setTimeout(() => {
        window.location = "/";
      }, 200);
    },
    // 请求失败异常的回调
    onError(e) {
      setLoading(false);
      // getImgCode();
    },
  });

  // 跳转至忘记密码
  const goForgetPwd = () => {
    history.push("/forgetpwd");
  };
  const onChange = (activeKey) => {
    if (getQueryString("code")) {
      history.push(
        "/login?mode=" + activeKey + "&code=" + getQueryString("code")
      );
    } else {
      history.push("/login?mode=" + activeKey);
    }
  };
  return (
    <LoginLayout>
      <Tabs
        defaultActiveKey={getQueryString("mode")}
        tabBarGutter={50}
        className={"ant-carousel"}
        onChange={onChange}
      >
        <TabPane tab="欢迎登录" key="1">
          <input
            className="ipt"
            type="text"
            placeholder="请输入手机号"
            maxLength="11"
            value={loginObj.phone}
            onChange={(e) => {
              const value = e.target.value;
              setLoginObj((state) => ({ ...state, phone: value }));
            }}
          />
          <input
            className="ipt"
            type="password"
            placeholder="请输入登录密码"
            value={loginObj.password}
            onChange={(e) => {
              const value = e.target.value;
              setLoginObj((state) => ({ ...state, password: value }));
            }}
          />
          <Button
            onClick={() => {
              confirmLogin();
            }}
            loading={loading}
            className="btn-login"
            type="primary"
            block
            size="large"
          >
            登录
          </Button>
          <p
            style={{
              textAlign: "right",
              fontSize: "12px",
              color: "rgba(17,17,17,1)",
              marginTop: "10px",
            }}
            onClick={goForgetPwd}
          >
            <span className={"href"}>忘记密码</span>
          </p>
          <div className="login-tip">
            登录代表您已同意树懒生活
            <div
              className="blue"
              onClick={() => history.push(`/resources?name=store_agreement`)}
            >
              用户协议
            </div>
            、
            <div
              className="blue"
              onClick={() =>
                history.push(`/resources?name=store_privacy_agreement`)
              }
            >
              隐私协议
            </div>
          </div>
        </TabPane>
        <TabPane tab="立即注册" key="2">
          <RegisterContent history={history} form={"register"} />
        </TabPane>
      </Tabs>{" "}
    </LoginLayout>
  );
};

export default withApollo(Login);
