import http from '../request'

// 查询余额
export const getExpressBalance = () => http.get('/express/balance')

// 配送偏好设置
export const setExpressPrefer = (params) => http.post('/express/confirm', params)

// 查询资金流水
export const getTransList = (params) => http.get('/express/balance-flow', params)

// 充值
export const recharge = (params) => http.post('/express/recharge', params)