import gql from "graphql-tag";

// 图库选择
export const RESOURCE_IMAGE = gql`
  query ($type: Int!, $limit: Int, $page: Int, $store_category_id: Int!) {
    resources(
      type: $type
      limit: $limit
      page: $page
      store_category_id: $store_category_id
    ) {
      data {
        image {
          id
          url
          thumbnail {
            url
          }
        }
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;
// 图库选择
export const RESOURCE_VIDEO = gql`
  query ($type: Int!, $limit: Int, $page: Int, $store_category_id: Int!) {
    resources(
      type: $type
      limit: $limit
      page: $page
      store_category_id: $store_category_id
    ) {
      data {
        video {
          url
          cover {
            thumbnail {
              url
            }
          }
        }
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;

// 商品详情
export const PRODUCT_INFO = gql`
  query ($product_id: Int!) {
    productInfo(product_id: $product_id) {
      id
      name
      spu
      sales_point
      content
      order_limit
      supplier
      status
      is_must
      module_type
      is_must
      commission_rate
      rebate_rate
      product_coupon
      category {
        id
        child {
          id
        }
      }
      distributor {
        id
      }
      productSpecs {
        id
        sku
        name
        price
        making_price
        offer_price
        stock
        commission_amount
        barcode
      }
      store {
        store_category_id
        is_spend
      }
      is_deposit
      delivery_type
      total_write_off_count
      productCategory {
        id
        name
      }
      cover {
        url
      }
      images {
        url
      }
      video {
        url
      }
      pusherman_redpack_rate
      service_redpack_rate
    }
  }
`;

export const USABLE_LOGISTICS = gql`
  query ($delivery_type: Int) {
    usablelogistics(delivery_type: $delivery_type) {
      label
      value: id
    }
  }
`;

// 添加商品详情
export const ADD_PRODUCT = gql`
  mutation (
    $id: Int
    $spu: String
    $name: String
    $productCategoryId: Int
    $order_limit: Int
    $supplier: String
    $status: Int
    $sales_point: String
    $video: String
    $images: [String]
    $cover: String
    $specs: [StoreProductSpecsInput]
    $content: String
    $weight: Int
    $delivery_type: Int!
    $module_type: Int
    $is_must: Int
    $supplier_id: Int
    $commission_rate: String
    $is_deposit: Int
    $categoryId: [Int]
    $total_write_off_count: Float
    $pusherman_redpack_rate: Float
    $service_redpack_rate: Float
    $rebate_rate: Float!
    $product_coupon: Int!
  ) {
    addProduct(
      id: $id
      spu: $spu
      name: $name
      productCategoryId: $productCategoryId
      order_limit: $order_limit
      supplier: $supplier
      status: $status
      sales_point: $sales_point
      video: $video
      images: $images
      cover: $cover
      specs: $specs
      content: $content
      weight: $weight
      delivery_type: $delivery_type
      module_type: $module_type
      is_must: $is_must
      supplier_id: $supplier_id
      commission_rate: $commission_rate
      categoryId: $categoryId
      is_deposit: $is_deposit
      total_write_off_count: $total_write_off_count
      pusherman_redpack_rate: $pusherman_redpack_rate
      service_redpack_rate: $service_redpack_rate
      rebate_rate: $rebate_rate
      product_coupon: $product_coupon
    ) {
      id
    }
  }
`;

// 商家商品删除
export const DEL_PRODUCT = gql`
  mutation ($id: Int) {
    deleteProduct(id: $id)
  }
`;

// 批量删除
export const BATCH_PRODUCT = gql`
  mutation ($ids: [Int], $type: Int) {
    batchProduct(ids: $ids, type: $type)
  }
`;


/** 树懒生活 **/
// 商家店铺商品添加
export const ADD_GOODS = gql`
  mutation AddGoods(
    $spu: String,
    $name: String!,
    $barcode: String,
    $keywords: String,
    $product_coupon: Int!,
    $product_category_id: Int!,
    $order_limit: Int!,
    $category_ids: [Int]!,
    $sales_point: String!,
    $content: String!,
    $is_deposit: Int!,
    $status: Int!,
    $is_must: Int!,
    $supplier_id: Int,
    $video: String,
    $cover: String!,
    $images: [String]!,
    $price: Float!,
    $first_price: Float!,
    $rebate_rate: Float!,
    $stock: Int!,
    $is_self_delivery: Int!,
    $delivery_type: Int!,
    $specs_types: String!,
    $product_offer_price: Float,
    $pusherman_redpack_rate: Float, 
    $service_redpack_rate: Float,
    $commission_rate: String
  ){
    AddGoods(
      spu: $spu,
      name: $name,
      barcode: $barcode,
      keywords: $keywords,
      product_coupon: $product_coupon,
      product_category_id: $product_category_id,
      order_limit: $order_limit,
      category_ids: $category_ids,
      sales_point: $sales_point,
      content: $content,
      is_deposit: $is_deposit,
      status: $status,
      is_must: $is_must,
      supplier_id: $supplier_id,
      video: $video,
      cover: $cover,
      images: $images,
      price: $price,
      first_price: $first_price,
      rebate_rate: $rebate_rate,
      stock: $stock,
      is_self_delivery: $is_self_delivery,
      delivery_type: $delivery_type,
      specs_types: $specs_types,
      product_offer_price: $product_offer_price,
      pusherman_redpack_rate: $pusherman_redpack_rate,
      service_redpack_rate: $service_redpack_rate,
      commission_rate: $commission_rate
    ){
      id
    }
  }
`;

// 商家店铺商品编辑
export const EDIT_GOODS = gql`
  mutation EditGoods(
    $product_id: Int!,
    $spu: String,
    $name: String!,
    $barcode: String,
    $keywords: String,
    $product_coupon: Int!,
    $product_category_id: Int!,
    $order_limit: Int!,
    $category_ids: [Int]!,
    $sales_point: String!,
    $content: String!,
    $is_deposit: Int!,
    $status: Int!,
    $is_must: Int!,
    $supplier_id: Int,
    $video: String,
    $cover: String!,
    $images: [String]!,
    $price: Float!,
    $first_price: Float!,
    $rebate_rate: Float!,
    $stock: Int!,
    $is_self_delivery: Int!,
    $delivery_type: Int!,
    $specs_types: String!,
    $product_offer_price: Float,
    $pusherman_redpack_rate: Float, 
    $service_redpack_rate: Float,
    $commission_rate: String
  ){
    EditGoods(
      product_id: $product_id,
      spu: $spu,
      name: $name,
      barcode: $barcode,
      keywords: $keywords,
      product_coupon: $product_coupon,
      product_category_id: $product_category_id,
      order_limit: $order_limit,
      category_ids: $category_ids,
      sales_point: $sales_point,
      content: $content,
      is_deposit: $is_deposit,
      status: $status,
      is_must: $is_must,
      supplier_id: $supplier_id,
      video: $video,
      cover: $cover,
      images: $images,
      price: $price,
      first_price: $first_price,
      rebate_rate: $rebate_rate,
      stock: $stock,
      is_self_delivery: $is_self_delivery,
      delivery_type: $delivery_type,
      specs_types: $specs_types,
      product_offer_price: $product_offer_price,
      pusherman_redpack_rate: $pusherman_redpack_rate,
      service_redpack_rate: $service_redpack_rate,
      commission_rate: $commission_rate
    ){
      id
    }
  }
`;

// 商家店铺商品详情
export const GOODS_DETAIL = gql`
  query GoodsDetail($product_id: Int!){
    GoodsDetail(product_id: $product_id){
      id
      spu
      name
      barcode
      product_type
      product_coupon
      product_category_id
      sales_point
      content
      first_price
      product_offer_price
      price
      rebate_rate
      stock
      specs_num
      order_limit
      delivery_type
      module_type
      is_self_delivery
      is_must
      is_deposit
      total_write_off_count
      supplier
      supplier_id
      commission_rate
      pusherman_redpack_rate
      service_redpack_rate
      status
      is_hot
      weight
      is_warehouse
      cover {
        url
      }
      video {
        url
      }
      images {
        id
        url
      }
      categories {
        id
      }
      product_category {
        id
        name
      }
      specs_types{
        id
        name
        product_specs {
          id
          sku
          name
          price
          stock
          offer_price
        }
      }
    }
  }
`;

// 店铺商品上/下架设置
export const GOODS_SET_STATUS = gql`
  mutation GoodsSaleStatusSetting($status: Int!, $product_ids: String!){
    GoodsSaleStatusSetting(status: $status, product_ids: $product_ids){
      status
    }
  }
`;

// 批量设置商品权重
export const GOODS_SET_WEIGHT = gql`
mutation GoodsWeightSetting($weight: Int!, $product_ids: String!){
  GoodsWeightSetting(weight: $weight, product_ids: $product_ids){
    status
  }
}`;