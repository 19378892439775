/*
 * @Author: Fan
 * @Date: 2020-12-22 11:04:30
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-22 17:29:26
 * @Description: file content
 */
import gql from "graphql-tag";

// # 签约消息详情
// # Header Arguments:
// # Authorization: 用户token
export const GET_STORE_SIGN = gql`
  query($id: Int!) {
    storeSign(id: $id) {
      id
      title
      content
      status
      store {
        name
        short_name
      }
      created_at
    }
  }
`;

// # 签约消息列表
// # Header Arguments:
// # Authorization: 用户token
export const GET_STORE_SIGNS = gql`
  query {
    storeSigns {
      id
      title
      status
      created_at
    }
  }
`;
export const POST_STORE_SIGNS = gql`
  mutation($id: Int!) {
    storeSign(id: $id)
  }
`;